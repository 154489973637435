
import { defineComponent } from 'vue';

import IconElement from '@/common/elements/IconElement.vue';
import AppButton from '@/common/components/AppButton.vue';

export default defineComponent({
  name: 'question-package-card',
  components: { IconElement, AppButton },
  emits: ['showresult', 'showpackage'],
  props: {
    status: {
      type: String,
      default: 'unplanned',
    },
    eyebrow: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
    resultText: {
      type: String,
      required: false,
    },
    result: {
      type: Number,
      default: 0,
    },
    partipicants: {
      type: String,
      required: false,
    },
  },
});
