
import { defineComponent, ref } from 'vue';
import AppButton from '@/common/components/AppButton.vue';
import useI18n from '@/common/i18n/useI18n';
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
  name: 'LocalesDropdown',
  components: { AppButton },
  emits: ['changedLocale', 'close'],
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { locale, locales } = useI18n();

    const elementRef = ref<HTMLElement>();

    onClickOutside(elementRef, () => {
      emit('close');
    });

    return {
      currentLocale: locale,
      locales,
      elementRef,
    };
  },
});
