{
  "common": {
    "ok": "OK",
    "save": "Salva",
    "cancel": "Annulla",
    "yes": "Sì",
    "no": "No",
    "continue": "Avanti",
    "unknownError": "Errore sconosciuto",
    "back": "Indietro",
    "confirm": "Conferma",
    "locale": "Lingua"
  },
  "locales": {
    "de": "Tedesco",
    "fr": "Francese",
    "it": "Italiano"
  },
  "offline": {
    "message": "Nessuna connessione Internet. Devi essere connesso a Internet per usare questa app."
  },
  "hints": {
    "popover": {
      "title": "Consiglio",
      "texts": {
        "dashboardEmployeeAdd": "Invita le tue collaboratrici e i tuoi collaboratori a usare l’applicazione.",
        "dashboardTaskAdd": "Per prima cosa pianifica i compiti mensili.",
        "planningQuestionPackagePool": "Scegli un pacchetto di domande dall’elenco e inseriscilo nel mese desiderato con la funzione «trascina e rilascia».",
        "employeesInviteFields": "Qui puoi inserire tutte le tue collaboratrici e i tuoi collaboratori e inviare loro un invito. Potrai aggiungerne altri anche in seguito.",
        "employeesInviteAdd": "Clicca sul pulsante «Aggiungi collaboratore» per inserire altre persone nell’elenco. Cliccando su «Invita collaboratori», i destinatari riceveranno un invito per SMS."
      }
    },
    "productTour": {
      "title": {
        "1": "Breve introduzione",
        "2": "Quadro generale (1 di 6)",
        "3": "Pianificazione e controllo (2 di 6)",
        "4": "Aggiungi e gestisci (3 di 6)",
        "5": "Profilo aziendale (4 di 6)",
        "6": "Notifiche (5 di 6)",
        "7": "Domande? (6 di 6)"
      },
      "texts": {
        "1": "Ciao {name}! Questa è una breve introduzione in 6 passi sulla struttura e le funzioni dell’applicazione.",
        "2": "Sulla dashboard trovi una panoramica generale con le informazioni principali.",
        "3": "Pianifica i compiti per le tue collaboratrici e i tuoi collaboratori.",
        "4": "Invita le tue collaboratrici e i tuoi collaboratori a partecipare e verifica in seguito i loro progressi.<br><br>La Suva parte dal presupposto che gli utenti registrino soltanto dati leciti.",
        "5": "Configura in questa sezione la tua azienda.",
        "6": "Qui compaiono le notifiche che ti aiuteranno nel tuo lavoro, ricordandoti ad esempio che non hai ancora pianificato il prossimo mese.",
        "7": "Clicca sul punto di domanda se non sai come fare qualcosa o vuoi guardare da capo l’introduzione."
      }
    }
  },
  "form": {
    "phone": {
      "invalidFormat": "Numero non valido, utilizzare il seguente formato: {format}"
    },
    "select": {
      "choose": "Seleziona"
    }
  },
  "staff": {
    "navigation": {
      "logo": "Logo aziendale",
      "navItems": {
        "start": "Compiti",
        "onboarding": "Onboarding",
        "challenge": "Sfida",
        "challenges": "Sfide",
        "challenge-list": "Elenco delle sfide",
        "employees": "Collaboratori",
        "profile": "Profilo",
        "logout": "Logout",
        "demo-start": "Suva 12x12 Demo"
      }
    },
    "login": {
      "view": {
        "title": "Accedi",
        "mobile": "Cellulare",
        "password": "Password",
        "login": "Accedi",
        "rememberMe": "Rimani connesso",
        "forgotPassword": "Password dimenticata?",
        "forgotPasswordQuestion": "Hai dimenticato la password?",
        "invalidCredentials": "Numero di telefono o password sbagliati."
      }
    },
    "demo": {
      "banner": "Modalità demo",
      "start": "Avvia demo",
      "reset": "Ricomincia",
      "selectIndustry": "Seleziona settore",
      "selectIndustryPlaceholder": "Seleziona",
      "afterTaskInformation": "Il compito fittizio del mese è stato svolto. Se vuoi ora puoi provare un altro pacchetto di compiti, continuare a scoprire le funzionalità di 12x12 oppure registrarti come addetto/a alla sicurezza (AdSic) e utilizzare l’app gratuitamente per la tua azienda.",
      "shareSubject": "Demo di 12x12",
      "shareBody": "Buongiorno!\n\nQuesto quiz online sulla sicurezza sul lavoro potrebbe interessarti:\n{shareLink}\n\nCordiali saluti",
      "actions": {
        "moreInfo": "Cosa ha da offrire 12x12?",
        "playAgain": "Svolgi un altro compito del mese",
        "share": "Condividi la demo di 12x12",
        "activate": "Attiva 12x12 per la tua azienda"
      }
    },
    "game": {
      "next": "Avanti",
      "reset": "Resetta",
      "goOnToFirstQuestion": "Vai al primo compito",
      "goOn": "Continua",
      "yesAbort": "Sì, termina",
      "secure": "Sicuro",
      "unsecure": "Non sicuro",
      "verifying": "Stiamo verificando la risposta…",
      "correctAnswer": "Risposta corretta",
      "explanation": "Spiegazione",
      "goodJob": "Niente male!",
      "challengeSomebody": "Sfida qualcuno",
      "backToStart": "Ritorna all’inizio",
      "scoreText": "Hai terminato il compito del mese totalizzando {0} caschi di sicurezza su {1}.",
      "quitQuestion": "Sei sicuro di voler smettere?",
      "progressWillNotSaved": "I tuoi progressi non verranno salvati.",
      "challangeSomebody": "Sfida qualcuno",
      "tryAgain": "Prova di nuovo",
      "identify-risks": "Individua i pericoli",
      "quiz": "Quiz",
      "safe-or-not": "È sicuro o no?",
      "instructions": {
        "identify-risks": "Indica il punto dell’immagine che ti sembra pericoloso o non sicuro. Ogni immagine contiene un \"errore\".",
        "quiz": "Scegli la risposta giusta tra le tre opzioni disponibili.",
        "safe-or-not": "Riscontri comportamenti non sicuri o altri pericoli nelle situazioni rappresentate? È fatto tutto correttamente?"
      },
      "machineName": {
        "work_in_isolation": "Lavori in solitudine",
        "tree_assessment": "Esame dell’albero",
        "falling_space": "Zona di caduta",
        "loads": "Carichi",
        "falling": "Cadute dall’alto",
        "psa": "DPI",
        "working_materials": "Attrezzature di lavoro"
      },
      "result": {
        "PASS": "Risposta corretta",
        "FAIL": "Risposta sbagliata",
        "PARTLYPASS": "Parzialmente corretto"
      }
    },
    "choosePassword": {
      "view": {
        "title": "Impostare la password",
        "mobile": "Cellulare",
        "password": "Imposta password",
        "passwordConfirmation": "Conferma password",
        "button": "Imposta password",
        "rememberMe": "Rimani connesso",
        "invalidToken": "L’invito non è valido o è scaduto. Contatta il tuo addetto alla sicurezza.",
        "passwordsMismatch": "La password non coincide. Ricontrollala per favore."
      }
    },
    "challenge": {
      "view": {
        "page": {
          "title": "Sfide",
          "ends": "Scade tra",
          "employees": "Collaboratori",
          "your-score": "Il tuo punteggio"
        },
        "challengerList": {
          "openChallenges": "Sfide in corso",
          "closedChallenges": "Sfide concluse"
        },
        "challenger-card": {
          "expired": "La sfida è scaduta.",
          "declined": "La sfida è stata rifiutata.",
          "invited": "Invito spedito",
          "challenged": "Hai ricevuto una sfida",
          "win": "Hai vinto! {0} {1} – tu {2}",
          "loss": "Hai perso! {0} {1} – tu {2}",
          "draw": "Pareggio! {0} {1} – tu {2}"
        },
        "shakeToChoose": {
          "shake": "Scuoti il tuo smartphone per scegliere automaticamente l’argomento e il tipo di domande oppure clicca su «Avanti».",
          "noShake": "Clicca su «Avanti» per scegliere automaticamente un argomento e il tipo di domande."
        },
        "slotMachine": {
          "title": "Sfida in caricamento",
          "subtitle": "Quale sarà la tua nuova sfida?"
        },
        "newChallenge": {
          "title": "Nuova sfida",
          "text": "Vuoi sfidare {0} a un duello di conoscenze?",
          "acceptChallengePrompt": "{0} ti sfida a un duello di conoscenze.",
          "primaryAction": "Accetta",
          "secondaryAction": "Rifiuta",
          "linkAction": "Forse più tardi",
          "confirm": "Sì, inizia",
          "no": "No",
          "next": "Avanti",
          "start": "Inizia",
          "chosenChallenge": {
            "title": "La tua sfida",
            "cta": "Sì, inizia"
          }
        },
        "result": {
          "wellDone": "Ben fatto!",
          "done": "Fatto!",
          "descriptionScore": "Punteggio totalizzato in questo duello",
          "descriptionInfo": "{0} riceverà il risultato che hai ottenuto nella sfida.",
          "titleWon": "Hai vinto la sfida!",
          "titleLost": "Purtroppo hai perso la sfida.",
          "titleDraw": "Pareggio!",
          "ctaBack": "Torna alla panoramica"
        }
      }
    },
    "passwordReset": {
      "view": {
        "title": "Reimpostare la password",
        "description": "Inserisci il numero di cellulare associato al tuo account e ti invieremo un SMS con le istruzioni per reimpostare la password.",
        "mobile": "Cellulare",
        "reset": "Invia istruzioni"
      }
    },
    "checkSMS": {
      "view": {
        "title": "Controllare l’SMS",
        "description": "Ti abbiamo inviato per SMS le istruzioni per ripristinare la password. Leggi il messaggio.",
        "question": "Non hai ricevuto SMS?",
        "link": "Riprova"
      }
    },
    "dashboard": {
      "filter": {
        "todo": "Da svolgere",
        "done": "Svolti"
      },
      "info": {
        "solved": "Hai già svolto il compito del mese. Riceverai un SMS quando il prossimo compito sarà pronto per essere svolto.",
        "notPlanned": "Ancora nessun compito pianificato. Riceverai un SMS quando il prossimo compito sarà pronto per essere svolto.",
        "notApproved": "Ancora nessun compito rilasciato. Riceverai un SMS quando il compito del mese sarà pronto per essere svolto.",
        "actionNewChallenge": "Sfida i tuoi colleghi"
      },
      "taskCard": {
        "state": "Stato",
        "done": "Svolto",
        "notDone": "Non svolto",
        "at": "il",
        "notYetReleased": "Non ancora rilasciato",
        "currentMonthlyTask": "Compito del mese",
        "taskNotEdited": "Il compito non è stato elaborato.",
        "releasingDate": "Rilascio previsto il",
        "result": "Risultato",
        "of": "su"
      },
      "view": {
        "collectMore": "Hai già concluso i compiti del mese! Vuoi guadagnare altri punti?",
        "challengeYourFriends": "Sfida i tuoi colleghi a duello."
      }
    },
    "onboarding": {
      "lets_go": "Eccoci qui",
      "screens": {
        "1": {
          "title": "12x12 App per imparare: 12 compiti in 12 mesi",
          "description": "Ogni mese l’AdSic ti affida un compito su un argomento rilevante ai fini della sicurezza.",
          "img": "01_onboarding"
        },
        "2": {
          "title": "Come funzionano i compiti del mese",
          "description": "Ogni compito del mese si compone di due o tre esercizi di vario tipo: \"Individua i pericoli\", \"Quiz\", \"È sicuro o no?\"",
          "img": "02_onboarding"
        },
        "3": {
          "title": "Raccogli punti sotto forma di caschi di sicurezza",
          "description": "Per ogni risposta corretta a una domanda ricevi un punto ovvero un casco di sicurezza.",
          "img": "03_onboarding"
        },
        "4": {
          "title": "Sfida i tuoi colleghi di lavoro",
          "description": "Metti alla prova le tue conoscenze sfidando colleghe e colleghi di lavoro.",
          "img": "04_onboarding"
        },
        "5": {
          "title": "Aggiungi foto al profilo",
          "description": "Aggiungi la tua foto al profilo per farti trovare più facilmente dal tuo AdSic e dai tuoi colleghi.",
          "img": "05_onboarding"
        },
        "6": {
          "title": "Aggiungi applicazione alla schermata Home",
          "description": "Per accedere rapidamente all’applicazione, salva il collegamento nella schermata Home.",
          "img": "06_onboarding"
        }
      }
    },
    "disclaimer": {
      "title": "Protezione dei dati",
      "text": "Il quiz 12x12 viene svolto nell’ambito della prevenzione volontaria e non implica obblighi giuridici o conseguenze giuridiche. L’applicazione registra i dati solo temporaneamente sullo smartphone dell’utente, la valutazione è accessibile solo all’addetto/a alla sicurezza. Per il resto valgono <a class='link' href='https://www.suva.ch/it-ch/generale/direttiva-sulla-protezione-dei-dati?lang=it-CH' target='_blank'>le condizioni di utilizzo generali e le disposizioni sulla protezione dei dati</a> della Suva.",
      "accept": "Accetta e continua"
    },
    "errorHandling": {
      "defaultTitle": "Ops!",
      "defaultDescription": "Qualcosa è andato storto. Riprova."
    },
    "profile": {
      "editProfile": "Modifica profilo",
      "saveChanges": "Salva modifiche",
      "firstName": "Nome",
      "lastName": "Cognome",
      "phone": "Cellulare",
      "fileSizeError": "L’immagine selezionata supera la dimensione massima di {0}MB.",
      "fileTypeError": "Il formato selezionato non è supportato.",
      "hintprofileImage": "Le immagini vengono ritagliate in forma quadrata. Il tuo viso dovrebbe trovarsi al centro per essere facilmente riconoscibile sull’immagine del profilo.",
      "hintMobileNumber": "Se vuoi modificare il numero, contatta il tuo addetto alla sicurezza.",
      "accountActivatedAt": "Conto attivato"
    }
  },
  "manager": {
    "login": {
      "view": {
        "title": "Effettua il login",
        "subtitle": " Inserisci i tuoi dati di accesso per continuare."
      }
    },
    "navigation": {
      "dashboard": "Dashboard",
      "planning": "Pianificazione mensile",
      "employees": "Collaboratori",
      "configuration": "Configurazione",
      "notification": {
        "title": "Notifiche",
        "noMessages": "Nessuna notifica",
        "message": {
          "unplannedMonth": "<span class='strong'>Compito del mese</span> \"{0}\" non ancora pianificato",
          "incompleteSubmissions": "<span class='strong'>{0} collaboratori</span> non hanno ancora svolto il compito \"{1}\""
        },
        "timeAgo": {
          "days": "{0} giorni fa",
          "hours": "{0} ore fa",
          "minutes": "{0} minuti fa"
        }
      },
      "user": {
        "logout": "Logout",
        "suvaClientPortal": "mySuva"
      }
    },
    "dashboard": {
      "view": {
        "noData": {
          "title": "Ancora nessun risultato disponibile",
          "noEvaluationData": "L’analisi dei dati è disponibile solo dopo aver completato almeno un mese.",
          "addEmployee": "Aggiungi collaboratore",
          "addMonthlyTask": "Pianifica compito del mese"
        },
        "currentTask": {
          "title": "Stato di avanzamento",
          "completedInfo": "dei collaboratori ha<br><strong> svolto il compito</strong>",
          "notCompletedInfo": "dei collaboratori<br><strong>non ha svolto il compito</strong>",
          "noPackage": "Nessun pacchetto di compiti pianificato",
          "noPackagePast": "Nessun pacchetto di compiti pianificato",
          "planNow": "Pianifica ora il mese"
        },
        "evaluation": {
          "title": "Valutazione della sicurezza e confronto settoriale"
        },
        "tasks": {
          "title": "Panoramica dei compiti mensili"
        },
        "lineChart": {
          "myCompany": "La mia azienda",
          "averageIndustry": "Media del settore"
        }
      }
    },
    "planning": {
      "view": {
        "title": "Pianificazione mensile",
        "task": {
          "active": "In corso",
          "unplanned": "Non pianificato",
          "planned": "Pianificato",
          "completed": "Assegnato",
          "missed": "Non pianificato",
          "submittedUsers": "{0} su {1}",
          "assignInfo": "Aggiungi un pacchetto di compiti a questo mese",
          "plannedInfo": "Le domande saranno disponibile il 1° {0}.",
          "addPackage": "Aggiungi pacchetto di domande",
          "removePackage": "Rimuovi pacchetto di domande",
          "submissionInfo": "Il {0}% dei collaboratori ha svolto il compito del mese.",
          "missedInfo": "Mese in corso: non è più possibile pianificare pacchetti di compiti.",
          "never": "In questo mese non è stato assegnato nessun pacchetto.",
          "result": "Percentuale raggiunta",
          "detailsLink": "Dettagli"
        },
        "package": {
          "title": "Pacchetti di domande disponibili",
          "package": "Pacchetto di domande",
          "neverUsed": "Mai assegnato",
          "activeDate": "Assegnato",
          "result": "Risultato",
          "resultLink": "Risultato",
          "searchFieldPlaceholder": "Cerca argomento…",
          "untestedCheckbox": "Mostra solo pacchetti mai assegnati"
        },
        "result": {
          "title": {
            "first-max": "Ottimo lavoro!",
            "first-good": "Niente male!",
            "first-zero": "Peccato!",
            "second-better": "Fantastico!",
            "second-same": "Ci hai provato",
            "second-worse": "Nessun miglioramento",
            "zero-zero": "Peccato!",
            "demo": "Compito completato"
          },
          "description": {
            "first-max": "Hai concluso il compito del mese con il massimo di {0} caschi di sicurezza su {1}.",
            "first-good": "Hai terminato il compito del mese con {0} caschi di sicurezza su {1}. Se vuoi, puoi riprovare una seconda volta per migliorare il tuo punteggio.",
            "first-zero": "Purtroppo non hai totalizzato nessun casco di sicurezza. Ma puoi sempre riprovarci. Ce la puoi fare!",
            "second-better": "Stavolta hai fatto meglio terminando il compito del mese con {0} caschi di sicurezza su {1}.",
            "second-same": "Nel secondo tentativo hai totalizzato gli stessi punti, terminando il compito del mese con {0} caschi di sicurezza su {1}.",
            "second-worse": "Ci hai provato. Il tuo primo tentativo è andato comunque meglio, quindi mantieni il risultato di {0} caschi di sicurezza su {1}.",
            "zero-zero": "Questo mese purtroppo non hai totalizzato nessun casco di sicurezza. Puoi comunque guadagnare caschi sfidando colleghe e colleghi.",
            "demo": "Hai concluso il compito del mese con {0} di {1} caschi di sicurezza."
          },
          "noResults": "Nessun risultato disponibile",
          "score": "{0} su {1}",
          "headers": {
            "employee": "Nome",
            "participated": "Partecipazione",
            "score": "Caschi totalizzati"
          },
          "viewPackage": "Mostra il pacchetto di compiti",
          "back": "Torna alla panoramica"
        }
      }
    },
    "employees": {
      "view": {
        "title": "Collaboratori",
        "list": {
          "empty": "Nessun collaboratore presente. Invita le tue collaboratrici e i tuoi collaboratori per assegnare loro i compiti mensili.",
          "invite": "Invita collaboratori",
          "lastOnline": "ultimo accesso",
          "headers": {
            "name": "Nome",
            "score": "Caschi",
            "status": "Stato"
          },
          "status": {
            "invited": "Invito spedito",
            "expired": "Invito scaduto",
            "failed": "Invito fallito",
            "deactivated": "Inattivo"
          },
          "actions": {
            "edit": "Modifica",
            "deactivate": "Disattiva",
            "activate": "Attiva",
            "reinvite": "Invita di nuovo",
            "delete": "Cancella"
          },
          "infos": {
            "updated": {
              "title": "Modifiche al profilo apportate",
              "message": "Sono state apportate le modifiche al profilo di {0}."
            },
            "deactivated": {
              "title": "Account disattivato",
              "message": "L’account di {0} è stato disattivato."
            },
            "activated": {
              "title": "Account attivato",
              "message": "L’account di {0} è stato attivato."
            },
            "reinvited": {
              "title": "L’invito è stato spedito.",
              "message": "{0} ha ricevuto per SMS un nuovo invito."
            },
            "deleted": {
              "title": "Utente cancellato",
              "message": "L’utente {0} è stato cancellato."
            },
            "inviteError": {
              "title": "Invio non riuscito",
              "message": "Non è stato possibile spedire l’invito."
            },
            "updateError": {
              "title": "Non è stato possibile aggiornare l’utente.",
              "userAlreadyExists": "Esiste già un utente attivo con questo numero.",
              "unknown": "Riprovare più tardi."
            },
            "deleteError": {
              "title": "Non è stato possibile cancellare l’utente.",
              "unknown": "Riprovare più tardi."
            }
          }
        },
        "invite": {
          "addMore": "Aggiungi altri collaboratori",
          "removeAll": "Rimuovi tutto",
          "importFromCsv": "Importazione da Csv",
          "csvExample": "Esempio",
          "limitReached": "È possibile registrare un massimo di {count} collaboratori in una sola volta",
          "send": "Invita un collaboratore | Invita {count} collaboratori",
          "firstname": "Nome",
          "lastname": "Cognome",
          "phone": "Cellulare",
          "back": "Torna alla panoramica",
          "errors": {
            "exists": "Numero di cellulare già registrato",
            "couldNotCreate": {
              "duplicates": {
                "title": "Numeri di cellulare doppioni",
                "message": "Controlla i dati inseriti e riprova."
              },
              "unknown": {
                "title": "Errore sconosciuto",
                "message": "Si è verificato un errore sconosciuto. Riprova più tardi."
              }
            }
          },
          "warningInfo": {
            "title": "Verifica collaboratore",
            "message": "Non è stato possibile spedire tutti gli inviti."
          }
        },
        "edit": {
          "title": "Modifica collaboratore",
          "firstname": "Nome",
          "lastname": "Cognome",
          "phone": "Cellulare",
          "update": "Salva modifiche"
        }
      }
    },
    "configuration": {
      "view": {
        "title": "Configurazione",
        "logo": "Logo aziendale",
        "changeLogo": "Vuoi cambiare il logo?",
        "name": "Nome dell’azienda",
        "industry": "Settore",
        "zip": "NPA",
        "city": "Località",
        "uploadLogo": "Carica logo aziendale",
        "updateDataClientPortal": "Puoi modificare i tuoi dati cliente nel portale clienti della Suva",
        "dragAndDropInfo": "Trascina e rilascia",
        "actions": {
          "changeLogo": "Modifica logo",
          "uploadLogo": "Carica logo",
          "save": "Salva modifiche",
          "toClientPortal": "Vai al portale clienti"
        }
      }
    },
    "setup": {
      "title": "Benvenuto in 12x12!",
      "lead": "Ottimizziamo i contenuti didattici in modo specifico per il settore della vostra azienda, in modo che i vostri dipendenti possano risolvere le domande appropriate per la loro area di responsabilità.",
      "industry": {
        "label": "Settore",
        "choose": "Seleziona settore",
        "placeholder": "Seleziona"
      },
      "confirm": {
        "title": "Settore selezionato",
        "description": "Hai indicato il settore \"{industry}\". Verifica nuovamente se la voce selezionata è corretta e confermala.<br><br>Non potrai più modificarla.",
        "change": "Modifica voce selezionata"
      },
      "help": {
        "title": "Definire l'industria",
        "description": "Prima di iniziare, vi preghiamo di indicare il settore in cui opera la vostra azienda."
      }
    },
    "help": {
      "overview": {
        "title": "Centro assistenza 12x12",
        "description": "In questa sezione trovi informazioni utili e video brevi riguardanti l’applicazione per addetti alla sicurezza.",
        "startProductTour": "Inizia il tour generale",
        "introTitle": "Primi passi",
        "introDescription": "Clicca sul pulsante per avviare il tour generale e scoprire come muoverti all’interno dell’applicazione.",
        "howItWorks": "Ecco come funziona"
      },
      "detail": {
        "back": "Indietro"
      }
    },
    "forbidden": {
      "noAccessToCompanies": "Non hai accesso alle aziende in 12x12.",
      "noAccessToCompany": "Non hai accesso a questa azienda.",
      "yourCompanies": "Le tue aziende"
    },
    "unauthorized": {
      "title": "Accesso negato",
      "text": "Non hai accesso all’app 12x12.",
      "backLink": "Vai a mySuva"
    }
  }
}
