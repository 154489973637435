<template>
  <div class="chart-doughnut">
    <svg ref="chartRef" class="chart" width="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <linearGradient id="doughnut-grey-gradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="var(--palette-neutral-200)" />
          <stop offset="100%" stop-color="var(--palette-neutral-300)" />
        </linearGradient>
        <linearGradient id="doughnut-green-gradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="var(--palette-emerald-500)" />
          <stop offset="100%" stop-color="var(--palette-emerald-600)" />
        </linearGradient>
        <linearGradient id="doughnut-red-gradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="var(--palette-flamingo-500)" />
          <stop offset="100%" stop-color="var(--palette-flamingo-600)" />
        </linearGradient>
      </defs>
      <g :transform="`translate(${100 / 2}, ${100 / 2})`">
        <path class="arc-background" :d="arcGenerator(100)" :fill="backgroundGradient" />
        <path class="arc-progress" :d="arcGenerator(progress)" :fill="'url(#doughnut-green-gradient)'" />
      </g>
    </svg>
    <div class="inner chart-doghnut-inner">
      <h4 class="h2 title">{{ title }}</h4>
      <p class="subtitle">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'ChartDoughnut',
  props: {
    title: {
      type: String,
      default: 'März',
      required: true,
    },
    subtitle: {
      type: String,
      default: '2021',
      required: true,
    },
    progress: {
      type: [String, Number],
      default: 75,
      required: true,
    },
    finished: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  mounted() {
    this.startupTransition();
  },
  computed: {
    backgroundGradient() {
      return this.finished ? 'url(#doughnut-red-gradient)' : 'url(#doughnut-grey-gradient)';
    },
    arcGenerator() {
      return d3
        .arc()
        .innerRadius(40)
        .outerRadius(50)
        .cornerRadius(10)
        .startAngle(0)
        .endAngle((d) => (d / 100) * 2 * Math.PI);
    },
  },
  methods: {
    startupTransition() {
      const svg = d3.select(this.$refs.chartRef);
      svg.select('.arc-progress').transition().duration(1000).attrTween('d', this.arcTween(this.progress));
    },
    arcTween(angle) {
      return () => {
        const interpolate = d3.interpolate(0, angle);
        return (t) => {
          const interpolatedAngle = interpolate(t);
          return this.arcGenerator(interpolatedAngle);
        };
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-doughnut {
  display: grid;
  place-items: center;
  grid-template-areas: 'chart';

  > .chart {
    width: 100%;
    grid-area: chart;
  }

  > .inner {
    grid-area: chart;
  }
}

.chart-doghnut-inner {
  display: grid;
  text-align: center;
}
</style>
