
export default {
  name: 'WeaknessDetector',
  props: {
    rating: {
      type: String,
      default: '-weak',
      required: true,
    },
  },
};
