import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ebea6090"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-confirm modal-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "title -font-size-21 -font-weight-900" }
const _hoisted_4 = { class: "text -font-size-18 -font-weight-700" }
const _hoisted_5 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('abort'))),
        src: require(`@/assets/icons/close.svg`),
        alt: ""
      }, null, 8, _hoisted_2),
      _createVNode(_component_base_card, {
        class: "content new-challenge",
        imageUrl: _ctx.imageUrl
      }, {
        line1: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('staff.challenge.view.newChallenge.title')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('staff.challenge.view.newChallenge.text', [_ctx.name])), 1)
        ]),
        line2: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('next'))),
              class: "button -primary",
              label: _ctx.$t('staff.challenge.view.newChallenge.confirm')
            }, null, 8, ["label"]),
            _createVNode(_component_app_button, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('abort'))),
              class: "button -secondary",
              label: _ctx.$t('staff.challenge.view.newChallenge.no')
            }, null, 8, ["label"])
          ])
        ]),
        _: 1
      }, 8, ["imageUrl"])
    ])
  ]))
}