import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d89ea5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "locales" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["locales-dropdown", { '-compact': _ctx.compact }]),
    ref: "elementRef"
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale) => {
        return (_openBlock(), _createElementBlock("li", {
          key: locale,
          class: _normalizeClass({ '-current': locale === _ctx.currentLocale })
        }, [
          (locale === _ctx.currentLocale)
            ? (_openBlock(), _createBlock(_component_app_button, {
                key: 0,
                class: "-secondary -link current-locale",
                label: locale.toUpperCase()
              }, null, 8, ["label"]))
            : _createCommentVNode("", true),
          (locale !== _ctx.currentLocale)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: "#",
                onClick: _withModifiers(($event: any) => (_ctx.$emit('changedLocale', locale)), ["prevent"])
              }, _toDisplayString(locale.toUpperCase()), 9, _hoisted_2))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ])
  ], 2))
}