
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MonthlyProgressBar',
  props: {
    progress: {
      // is a number between 0 and 1
      type: Number,
      default: 0,
    },
    isTaskCompleted: {
      type: Boolean,
      default: false,
    },
  },
});
