
import { defineComponent, PropType } from 'vue';

enum ToastStyle {
  info = 'info',
  warning = 'warning',
  error = 'error',
  default = 'default',
}
export default defineComponent({
  name: 'app-toast',
  emits: ['abort', 'confirm'],
  props: {
    type: {
      type: String as PropType<ToastStyle>,
      default: 'default',
    },
  },
});
