
import { defineComponent } from 'vue';

import IconElement from '@/common/elements/IconElement.vue';

export default defineComponent({
  name: 'app-button',
  components: {
    IconElement,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    isLearn: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    iconWidth: {
      type: Number,
      default: 24,
    },
    iconHeight: {
      type: Number,
      default: 24,
    },
    type: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    isImage: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  setup(_props, { emit }) {
    return {
      onClick() {
        emit('click');
      },
    };
  },
});
