import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15da9e7b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-top" }
const _hoisted_2 = { class: "eyebrow" }
const _hoisted_3 = { class: "copy -font-size-10 -font-weight-500" }
const _hoisted_4 = { class: "title h4" }
const _hoisted_5 = { class: "content-bottom" }
const _hoisted_6 = { class: "details" }
const _hoisted_7 = { class: "title copy -font-size-12 -font-weight-700" }
const _hoisted_8 = { class: "calendar" }
const _hoisted_9 = {
  key: 1,
  class: "copy -font-size-10 -font-weight-500"
}
const _hoisted_10 = {
  key: 0,
  class: "result"
}
const _hoisted_11 = { class: "title copy -font-size-12 -font-weight-700" }
const _hoisted_12 = { class: "copy -font-size-10 -font-weight-500" }
const _hoisted_13 = {
  key: 1,
  class: "number"
}
const _hoisted_14 = { class: "copy -font-size-10 -font-weight-500" }
const _hoisted_15 = {
  key: 2,
  class: "link"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_icon_element = _resolveComponent("icon-element")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["question-package-card", {
      '-unplanned': _ctx.status === 'unplanned',
      '-planned': _ctx.status === 'planned',
      '-active': _ctx.status === 'active',
      '-solved-once': _ctx.status === 'solved-once',
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.eyebrow), 1),
        _createVNode(_component_app_button, {
          class: "packagelink -secondary -link -icon-only",
          "is-small": true,
          icon: "file-text",
          "icon-width": 12,
          "icon-height": 12,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('showpackage')))
        })
      ]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass(["date", { '-is-uncompleted': _ctx.status !== 'completed' }])
        }, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.text), 1),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.status === 'completed' || _ctx.status === 'planned')
              ? (_openBlock(), _createBlock(_component_icon_element, {
                  key: 0,
                  class: "icon",
                  icon: "calendar",
                  width: 12,
                  height: 12
                }))
              : _createCommentVNode("", true),
            (_ctx.status === 'planned' || _ctx.status === 'completed')
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.date), 1))
              : _createCommentVNode("", true)
          ])
        ], 2),
        (_ctx.status === 'completed')
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.resultText), 1),
              _createElementVNode("p", _hoisted_12, _toDisplayString(Math.ceil(_ctx.result * 100)) + "%", 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.status === 'completed')
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createVNode(_component_icon_element, {
                class: "icon",
                icon: "person",
                width: 14.3,
                height: 10.57
              }, null, 8, ["width", "height"]),
              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.partipicants), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.status === 'completed')
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_component_app_button, {
                class: "-secondary -link",
                isSmall: "",
                label: _ctx.$t('manager.planning.view.package.resultLink'),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('showresult')))
              }, null, 8, ["label"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}