import { App } from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

const sentry = {
  install: (app: App, { appType }: { appType: string }) => {
    const $el = document.querySelector('#app');
    const appEnv = $el?.getAttribute('data-app-env') ?? 'development';
    const appInstance = $el?.getAttribute('data-app-instance') ?? 'local';

    if (appEnv === 'development') {
      console.debug('Sentry not initialized for development build');
      return;
    }

    const sentryEnvironment = `${appInstance}-${appType}`;

    Sentry.init({
      Vue: app as any,
      dsn: 'https://c2dde37454f846fe9fb0b46273a1551c@o307906.ingest.sentry.io/5668695',
      integrations: [new Integrations.BrowserTracing()],
      environment: sentryEnvironment,
    });

    console.debug(`Sentry initialized for environment: ${sentryEnvironment}`);
  },
};

export default sentry;
