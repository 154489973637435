import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-415b111c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "game-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_game_score = _resolveComponent("game-score")!
  const _component_game_progress = _resolveComponent("game-progress")!
  const _component_icon_element = _resolveComponent("icon-element")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_game_score, { score: _ctx.score }, null, 8, ["score"]),
    _createVNode(_component_game_progress, {
      class: "game-progress",
      current: _ctx.answeredQuestions,
      max: _ctx.totalQuestions,
      animationProgress: _ctx.animationProgress
    }, null, 8, ["current", "max", "animationProgress"]),
    _createVNode(_component_icon_element, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('exit'))),
      class: "image",
      icon: "close",
      width: "18",
      height: "18"
    })
  ]))
}