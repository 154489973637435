
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'base-card',
  emits: ['imageClick'],
  props: {
    imageUrl: {
      type: String,
      required: false,
      default: 'https://source.unsplash.com/random',
    },
    imageOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    imgWidth: {
      type: [Number, String],
      required: false,
      default: 104,
    },
    imgHeight: {
      type: [Number, String],
      required: false,
      default: 104,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
