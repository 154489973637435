
import { defineComponent, ref } from 'vue';
import useVerifyAnswer from '@/learn-app/common/useVerifyAnswer';
import GameButton from '@/learn-app/components/game/GameButton.vue';
import GameHeader from '@/learn-app/components/game/GameHeader.vue';

export default defineComponent({
  name: 'game-question-safe-or-not',
  components: {
    GameButton,
    GameHeader,
  },
  emits: ['exit'],
  props: {
    score: {
      type: Number,
      required: false,
      default: 2,
    },
    answeredQuestions: {
      type: Number,
      required: false,
      default: 3,
    },
    totalQuestions: {
      type: Number,
      required: false,
      default: 10,
    },
    animationProgress: {
      type: Boolean,
      required: false,
      default: false,
    },
    imagePath: {
      type: String,
      required: false,
      default: require('@/assets/images/learn-app/YesOrNo/yesorno1.png'),
    },
  },
  setup() {
    const hasChanges = ref(false);
    const imageRef = ref<HTMLImageElement>();
    const onChange = () => (hasChanges.value = true);

    return {
      ...useVerifyAnswer(),
      hasChanges,
      onChange,
      imageRef,
    };
  },
});
