import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-493350cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-card" }
const _hoisted_2 = { class: "circle" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "image-wrapper",
        style: _normalizeStyle({
          height: _ctx.imgHeight + 'px',
          width: _ctx.imgWidth + 'px',
        })
      }, [
        _createElementVNode("img", {
          src: _ctx.imageUrl,
          alt: "",
          class: "image"
        }, null, 8, _hoisted_3)
      ], 4),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "loading-overlay",
            style: _normalizeStyle({
          height: _ctx.imgHeight + 'px',
          width: _ctx.imgWidth + 'px',
        })
          }, [
            _createElementVNode("img", {
              class: "image",
              src: require('@/assets/icons/loading-indicator.svg'),
              alt: ""
            }, null, 8, _hoisted_4)
          ], 4))
        : (_ctx.imageOverlay)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "image-overlay",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('imageClick'))),
              style: _normalizeStyle({
          height: _ctx.imgHeight + 'px',
          width: _ctx.imgWidth + 'px',
        })
            }, [
              _createElementVNode("img", {
                class: "image",
                src: require('@/assets/icons/photo.svg'),
                alt: ""
              }, null, 8, _hoisted_5)
            ], 4))
          : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "line1", {}, undefined, true),
    _renderSlot(_ctx.$slots, "line2", {}, undefined, true)
  ]))
}