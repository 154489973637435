
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'MonthlyTaskPlannerStatus',
  props: {
    status: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const setStatusClass = computed(() => {
      if (props.status === 'active') {
        return '-active';
      }
      if (props.status === 'planned') {
        return '-planned';
      }
      if (props.status === 'missed') {
        return '-missed';
      }
      return '-unplanned';
    });
    return {
      setStatusClass,
    };
  },
});
