import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e52cdda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "password-rating" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_form = _resolveComponent("input-form")!
  const _component_weakness_detector = _resolveComponent("weakness-detector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_input_form, {
      class: "password",
      type: "password",
      name: "password",
      modelValue: _ctx.password,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
      onChange: _cache[1] || (_cache[1] = (e) => _ctx.$emit('update:modelValue', e.target.value)),
      label: _ctx.$t('staff.choosePassword.view.password'),
      isPW: "",
      required: "",
      autocomplete: "new-password"
    }, null, 8, ["modelValue", "label"]),
    _createVNode(_component_weakness_detector, {
      class: _normalizeClass([{ '-show': _ctx.password }, "rating"]),
      rating: _ctx.getRating
    }, null, 8, ["class", "rating"])
  ]))
}