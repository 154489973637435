import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0553f2cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "circle-header" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content card-content" }
const _hoisted_5 = { class: "card-date" }
const _hoisted_6 = { class: "copy -font-size-10" }
const _hoisted_7 = { class: "title h4" }
const _hoisted_8 = { class: "card-info" }
const _hoisted_9 = { class: "state card-state" }
const _hoisted_10 = { class: "state copy -font-size-12 -font-weight-700" }
const _hoisted_11 = {
  key: 0,
  class: "result card-result"
}
const _hoisted_12 = { class: "text copy -font-size-12 -font-weight-700" }
const _hoisted_13 = { class: "result" }
const _hoisted_14 = { class: "score -font-size-16 -font-weight-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!
  const _component_questionpack_state = _resolveComponent("questionpack-state")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["questionpack-card", {
      '-disabled': _ctx.state === _ctx.QuestionState.Scheduled,
      '-active': _ctx.state === _ctx.QuestionState.Current,
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cardClickHandler()))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          class: "icon",
          src: _ctx.icon,
          width: "60",
          height: "60",
          ref: "icon",
          alt: "question-package-icon"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.canUserSubmit)
          ? (_openBlock(), _createBlock(_component_icon_element, {
              key: 0,
              class: "lock",
              icon: "lock",
              width: "16",
              height: "16"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_icon_element, {
            class: "icon",
            icon: "calendar",
            width: "12",
            height: "12"
          }),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.formatToMonthYeah(_ctx.date)), 1)
        ]),
        _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.name), 1),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('staff.dashboard.taskCard.state')), 1),
            _createVNode(_component_questionpack_state, {
              state: _ctx.state,
              title: _ctx.name,
              doneDate: _ctx.formatDateDefault(_ctx.doneDate),
              scheduled: _ctx.formatDayMonthYear(_ctx.scheduled),
              canUserSubmit: _ctx.canUserSubmit
            }, null, 8, ["state", "title", "doneDate", "scheduled", "canUserSubmit"])
          ]),
          (_ctx.state === _ctx.QuestionState.Completed || _ctx.state === _ctx.QuestionState.SolvedOnce)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('staff.dashboard.taskCard.result')), 1),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_icon_element, {
                    isImage: "",
                    class: "iconhelmet",
                    icon: "helmet"
                  }),
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.score) + " " + _toDisplayString(_ctx.$t('staff.dashboard.taskCard.of')) + " " + _toDisplayString(_ctx.maxPoints), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 2))
}