import axios, { AxiosError } from 'axios';
import { computed, ref } from 'vue';
import useCrypto from '@/common/utils/useCrypto';
import { demoDataForRequest } from '@/common/modules/demoMode';
import { DEMO_MODE_ENABLED } from '@/common/modules/appMode';

export type ApiOptions = {
  encrypted?: boolean;
};

const { CancelToken } = axios;

const { decrypt } = useCrypto();

export default function useApi(endpoint: string, options: ApiOptions = {}) {
  const { encrypted = false } = options;

  const api = axios.create({
    baseURL: '/',
    withCredentials: true,
  });

  const data = ref();
  const loading = ref(false);
  const error = ref();
  const cancel = ref();

  const cancelToken = new CancelToken((c) => {
    cancel.value = c;
  });

  function reloadOn401OrThrowError(err: AxiosError) {
    if (err.response?.status === 401) {
      window.location.reload();
    } else {
      throw err;
    }
  }

  const post = (payload?: Record<string, any>, settings?: Record<string, any>) => {
    loading.value = true;
    error.value = undefined;

    if (DEMO_MODE_ENABLED) {
      const fun = demoDataForRequest(endpoint, payload);
      if (fun) {
        return fun()
          .then((r) => {
            data.value = r;
          })
          .finally(() => (loading.value = false));
      }

      console.debug('Post Request skipped: ', endpoint);
      return null;
    }

    return api
      .post(endpoint, payload, {
        ...settings,
        cancelToken,
      })
      .then((res) => (data.value = convertedData(res.data)))
      .catch((e) => {
        if (axios.isCancel(e)) {
          console.log('Request canceled', e.message);
        } else {
          error.value = e;
          reloadOn401OrThrowError(e);
        }
      })
      .finally(() => (loading.value = false));
  };

  const put = (payload?: Record<string, any>, settings?: Record<string, any>) => {
    // Note: Never used for demo mode
    if (DEMO_MODE_ENABLED) {
      console.debug('Put Request skipped: ', endpoint);
      return null;
    }

    loading.value = true;
    error.value = undefined;

    return api
      .put(endpoint, payload, { ...settings, cancelToken })
      .then((res) => (data.value = convertedData(res.data)))
      .catch((e) => {
        if (axios.isCancel(e)) {
          console.log('Request canceled', e.message);
        } else {
          error.value = e;
          reloadOn401OrThrowError(e);
        }
      })
      .finally(() => (loading.value = false));
  };

  const get = (query?: Record<string, any>, settings?: Record<string, any>) => {
    loading.value = true;
    error.value = undefined;

    if (DEMO_MODE_ENABLED) {
      const fun = demoDataForRequest(endpoint, query);
      if (fun) {
        return fun()
          .then((r) => {
            data.value = r;
          })
          .finally(() => (loading.value = false));
      }

      console.debug('Get Request skipped: ', endpoint);

      return null;
    }

    let queryString = '';

    if (query) {
      queryString = Object.entries(query)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
      queryString = `?${queryString}`;
    }

    return api
      .get(endpoint + queryString, { ...settings, cancelToken })
      .then((res) => {
        data.value = convertedData(res.data);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          console.log('Request canceled', e.message);
        } else {
          error.value = e;
          reloadOn401OrThrowError(e);
        }
      })
      .finally(() => (loading.value = false));
  };

  const del = (settings?: Record<string, any>) => {
    // Note: Never used for demo mode
    if (DEMO_MODE_ENABLED) {
      console.debug('Delete Request skipped: ', endpoint);
      return null;
    }

    loading.value = true;
    error.value = undefined;

    return api
      .delete(endpoint, { ...settings, cancelToken })
      .then((res) => (data.value = convertedData(res.data)))
      .catch((e) => {
        if (axios.isCancel(e)) {
          console.log('Request canceled', e.message);
        } else {
          error.value = e;
          reloadOn401OrThrowError(e);
        }
      })
      .finally(() => (loading.value = false));
  };

  const errorMessage = computed(() => {
    console.log('?? compute', error.value);

    if (error.value) {
      return error.value.message;
    }
    return null;
  });

  const errorDetails = computed(() => {
    if (error.value && error.value.response) {
      return error.value.response.data.message;
    }
    return null;
  });

  const errorFields = computed(() => {
    if (error.value && Array.isArray(error.value.response.data.message)) {
      return (error.value.response.data.message as string[]).reduce((acc: Record<string, any>, msg: string) => {
        let [field] = msg.split(' ');

        // TODO: Maximal...
        if (field === 'maximal') field = 'dateOfBirth';

        if (!acc[field]) {
          acc[field] = [];
        }

        acc[field].push(msg);

        return acc;
      }, {});
    }
    return null;
  });

  const computedClasses = (key: string) => {
    // eslint-disable-next-line no-prototype-builtins
    if (errorFields.value?.hasOwnProperty(key)) {
      return ['border-red-600', 'bg-red-200', 'text-red-900'];
    }
    return ['border-grey-600', 'bg-white', 'text-gray-900'];
  };

  function convertedData(value: any) {
    return encrypted ? decrypt(value) : value;
  }

  return {
    loading,
    data,
    error,
    get,
    post,
    put,
    del,
    errorMessage,
    errorDetails,
    errorFields,
    computedClasses,
    cancel,
  };
}
