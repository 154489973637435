{
  "common": {
    "ok": "Ok",
    "save": "Speichern",
    "cancel": "Abbrechen",
    "yes": "Ja",
    "no": "Nein",
    "continue": "Weiter",
    "unknownError": "Unbekannter Fehler",
    "back": "Zurück",
    "confirm": "Bestätigen",
    "locale": "Sprache"
  },
  "locales": {
    "de": "Deutsch",
    "fr": "Französisch",
    "it": "Italienisch"
  },
  "offline": {
    "message": "Keine Internet-Verbindung. Du musst mit dem Internet verbunden sein, um diese App zu benutzen."
  },
  "hints": {
    "popover": {
      "title": "Tipp",
      "texts": {
        "dashboardEmployeeAdd": "Lade nun deine Mitarbeitenden zur Lern-Applikation ein.",
        "dashboardTaskAdd": "Plane als erstes die Monatsaufgaben ein",
        "planningQuestionPackagePool": "Wähle ein Fragenpaket aus dem Fragenkatalog aus und füge es per drag&drop in den gewünschten Monat ein.",
        "employeesInviteFields": "Hier kannst du alle deine Mitarbeitenden eintragen und dann einladen. Du kannst auch später noch weitere hinzufügen.",
        "employeesInviteAdd": "Klicke auf den Button hinzufügen, um weitere Mitarbeitende in die Liste einzufügen. Mit dem Klick auf \"Mitarbeitenden einladen\", werden diese per SMS eingeladen."
      }
    },
    "productTour": {
      "title": {
        "1": "Kurze Einführung",
        "2": "Übersicht schaffen (1 von 6)",
        "3": "Planen & Überprüfen (2 von 6)",
        "4": "Einladen & Verwalten (3 von 6)",
        "5": "Firma verwalten (4 von 6)",
        "6": "Erinnerungen (5 von 6)",
        "7": "Fragen? (6 von 6)"
      },
      "texts": {
        "1": "Hallo {name}. Folgend eine kurze Einleitung zum Aufbau und den Funktionen der Applikation in 6 Schritten.",
        "2": "Auf dem Dashboard kannst du dir Übersicht verschaffen, die wichtigsten Informationen sind hier für dich bereitgestellt.",
        "3": "Plane die Aufgaben für deine Mitarbeitenden.",
        "4": "Lade hier deine Mitarbeitenden ein und überprüfe später ihren Lernstand.<br><br>Die Suva geht davon aus, dass nur rechtmässige Daten erfasst werden.",
        "5": "Konfiguriere unter diesem Punkt deine Firma.",
        "6": "Hier erscheinen Erinnerungen, die dich bei deiner Arbeit unterstützen, z.B. wenn der nächste Monat noch nicht geplant ist.",
        "7": "Klicke hier, wenn du nicht weiter weist oder um diese Einführung noch einmal zu starten."
      }
    }
  },
  "form": {
    "phone": {
      "invalidFormat": "Ungültige Nummer"
    },
    "select": {
      "choose": "Bitte wählen"
    }
  },
  "staff": {
    "navigation": {
      "logo": "Firmenlogo",
      "navItems": {
        "start": "Aufgaben",
        "onboarding": "Onboarding",
        "challenge": "Herausfordern",
        "challenges": "Herausforderungen",
        "challenge-list": "Challenge-Liste",
        "employees": "Mitarbeitende",
        "profile": "Profil",
        "logout": "Logout",
        "demo-start": "Suva 12x12 Demo"
      }
    },
    "login": {
      "view": {
        "title": "Einloggen",
        "mobile": "Mobile",
        "password": "Passwort",
        "login": "Einloggen",
        "rememberMe": "An mich erinnern",
        "forgotPassword": "Passwort vergessen?",
        "forgotPasswordQuestion": "Hast du dein Passwort vergessen?",
        "invalidCredentials": "Telefonnummer oder Passwort ist nicht korrekt."
      }
    },
    "demo": {
      "banner": "Demo-Modus",
      "start": "Demo starten",
      "reset": "Neu starten",
      "selectIndustry": "Branche wählen",
      "selectIndustryPlaceholder": "Bitte wählen",
      "afterTaskInformation": "Beispielhafte Monatsaufgabe wurde gelöst. Du kannst eine weiteres Aufgabenpaket spielen, dich weiter über die Funktionalitäten von 12x12 informieren oder dich als SiBe Registrieren und 12x12 für deinen Betrieb gratis einsetzen.",
      "shareSubject": "12x12 Demo",
      "shareBody": "Hallo\n\nDieses Online-Quiz für die Arbeitssicherheit könnte dich interessieren:\n{shareLink}\n\nFreundliche Grüsse",
      "actions": {
        "moreInfo": "Was bietet 12x12 alles?",
        "playAgain": "Weitere Monatsaufgabe spielen",
        "share": "12x12 Demo teilen",
        "activate": "12x12 für deinen Betrieb aktivieren"
      }
    },
    "game": {
      "next": "Weiter",
      "reset": "Zurücksetzen",
      "goOnToFirstQuestion": "Zur ersten Aufgabe",
      "goOn": "Weitermachen",
      "yesAbort": "Ja, beenden",
      "secure": "Sicher",
      "unsecure": "Unsicher",
      "verifying": "Antwort wird überprüft...",
      "correctAnswer": "Korrekte Antwort",
      "explanation": "Begründung",
      "goodJob": "Gute Arbeit!",
      "challengeSomebody": "Jemanden Herausfordern",
      "backToStart": "Zurück zum Start",
      "scoreText": "Du hast die Monatsaufgabe abgeschlossen und {0} von {1} Sicherheitshelmen gesammelt.",
      "quitQuestion": "Bist du sicher, dass du aufhören möchtest?",
      "progressWillNotSaved": "Dein Fortschritt wird nicht gespeichert.",
      "challangeSomebody": "Jemand Herausfordern",
      "tryAgain": "Nochmals lösen",
      "identify-risks": "Gefahren erkennen",
      "quiz": "Quiz",
      "safe-or-not": "Sicher oder nicht?",
      "instructions": {
        "identify-risks": "Markiere die Stelle auf dem Bild, die dir unsicher oder gefährlich erscheint. Pro Bild gibt es einen \"Fehler\".",
        "quiz": "Wähle aus drei möglichen Antworten die richtige aus.",
        "safe-or-not": "Siehst du in den folgenden Situationen unsichere Verhaltensweise oder sonstige Gefahren? Wurde alles richtig gemacht?"
      },
      "machineName": {
        "work_in_isolation": "Alleinarbeit",
        "tree_assessment": "Baum beurteilen",
        "falling_space": "Fallbereich",
        "loads": "Lasten",
        "falling": "Absturz",
        "psa": "PSA",
        "working_materials": "Arbeitsmittel"
      },
      "result": {
        "PASS": "Korrekte Antwort",
        "FAIL": "Falsche Antwort",
        "PARTLYPASS": "Teilweise richtig"
      }
    },
    "choosePassword": {
      "view": {
        "title": "Passwort setzen",
        "mobile": "Mobile",
        "password": "Passwort setzen",
        "passwordConfirmation": "Passwort bestätigen",
        "button": "Passwort setzen",
        "rememberMe": "An mich erinnern",
        "invalidToken": "Einladung ist ungültig oder abgelaufen. Melde dich bei deinem Sicherheitsbeauftragten.",
        "passwordsMismatch": "Passwort stimmt nicht überein. Bitte überprüfe dein Passwort."
      }
    },
    "challenge": {
      "view": {
        "page": {
          "title": "Herausforderungen",
          "ends": "Endet in",
          "employees": "Mitarbeitende",
          "your-score": "Dein Punktestand"
        },
        "challengerList": {
          "openChallenges": "Offene Herausforderungen",
          "closedChallenges": "Beendete Herausforderungen"
        },
        "challenger-card": {
          "expired": "Herausforderung ist abgelaufen.",
          "declined": "Herausforderung wurde abgelehnt.",
          "invited": "Einladung versendet",
          "challenged": "Du wurdest herausgefordert",
          "win": "Du hast gewonnen! {0} {1} - Du {2}",
          "loss": "Du hast verloren! {0} {1} - Du {2}",
          "draw": "Unentschieden! {0} {1} - Du {2}"
        },
        "shakeToChoose": {
          "shake": "Bewege dein Smartphone, um automatisch ein Themengebiet und Fragentyp zu wählen oder klicke auf weiter.",
          "noShake": "Klicke auf weiter, um automatisch ein Themengebiet und Fragentyp zu wählen."
        },
        "slotMachine": {
          "title": "Herausforderung wird gewählt",
          "subtitle": "Welches wird deine neue Herausforderung?"
        },
        "newChallenge": {
          "title": "Neue Herausforderung",
          "text": "Möchtest du {0} zu einem Wissensduell herausfordern?",
          "acceptChallengePrompt": "{0} fordert dich zu einem Wissensduell heraus.",
          "primaryAction": "Annehmen",
          "secondaryAction": "Ablehnen",
          "linkAction": "Vielleicht später",
          "confirm": "Ja, los gehts",
          "no": "Nein",
          "next": "Weiter",
          "start": "Start",
          "chosenChallenge": {
            "title": "Deine Herausforderung",
            "cta": "Ja, los gehts"
          }
        },
        "result": {
          "wellDone": "Gut gemacht!",
          "done": "Geschafft!",
          "descriptionScore": "Deine Punktzahl in diesem Duell",
          "descriptionInfo": "{0} wird über deine Herausforderung informiert.",
          "titleWon": "Du hast die Herausforderung gewonnen!",
          "titleLost": "Du hast die Herausforderung leider verloren",
          "titleDraw": "Unentschieden!",
          "ctaBack": "Zurück zur Übersicht"
        }
      }
    },
    "passwordReset": {
      "view": {
        "title": "Passwort zurücksetzen",
        "description": "Gib die mit deinem Konto verknüpfte Mobile Nummer ein und wir senden dir eine SMS mit Anweisungen zum Zurücksetzen deines Passworts.",
        "mobile": "Mobile",
        "reset": "Anweisungen senden"
      }
    },
    "checkSMS": {
      "view": {
        "title": "SMS überprüfen",
        "description": "Wir haben dir die Anweisungen zur Wiederherstellung deines Passworts per SMS gesendet. Lese diese SMS.",
        "question": "Keine SMS erhalten?",
        "link": "Nochmals versuchen"
      }
    },
    "dashboard": {
      "filter": {
        "todo": "Aktuell",
        "done": "Erledigt"
      },
      "info": {
        "solved": "Aktuelle Monatsaufgabe bereits gelöst. Du wirst per SMS informiert, wenn die nächste Monatsaufgabe gelöst werden kann.",
        "notPlanned": "Noch keine geplanten Monatsaufgaben. Du wirst per SMS informiert, wenn die nächste Monatsaufgabe gelöst werden kann.",
        "notApproved": "Noch keine freigegebenen Monatsaufgaben. Du wirst per SMS informiert, wenn deine aktuelle Monatsaufgabe gelöst werden kann.",
        "actionNewChallenge": "Fordere deine Kollegen heraus"
      },
      "taskCard": {
        "state": "Status",
        "done": "Erledigt",
        "notDone": "Nicht erledigt",
        "at": "am",
        "notYetReleased": "Noch nicht freigegeben",
        "currentMonthlyTask": "Aktuelle Monatsaufgabe",
        "taskNotEdited": "Aufgabe wurde nicht bearbeitet.",
        "releasingDate": "Freigabe erfolgt am",
        "result": "Resultat",
        "of": "von"
      },
      "view": {
        "collectMore": "Du hast die aktuelle Monatsaufgaben bereits gelöst! Willst du noch mehr Punkte sammeln?",
        "challengeYourFriends": "Fordere deine Kollegen zu einem Duell heraus."
      }
    },
    "onboarding": {
      "lets_go": "Los geht's",
      "screens": {
        "1": {
          "title": "12x12 Lernapplikation: 12 Aufgaben in 12 Monaten",
          "description": "Dein SiBe stellt dir jeden Monat eine Aufgabe zu einem sicherheitsrelevanten Thema.",
          "img": "01_onboarding"
        },
        "2": {
          "title": "So funktionieren Monatsaufgaben",
          "description": "Jede Monatsaufgabe besteht aus zwei oder drei Teilaufgaben der Typen: \"Gefahr erkennen\", \"Quiz\", \"Sicher oder nicht?\"",
          "img": "02_onboarding"
        },
        "3": {
          "title": "Sammle Punkte in Form von Sicherheitshelmen",
          "description": "Für jede richtige Antwort auf eine Frage erhältst du einen Punkt in Form von einem Sicherheitshelm.",
          "img": "03_onboarding"
        },
        "4": {
          "title": "Fordere deine Arbeitskollegen heraus",
          "description": "Teste deinen Wissensstand, indem du deine Arbeitskollegen und Arbeitskolleginnen herausforderst.",
          "img": "04_onboarding"
        },
        "5": {
          "title": "Füge dein Profilbild hinzu",
          "description": "Damit dich dein SiBe und deine Arbeitskollegen besser finden können, füge ein Profilbild von dir hinzu.",
          "img": "05_onboarding"
        },
        "6": {
          "title": "Applikation zum Home-Bildschirm hinzufügen",
          "description": "Um schnell Zugriff auf die Applikation zu haben, speicher dir den Link der Applikation auf deinem Home-Bildschirm.",
          "img": "06_onboarding"
        }
      }
    },
    "disclaimer": {
      "title": "Datenschutz",
      "text": "Die Benutzung des 12x12 Quiz findet im Rahmen der freiwilligen Prävention statt und hat keine rechtlichen Auflagen oder Konsequenzen. Die Anwendung zeichnet Daten lediglich temporär auf dem Smartphone des Nutzers auf – die Auswertung ist nur ihrem SiBe zugänglich. Im Übrigen gelten die allgemeinen <a class='link' href='https://www.suva.ch/de-ch/allgemein/datenschutz' target='_blank'>Nutzungsbedingungen und Datenschutzbestimmungen</a> der Suva.",
      "accept": "Akzeptieren und weiter"
    },
    "errorHandling": {
      "defaultTitle": "Oops!",
      "defaultDescription": "Da ging was schief. Bitte erneut versuchen."
    },
    "profile": {
      "editProfile": "Profil ändern",
      "saveChanges": "Änderungen speichern",
      "firstName": "Vorname",
      "lastName": "Name",
      "phone": "Mobile",
      "fileSizeError": "Das gewählte Bild überschreitet die maximale Grösse von {0}MB.",
      "fileTypeError": "Das gewählte Format wird nicht unterstützt.",
      "hintprofileImage": "Bilder werden quadratisch zugeschnitten. Dein Gesicht sollte mittig sein, damit es auf dem Profilbild richtig zu erkennen ist.",
      "hintMobileNumber": "Wenn du deine Nummer ändern möchtest, kontaktiere bitte deinen SiBe.",
      "accountActivatedAt": "Account aktiviert"
    }
  },
  "manager": {
    "login": {
      "view": {
        "title": "Logge dich ein",
        "subtitle": " Bitte gib deine Zugangsdaten ein um fortzufahren."
      }
    },
    "navigation": {
      "dashboard": "Dashboard",
      "planning": "Monatsplanung",
      "employees": "Mitarbeitende",
      "configuration": "Konfiguration",
      "notification": {
        "title": "Benachrichtigungen",
        "noMessages": "Keine Benachrichtigungen",
        "message": {
          "unplannedMonth": "<span class='strong'>Monatsaufgabe</span> \"{0}\" noch nicht geplant",
          "incompleteSubmissions": "<span class='strong'>{0} Mitarbeitende</span> haben Monatsaufgabe \"{1}\" noch nicht gelöst"
        },
        "timeAgo": {
          "days": "Vor {0} Tagen",
          "hours": "Vor {0} Stunden",
          "minutes": "Vor {0} Minuten"
        }
      },
      "user": {
        "logout": "Logout",
        "suvaClientPortal": "mySuva"
      }
    },
    "dashboard": {
      "view": {
        "noData": {
          "title": "Noch kein Resultate vorhanden",
          "noEvaluationData": "Die Auswertung der Daten kann erst nach Erfassung von mindestens einem Monat erfolgen.",
          "addEmployee": "Mitarbeiter hinzufügen",
          "addMonthlyTask": "Monatsaufgabe planen"
        },
        "currentTask": {
          "title": "Fortschritt Monatsaufgabe",
          "completedInfo": "der Mitarbeitenden haben<br><strong>Monatsaufgabe erledigt</strong>",
          "notCompletedInfo": "der Mitarbeitenden haben<br><strong>Monatsaufgabe nicht erledigt</strong>",
          "noPackage": "Kein Aufgabenpaket eingeplant",
          "noPackagePast": "Kein Aufgabenpaket eingeplant",
          "planNow": "Jetzt Monat planen"
        },
        "evaluation": {
          "title": "Sicherheitswertung & Branchenvergleich"
        },
        "tasks": {
          "title": "Übersicht Monatsaufgaben"
        },
        "lineChart": {
          "myCompany": "Meine Firma",
          "averageIndustry": "Durchschnitt Branche"
        }
      }
    },
    "planning": {
      "view": {
        "title": "Monatsplanung",
        "task": {
          "active": "Läuft gerade",
          "unplanned": "Ungeplant",
          "planned": "Geplant",
          "completed": "Abgefragt",
          "missed": "Ungeplant",
          "submittedUsers": "{0} von {1}",
          "assignInfo": "Weise diesem Monat ein Aufgabenpaket hinzu",
          "plannedInfo": "Fragen sind ab dem 1. {0} verfügbar.",
          "addPackage": "Fragepaket hinzufügen",
          "removePackage": "Fragepaket entfernen",
          "submissionInfo": "{0}% der Mitarbeitenden haben die Monatsaufgabe erledigt.",
          "missedInfo": "Laufender Monat: Aufgabenpaket kann nicht mehr eingeplant werden.",
          "never": "In diesem Monat wurde kein Paket abgefragt.",
          "result": "Erreichte Wertung",
          "detailsLink": "Details"
        },
        "package": {
          "title": "Fragen-Pool",
          "package": "Fragepaket",
          "neverUsed": "Noch nie abgefragt",
          "activeDate": "Abgefragt",
          "result": "Resultat",
          "resultLink": "Resultat",
          "searchFieldPlaceholder": "Nach Aufgaben suchen...",
          "untestedCheckbox": "Nur ungetestete Fragepakete anzeigen"
        },
        "result": {
          "title": {
            "first-max": "Super Arbeit!",
            "first-good": "Gute Arbeit!",
            "first-zero": "ZONK!",
            "second-better": "Super Durchgang!",
            "second-same": "Guter Versuch",
            "second-worse": "Keine Verbesserung",
            "zero-zero": "ZONK!",
            "demo": "Aufgabe beendet"
          },
          "description": {
            "first-max": "Du hast die Monatsaufgabe mit dem Maximum von {0} von {1} Sicherheitshelmen abgeschlossen.",
            "first-good": "Du hast die Monatsaufgabe mit {0} von {1} Sicherheitshelmen beendet. Wenn du magst, kannst die Monatsaufgabe noch ein zweites mal lösen um dich zu verbessern.",
            "first-zero": "Du hast diesmal leider keine Sicherheitshelme gesammelt. Versuche es gleich noch ein zweites mal. Da geht noch was!",
            "second-better": "Du hast dich verbessert und die Monatsaufgabe mit {0} von {1} Sicherheitshelmen abgeschlossen.",
            "second-same": "Du hast beim 2. Durchgang deinen Punktestand halten können und schliesst die Monatsaufgabe mit {0} von {1} Sicherheitshelmen ab.",
            "second-worse": "Guter Versuch. Dein erster Durchgang war allerdings besser, entsprechend bleibt es bei deinem Resultat von {0} von {1} gesammelten Sicherheitshelmen.",
            "zero-zero": "Du hast diesen Monat leider keine Sicherheitshelme gesammelt. Du kannst dennoch Helme sammeln, in dem du deine Mitarbeitenden herausforderst.",
            "demo": "Du hast die Monatsaufgabe mit {0} von {1} Sicherheitshelmen abgeschlossen."
          },
          "noResults": "Kein Resultat vorhanden",
          "score": "{0} von {1}",
          "headers": {
            "employee": "Mitarbeitende",
            "participated": "Teilgenommen",
            "score": "Gesammelte Helme"
          },
          "viewPackage": "Fragepaket Inhalte anzeigen",
          "back": "Zurück zur Übersicht"
        }
      }
    },
    "employees": {
      "view": {
        "title": "Mitarbeitende",
        "list": {
          "empty": "Keine Mitarbeitende vorhanden. Lade deine Mitarbeitenden ein, um ihnen Monatsaufgaben zuzuweisen.",
          "invite": "Mitarbeitende einladen",
          "lastOnline": "zuletzt online",
          "headers": {
            "name": "Name",
            "score": "Helme",
            "status": "Status"
          },
          "status": {
            "invited": "Einladung verschickt",
            "expired": "Einladung abgelaufen",
            "failed": "Einladung fehlgeschlagen",
            "deactivated": "Inaktiv"
          },
          "actions": {
            "edit": "Bearbeiten",
            "deactivate": "Deaktivieren",
            "activate": "Aktivieren",
            "reinvite": "Nochmals einladen",
            "delete": "Löschen"
          },
          "infos": {
            "updated": {
              "title": "Profil-Änderungen übernommen",
              "message": "Die Änderungen im Profil von {0} wurden übernommen."
            },
            "deactivated": {
              "title": "Account wurde deaktiviert",
              "message": "Der Account von {0} wurde deaktiviert."
            },
            "activated": {
              "title": "Account wurde aktiviert",
              "message": "Der Account von {0} wurde aktiviert."
            },
            "reinvited": {
              "title": "Einladung wurde verschickt",
              "message": "{0} hat erneut eine Einladung per SMS zur Lern Applikation erhalten."
            },
            "deleted": {
              "title": "Benutzer wurde gelöscht",
              "message": "{0} wurde gelöscht."
            },
            "inviteError": {
              "title": "Fehlgeschlagen",
              "message": "Einladung konnte nicht verschickt werden"
            },
            "updateError": {
              "title": "Mitarbeiter konnte nicht aktualisiert werden.",
              "userAlreadyExists": "Es existiert bereits ein aktiver Benutzer mit dieser Nummer.",
              "unknown": "Versuchen Sie es später nochmals."
            },
            "deleteError": {
              "title": "Mitarbeiter konnte nicht gelöscht werden.",
              "unknown": "Versuchen Sie es später nochmals."
            }
          }
        },
        "invite": {
          "addMore": "Weitere Mitarbeitende hinzufügen",
          "removeAll": "Alle entfernen",
          "importFromCsv": "Aus Csv importieren",
          "csvExample": "Beispiel runterladen",
          "limitReached": "Es können maximal {count} Mitarbeiter auf einmal erfasst werden.",
          "send": "{count} Mitarbeiter einladen | {count} Mitarbeitende einladen",
          "firstname": "Vorname",
          "lastname": "Nachname",
          "phone": "Mobile",
          "back": "Zurück zur Übersicht",
          "errors": {
            "exists": "Mobile Nummer ist bereits vergeben.",
            "couldNotCreate": {
              "duplicates": {
                "title": "Doppelte Mobile-Nummern",
                "message": "Bitte überprüfe deine Eingaben und versuche es erneut."
              },
              "unknown": {
                "title": "Unbekannter Fehler",
                "message": "Es ist ein unbekannter Fehler aufgetreten. Versuche es später nochmals."
              }
            }
          },
          "warningInfo": {
            "title": "Mitarbeiter überprüfen",
            "message": "Es konnten nicht alle Einladungen verschickt werden."
          }
        },
        "edit": {
          "title": "Mitarbeiter bearbeiten",
          "firstname": "Vorname",
          "lastname": "Nachname",
          "phone": "Mobile",
          "update": "Änderungen übernehmen"
        }
      }
    },
    "configuration": {
      "view": {
        "title": "Konfiguration",
        "logo": "Dein Firmenlogo",
        "changeLogo": "Möchtest du dein Logo ändern?",
        "noLogoUploaded": "Es wurde noch kein Logo hochgeladen.",
        "name": "Firmenname",
        "industry": "Branche",
        "zip": "PLZ",
        "city": "Ort",
        "uploadLogo": "Firmenlogo hochladen",
        "updateDataClientPortal": "Du kannst deine Kundendaten im Kundenportal von Suva anpassen",
        "dragAndDropInfo": "Drag and Drop",
        "actions": {
          "changeLogo": "Logo ändern",
          "uploadLogo": "Logo hochladen",
          "save": "Änderungen speichern",
          "toClientPortal": "Zum Kundenportal"
        }
      }
    },
    "setup": {
      "title": "Willkommen bei 12x12!",
      "lead": "Wir optimieren die Lerninhalte spezifisch auf die Branche deiner Firma, damit deine Mitarbeitenden die passenden Fragen zu ihrem Aufgabengebiet lösen können.",
      "industry": {
        "label": "Branche",
        "choose": "Branche wählen",
        "placeholder": "Bitte wählen"
      },
      "confirm": {
        "title": "Branche gewählt",
        "description": "Du hast die Branche \"{industry}\" festgelegt. Bitte überprüfe erneut, ob die Auswahl korrekt ist und bestätige diese.<br><br>Du kannst die Auswahl nicht wiederrufen.",
        "change": "Auswahl ändern"
      },
      "help": {
        "title": "Branche festlegen",
        "description": "Bevor wir starten, gib bitte die Branche in der deine Firma tätig ist an."
      }
    },
    "help": {
      "overview": {
        "title": "Hilfe Center 12x12",
        "description": "Hier findest du hilfreiche Infos und kurze Videos rund um deine SiBe-Applikation.",
        "startProductTour": "Produkttour starten",
        "introTitle": "Erste Schritte",
        "introDescription": "Hier kannst du die Produkttour nochmals starten, um zu erfahren, was die ersten Schritte in deiner SiBe-Applikation sind.",
        "howItWorks": "So funktioniert's"
      },
      "detail": {
        "back": "Zurück"
      }
    },
    "forbidden": {
      "noAccessToCompanies": "Du hast keinen Zugriff auf Firmen in 12x12.",
      "noAccessToCompany": "Du hast auf diese Firma keinen Zugriff.",
      "yourCompanies": "Deine Firmen"
    },
    "unauthorized": {
      "title": "Zugriff verweigert",
      "text": "Sie haben keinen Zugriff auf die 12x12 App.",
      "backLink": "Zu mySuva"
    }
  }
}
