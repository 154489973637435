
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconElement',
  props: {
    icon: {
      type: String,
      required: false,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 24,
    },
    height: {
      type: [Number, String],
      required: false,
      default: 24,
    },
    isImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
