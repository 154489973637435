
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'app-button-card',
  emits: ['click'],
  props: {
    label: {
      type: String,
      required: true,
    },
    secondary: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(_props, { emit }) {
    return {
      onClick() {
        emit('click');
      },
    };
  },
});
