
import { defineComponent, ref, computed } from 'vue';
import PasswordValidator from 'password-validator';

import InputForm from '@/common/components/form/InputForm.vue';
import WeaknessDetector from '@/common/components/form/WeaknessDetector.vue';

export default defineComponent({
  name: 'PasswordRating',
  components: {
    InputForm,
    WeaknessDetector,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const password = ref('');
    const getRating = computed(() => {
      const isStrong = new PasswordValidator().is().min(12).has().uppercase().has().symbols(1);
      const isStrong2 = new PasswordValidator().is().min(20);
      const isGood = new PasswordValidator().is().min(10).has().uppercase();
      const isGood2 = new PasswordValidator().is().min(12);
      const isOk = new PasswordValidator().is().min(10);
      const isOk2 = new PasswordValidator().is().min(8).has().uppercase();

      if (isStrong.validate(password.value) || isStrong2.validate(password.value)) {
        return '-strong';
      }
      if (isGood.validate(password.value) || isGood2.validate(password.value)) {
        return '-good';
      }
      if (isOk.validate(password.value) || isOk2.validate(password.value)) {
        return '-ok';
      }
      return '-weak';
    });
    return {
      password,
      getRating,
    };
  },
});
