
import { computed, defineComponent, ref } from 'vue';

import IconElement from '@/common/elements/IconElement.vue';

export default defineComponent({
  name: 'input-form',
  components: {
    IconElement,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
      default: '',
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    isPW: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
  },
  emits: ['focus', 'update:modelValue'],
  setup(props) {
    const currentType = ref(props.type);

    function switchVisibility() {
      if (currentType.value === 'password') {
        currentType.value = 'text';
      } else {
        currentType.value = 'password';
      }
    }

    // prettier-ignore
    const visibleLabel = computed(() => [props.label, !props.modelValue ? props.placeholder : null].filter((i) => !!i).join(' '));

    return {
      currentType,
      switchVisibility,
      visibleLabel,
    };
  },
});
