
import { defineComponent } from 'vue';

import IconElement from '@/common/elements/IconElement.vue';

export default defineComponent({
  name: 'employee-card',
  components: {
    IconElement,
  },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
  },
});
