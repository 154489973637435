<template>
  <div class="onboard-container">
    <div class="stepper" ref="stepElementRef">
      <div
        @click="nextStep"
        class="step"
        :class="{
          '-is-active': index === activeStep,
          '-previous-active': index === activeStep - 1,
        }"
        v-for="(step, index) in steps"
        :key="`step-${index}`"
      >
        <onboard-step :title="step.title" :img="step.img" :description="step.description" />
      </div>
    </div>
  </div>
  <div class="onboard-controls">
    <onboard-stepper :numberOfSteps="steps.length" :activeStep="activeStep"> </onboard-stepper>
    <app-button @click="nextStep" class="-primary control" :label="getButtonText(steps)"></app-button>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useSwipe } from '@vueuse/core';
import AppButton from '@/common/components/AppButton.vue';
import { useI18n } from 'vue-i18n';
import OnboardStep from './OnboardStep.vue';
import OnboardStepper from './OnboardStepper.vue';

export default {
  name: 'OnboardContainer',
  components: { OnboardStep, OnboardStepper, AppButton },
  props: {
    steps: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ['complete'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const stepElementRef = ref(null);

    const activeStep = ref(0);

    function nextStep() {
      if (activeStep.value === props.steps.length - 1) {
        emit('complete');

        return;
      }
      activeStep.value += 1;
    }

    function previousStep() {
      if (activeStep.value > 0) {
        activeStep.value -= 1;
      }
    }

    function getButtonText(steps) {
      if (activeStep.value === steps.length - 1) return t('staff.onboarding.lets_go');

      return t('common.continue');
    }

    const { isSwiping, direction } = useSwipe(stepElementRef, {
      onSwipeEnd(e, dir) {
        if (dir === 'LEFT') {
          nextStep();
        } else if (dir === 'RIGHT') {
          previousStep();
        }
      },
    });

    return {
      stepElementRef,
      isSwiping,
      direction,
      activeStep,
      nextStep,
      getButtonText,
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/shared/_animations.scss';

.onboard-container {
  position: relative;
  height: 100%;
  background: $gradient-yellow;
  overflow: hidden;

  > .stepper {
    display: flex;
    justify-content: center;
    height: 100%;

    > .step {
      position: absolute;
      height: 100%;
      transition: 300ms $easeOutQuart;
      transform: translateX(100%);
      opacity: 0;

      &.-previous-active {
        transform: translateX(-100%);
      }

      &.-is-active {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

.onboard-controls {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  bottom: calc(50px - (6px / 2));
  width: 100%;
  transform: translate(-50%, 50%);
  left: 50%;

  > .control {
    margin-left: 54px;
  }
}
</style>
