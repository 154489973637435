import { GameContext } from '@/learn-app/types/GameContext';
import { inspect } from '@xstate/inspect';
import { useActor } from '@xstate/vue';
import { assign, createMachine, interpret } from 'xstate';

if (process.env.VUE_APP_INSPECT_MODE === 'true') {
  inspect({ iframe: false });
}

const gameMachine = createMachine<GameContext>(
  {
    id: 'game',
    initial: 'idle',
    context: {
      setId: undefined,
      sets: [],
      setIndex: 0,
      currentSet: undefined,
      questionIndex: 0,
      currentQuestion: undefined,
      score: 0,
      maxScore: 0,
      answeredQuestions: 0,
      totalQuestions: 0,
      result: undefined,
      gameMode: undefined,
    },
    states: {
      idle: {
        on: {
          NEXT: 'intro',
        },
      },
      intro: {
        on: {
          NEXT: 'question',
          ABORT: 'idle',
          DEBUG_AUTOCOMPLETE: 'final',
        },
        entry: assign({
          /* eslint-disable @typescript-eslint/no-unused-vars */
          questionIndex: (_ctx) => 0,
          currentSet: (ctx) => (ctx.sets?.length ? ctx.sets[ctx.setIndex] : undefined),
        }),
      },
      question: {
        entry: assign({
          // prettier-ignore
          currentQuestion: (ctx) => (
            ctx.currentSet ? ctx.currentSet.questions[ctx.questionIndex] : undefined
          ),
          answeredQuestions: (ctx) => ctx.answeredQuestions + 1,
        }),
        on: {
          NEXT: 'result',
          ABORT: 'idle',
        },
      },
      result: {
        on: {
          NEXT: [
            {
              target: 'question',
              cond: 'hasAnotherQuestion',
              actions: assign({
                questionIndex: (ctx) => ctx.questionIndex + 1,
              }),
            },
            {
              target: 'intro',
              cond: 'hasAnotherSet',
              actions: assign({
                setIndex: (ctx) => ctx.setIndex + 1,
              }),
            },
            {
              target: 'final',
            },
          ],
          ABORT: 'idle',
        },
      },
      final: {
        on: {
          NEXT: 'intro',
          ABORT: 'idle',
        },
        exit: assign({
          questionIndex: (_ctx) => 0,
          setIndex: (_ctx) => 0,
          score: (_ctx) => 0,
          answeredQuestions: (_ctx) => 0,
        }),
      },
    },
  },
  {
    guards: {
      // check if there is another set question
      hasAnotherSet: (ctx: GameContext) => (ctx.sets ? ctx.setIndex < ctx.sets.length - 1 : false),
      // check if there is another question set
      // prettier-ignore
      hasAnotherQuestion: (ctx: GameContext) => (
        ctx.sets ? ctx.questionIndex < ctx.sets[ctx.setIndex].questions.length - 1 : false
      ),
    },
  },
);
const gameService = interpret(gameMachine, { devTools: true });
gameService.start();

export const useGameService = (): any => useActor(gameService);
export const useGameInterpreter = (): any => gameService;
