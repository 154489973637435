
import { PropType, defineComponent, ref, onMounted, computed, Ref, watch } from 'vue';
import { QuestionResult } from '@/learn-app/types/QuestionResult';
import { QuestionTypes } from '@/learn-app/types/QuestionTypes';
import FixedGameButton from '@/learn-app/components/game/FixedGameButton.vue';
import { useI18n } from 'vue-i18n';
import { computeIdentifyRiskCellsArray, IdentifyRiskSolutionCell } from '@/common/utils/identifyRiskUtils';

interface ImageCords {
  x: number;
  y: number;
  result?: string;
}

interface IdentifyRiskResult {
  choosenSpots: [ImageCords];
  solutionSpots: number[];
}

export default defineComponent({
  name: 'game-question-result',
  components: {
    FixedGameButton,
  },
  emits: ['next'],
  props: {
    questionType: {
      type: String as PropType<QuestionTypes>,
      required: false,
      default: QuestionTypes.Quiz,
    },
    result: {
      type: String as PropType<QuestionResult>,
      required: false,
      default: QuestionResult.PASS,
    },
    question: {
      type: String,
      required: false,
      default: 'Wann dürfen Holzerntearbeiten ausgeführt werden?',
    },
    correctAnswer: {
      type: String,
      required: false,
      default: 'Wenn Sicht- Ruf- oder Funkverbindung vorhanden ist',
    },
    justification: {
      type: String,
      required: false,
      default: 'Somit ist jederzeit Hilfe gewährleistet',
    },
    imagePath: {
      type: String,
      required: true,
      default: require('@/assets/images/learn-app/YesOrNo/yesorno2.png'),
    },
    givenAnswer: {
      type: Object as PropType<IdentifyRiskResult>,
      required: false,
      default: () => ({
        choosenSpots: [],
        solutionSpots: [],
      }),
    },
  },
  setup(props) {
    const { t } = useI18n();
    const imageRef = ref<HTMLImageElement>();
    const getResultText = (result: string) => t(`staff.game.result.${result}`);

    const cells: Ref<Record<number, IdentifyRiskSolutionCell>> = ref({});
    const createCellsArray = () => {
      cells.value = computeIdentifyRiskCellsArray(props.givenAnswer.solutionSpots);
    };

    const imageHeight = ref(0);
    const imageWidth = ref(0);

    const calculateImageSizes = () => {
      if (imageRef.value) {
        imageHeight.value = Number(imageRef.value?.getBoundingClientRect().height);
        imageWidth.value = Number(imageRef.value?.getBoundingClientRect().width);
      }
    };

    watch(imageRef, () => {
      if (imageRef.value) {
        imageRef.value.onload = () => {
          calculateImageSizes();
        };
      }
    });

    const xScale = computed(() => (value: number) => (imageWidth.value / 100) * value);
    const yScale = computed(() => (value: number) => (imageHeight.value / 100) * value);

    const getResultIcon = (result: QuestionResult) => {
      const iconMapping = {
        PASS: 'result-right',
        FAIL: 'result-wrong',
      };
      return iconMapping[result];
    };

    onMounted(() => {
      window.addEventListener('resize', () => {
        calculateImageSizes();
      });
      calculateImageSizes();

      if (props.givenAnswer !== null) {
        createCellsArray();
      }
    });

    return {
      getResultText,
      getResultIcon,
      imageRef,
      QuestionTypes,
      cells,
      QuestionResult,
      xScale,
      yScale,
    };
  },
});
