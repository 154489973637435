{
  "common": {
    "ok": "Ok",
    "save": "Enregistrer",
    "cancel": "Annuler",
    "yes": "Oui",
    "no": "Non",
    "continue": "Suivant",
    "unknownError": "Erreur inconnue",
    "back": "Précédent",
    "confirm": "Confirmer",
    "locale": "Langue"
  },
  "locales": {
    "de": "Allemand",
    "fr": "Français",
    "it": "Italien"
  },
  "offline": {
    "message": "Aucune connexion Internet. Vous devez être connecté à Internet pour utiliser cette application."
  },
  "hints": {
    "popover": {
      "title": "Conseil",
      "texts": {
        "dashboardEmployeeAdd": "Invitez maintenant vos collaborateurs à utiliser l’application.",
        "dashboardTaskAdd": "Planifiez d’abord les exercices mensuels.",
        "planningQuestionPackagePool": "Sélectionnez un lot de questions depuis le catalogue correspondant, puis insérez-le dans le mois souhaité via un glisser-déposer.",
        "employeesInviteFields": "Vous pouvez saisir ici tous vos collaborateurs avant de les inviter. Vous pourrez en saisir d’autres ultérieurement.",
        "employeesInviteAdd": "Cliquez sur le bouton «Ajouter» afin de saisir d’autres collaborateurs dans la liste. Il suffit de cliquer sur «Inviter des collaborateurs» pour les inviter par SMS."
      }
    },
    "productTour": {
      "title": {
        "1": "Brève introduction",
        "2": "Créer un aperçu (1 sur 6)",
        "3": "Planifier et vérifier (2 sur 6)",
        "4": "Inviter et gérer (3 sur 6)",
        "5": "Gérer la société (4 sur 6)",
        "6": "Rappels (5 sur 6)",
        "7": "Avez-vous des questions? (6 sur 6)"
      },
      "texts": {
        "1": "Salut {name}. Vous trouverez ci-dessous une brève introduction à la structure et aux fonctions de l’application en 6 étapes.",
        "2": "Le tableau de bord vous permet de créer un aperçu. Les informations essentielles y sont déjà affichées.",
        "3": "Planifiez les tâches pour vos collaborateurs.",
        "4": "Invitez ici vos collaborateurs et vos collaboratrices à participer et vérifiez ensuite leurs progrès.<br><br>La Suva part du principe que seules sont traitées des données licites.",
        "5": "Configurez votre société dans cette rubrique.",
        "6": "Vous voyez ici des rappels qui vous soutiennent dans votre travail, p. ex. le mois suivant n’est pas encore planifié.",
        "7": "Cliquez ici si vous ne savez pas comment faire pour continuer ou si vous souhaitez relancer l’introduction."
      }
    }
  },
  "form": {
    "phone": {
      "invalidFormat": "Numéro incorrect, veuillez le saisir dans le bon format: {format}"
    },
    "select": {
      "choose": "Veuillez sélectionner"
    }
  },
  "staff": {
    "navigation": {
      "logo": "Logo de l'entreprise",
      "navItems": {
        "start": "Exercices",
        "onboarding": "Onboarding",
        "challenge": "Lancer un défi",
        "challenges": "Défis",
        "challenge-list": "Liste des défis",
        "employees": "Collaborateur",
        "profile": "Profil",
        "logout": "Déconnexion",
        "demo-start": "Suva 12x12 démo"
      }
    },
    "login": {
      "view": {
        "title": "Se connecter",
        "mobile": "Tél. portable",
        "password": "Mot de passe",
        "login": "Se connecter",
        "rememberMe": "Se souvenir de moi",
        "forgotPassword": "Mot de passe oublié?",
        "forgotPasswordQuestion": "Avez-vous oublié votre mot de passe?",
        "invalidCredentials": "Le numéro de téléphone ou mot de passe est incorrect."
      }
    },
    "demo": {
      "banner": "Mode démo",
      "start": "Démarrer la démo",
      "reset": "Redémarrer",
      "selectIndustry": "Sélectionner une branche",
      "selectIndustryPlaceholder": "Sélectionnez",
      "afterTaskInformation": "L’exemple d’exercice mensuel a été réalisé. Vous pouvez essayer avec une autre série d’exercices, continuer à vous informer sur les fonctionnalités 12x12 ou vous inscrire en tant que préposé à la sécurité et utiliser l’appli gratuitement pour votre entreprise.",
      "shareSubject": "Démo 12x12",
      "shareBody": "Bonjour,\n\nCe quiz en ligne sur la sécurité au travail pourrait t’intéresser:\n{shareLink}\n\nAvec mes meilleures salutations",
      "actions": {
        "moreInfo": "Que vous offre 12x12?",
        "playAgain": "Faire un autre exercice mensuel",
        "share": "Partager la démo 12x12",
        "activate": "Activer 12x12 pour votre entreprise"
      }
    },
    "game": {
      "next": "Suivant",
      "reset": "Réinitialiser",
      "goOnToFirstQuestion": "Accéder au premier exercice",
      "goOn": "Souhaitez-vous poursuivre?",
      "yesAbort": "Oui, quitter",
      "secure": "Sûr",
      "unsecure": "Pas sûr",
      "verifying": "La réponse est en cours de vérification...",
      "correctAnswer": "Réponse correcte",
      "explanation": "Justification",
      "goodJob": "Bon travail!",
      "challengeSomebody": "Lancer un défi à quelqu’un",
      "backToStart": "Retour au début",
      "scoreText": "Vous avez réalisé l’exercice du mois et gagné {0} sur {1} casques de sécurité.",
      "quitQuestion": "Êtes-vous sûr de vouloir arrêter?",
      "progressWillNotSaved": "Votre taux de réalisation ne sera pas enregistré.",
      "challangeSomebody": "Lancer un défi à quelqu’un",
      "tryAgain": "Refaire l’exercice",
      "identify-risks": "Identifier les dangers",
      "quiz": "Quiz",
      "safe-or-not": "Sûr ou pas sûr?",
      "instructions": {
        "identify-risks": "Marquez sur la photo l’endroit présentant des risques ou des dangers. Il y a une erreur sur chaque photo.",
        "quiz": "Sélectionnez une réponse exacte parmi ces trois réponses.",
        "safe-or-not": "Dans les situations suivantes, voyez-vous des comportements à risque ou d’autres dangers? Tout a-t-il été fait dans les règles de l’art?"
      },
      "machineName": {
        "work_in_isolation": "Travail en solo",
        "tree_assessment": "Examen de l’arbre",
        "falling_space": "Zone de chute",
        "loads": "Charges",
        "falling": "Chutes de hauteur",
        "psa": "EPI",
        "working_materials": "Équipements de travail"
      },
      "result": {
        "PASS": "Réponse correcte",
        "FAIL": "Mauvaise réponse",
        "PARTLYPASS": "Réponse en partie correcte"
      }
    },
    "choosePassword": {
      "view": {
        "title": "Saisie du mot de passe",
        "mobile": "Tél. portable",
        "password": "Saisie du mot de passe",
        "passwordConfirmation": "Confirmation du mot de passe",
        "button": "Saisie du mot de passe",
        "rememberMe": "Se souvenir de moi",
        "invalidToken": "L’invitation n’est pas valable ou a expiré. Veuillez vous annoncer à votre préposé à la sécurité.",
        "passwordsMismatch": "Le mot de passe est erroné. Veuillez vérifier votre mot de passe."
      }
    },
    "challenge": {
      "view": {
        "page": {
          "title": "Défis",
          "ends": "Se termine dans",
          "employees": "Collaborateur",
          "your-score": "Votre nombre de points"
        },
        "challengerList": {
          "openChallenges": "Défis en suspens",
          "closedChallenges": "Défis terminés"
        },
        "challenger-card": {
          "expired": "Le défi a expiré.",
          "declined": "Le défi a été refusé.",
          "invited": "Invitation envoyée",
          "challenged": "On vous a lancé un défi",
          "win": "Vous avez gagné! {0} {1} - Vous {2}",
          "loss": "Vous avez perdu! {0} {1} - Vous {2}",
          "draw": "Match nul! {0} {1} - Vous {2}"
        },
        "shakeToChoose": {
          "shake": "Déplacez votre smartphone pour sélectionner automatiquement un champ thématique et un type de question ou cliquez sur «Suivant».",
          "noShake": "Cliquez sur «Suivant» pour sélectionner automatiquement un champ thématique et un type de question."
        },
        "slotMachine": {
          "title": "Le défi est sélectionné",
          "subtitle": "Quel sera votre nouveau défi?"
        },
        "newChallenge": {
          "title": "Nouveaux défis",
          "text": "Voulez-vous {0} participer à un défi pour tester vos connaissances?",
          "acceptChallengePrompt": "{0} vous lance un défi pour tester vos connaissances.",
          "primaryAction": "Accepter",
          "secondaryAction": "Refuser",
          "linkAction": "Peut-être plus tard",
          "confirm": "C’est parti!",
          "no": "Non",
          "next": "Suivant",
          "start": "Démarrer",
          "chosenChallenge": {
            "title": "Votre défi",
            "cta": "C’est parti!"
          }
        },
        "result": {
          "wellDone": "Parfait!",
          "done": "Exercice réussi!",
          "descriptionScore": "Votre nombre de points pour ce duel",
          "descriptionInfo": "{0} sera informé de votre défi.",
          "titleWon": "Vous avez remporté le défi!",
          "titleLost": "Vous avez malheureusement perdu",
          "titleDraw": "Match nul!",
          "ctaBack": "Retour à l’aperçu"
        }
      }
    },
    "passwordReset": {
      "view": {
        "title": "Réinitialiser le mot de passe",
        "description": "Entrez le numéro de téléphone portable associé à votre compte afin que nous puissions vous envoyer par SMS les instructions nécessaires pour la réinitialisation de votre mot de passe.",
        "mobile": "Tél. portable",
        "reset": "Envoyer les instructions"
      }
    },
    "checkSMS": {
      "view": {
        "title": "Vérifier le SMS",
        "description": "Nous vous avons envoyé par SMS les instructions nécessaires pour la réinitialisation de votre mot de passe. Lisez ce SMS.",
        "question": "Vous n’avez pas reçu de SMS?",
        "link": "Essayer encore"
      }
    },
    "dashboard": {
      "filter": {
        "todo": "Actuel",
        "done": "Réalisé"
      },
      "info": {
        "solved": "Exercice mensuel actuel déjà réalisé. Vous serez informé par SMS lorsque le prochain exercice mensuel pourra être réalisé.",
        "notPlanned": "Encore aucun exercice mensuel planifié. Vous serez informé par SMS lorsque le prochain exercice mensuel pourra être réalisé.",
        "notApproved": "Encore aucun exercice mensuel validé. Vous êtes informé par SMS lorsque l’actuel exercice mensuel peut être réalisé.",
        "actionNewChallenge": "Lancez un défi à vos collègues"
      },
      "taskCard": {
        "state": "Statut",
        "done": "Réalisé",
        "notDone": "Pas réalisé",
        "at": "le",
        "notYetReleased": "Pas encore validé",
        "currentMonthlyTask": "Exercice mensuel actuel",
        "taskNotEdited": "La tâche n’a pas été traitée.",
        "releasingDate": "Validation réalisée le",
        "result": "Résultat",
        "of": "par"
      },
      "view": {
        "collectMore": "Vous avez déjà réalisé les exercices mensuels actuels! Voulez-vous gagner encore plus de points?",
        "challengeYourFriends": "Lancez un duel à vos collègues."
      }
    },
    "onboarding": {
      "lets_go": "Allez",
      "screens": {
        "1": {
          "title": "Application 12x12: 12 exercices en 12 mois",
          "description": "Votre préposé à la sécurité vous propose chaque mois un exercice sur un thème concernant la sécurité.",
          "img": "01_onboarding"
        },
        "2": {
          "title": "Les exercices mensuels fonctionnent ainsi.",
          "description": "Chaque exercice mensuel est composé de deux ou trois exercices partiels du type: «Identifier les dangers», «Quiz», «Sûr ou pas sûr?»",
          "img": "02_onboarding"
        },
        "3": {
          "title": "Gagnez des points sous la forme de casques de sécurité.",
          "description": "Pour chaque bonne réponse, vous gagnez un point sous la forme d’un casque de sécurité.",
          "img": "03_onboarding"
        },
        "4": {
          "title": "Lancez un défi à vos collègues.",
          "description": "Testez votre niveau de connaissances en lançant un défi à vos collègues.",
          "img": "04_onboarding"
        },
        "5": {
          "title": "Ajoutez votre photo",
          "description": "Ajoutez votre photo afin que votre préposé à la sécurité et vos collègues puissent vous trouver plus facilement.",
          "img": "05_onboarding"
        },
        "6": {
          "title": "Ajouter l’application sur votre écran d’accueil",
          "description": "Pour avoir un accès rapide à l’application, sauvegardez le lien d’accès à l’application sur votre écran d’accueil.",
          "img": "06_onboarding"
        }
      }
    },
    "disclaimer": {
      "title": "Protection des données",
      "text": "L’utilisation du Quiz 12x12 s’inscrit dans le cadre de la prévention volontaire. Elle ne s’accompagne d’aucune condition ou conséquence juridique. L’application n’enregistre des données que de manière temporaire sur le smartphone de l’utilisateur. L’accès à l’analyse est exclusivement réservé à votre préposé à la sécurité. Les <a class='link' href='https://www.suva.ch/fr-ch/generalites/directive-sur-la-protection-des-donnees?lang=fr-CH' target='_blank'>conditions générales d’utilisation et les dispositions en matière de protection des données</a> de la Suva sont applicables.",
      "accept": "Accepter et continuer"
    },
    "errorHandling": {
      "defaultTitle": "Oups!",
      "defaultDescription": "Une erreur est survenue. Veuillez réessayer."
    },
    "profile": {
      "editProfile": "Modifier le profil",
      "saveChanges": "Enregistrer les modifications",
      "firstName": "Prénom",
      "lastName": "Nom",
      "phone": "Tél. portable",
      "fileSizeError": "La photo sélectionnée dépasse la taille maximale de {0}MB.",
      "fileTypeError": "Le format sélectionné n’est pas supporté.",
      "hintprofileImage": "Les photos sont découpées en carré. Votre visage doit être centré afin d’être aisément reconnaissable sur la photo.",
      "hintMobileNumber": "Veuillez contacter votre préposé à la sécurité dans le cas où vous souhaiteriez changer vos numéros.",
      "accountActivatedAt": "Compte activé"
    }
  },
  "manager": {
    "login": {
      "view": {
        "title": "Connectez-vous",
        "subtitle": " Veuillez saisir vos données d’accès pour continuer."
      }
    },
    "navigation": {
      "dashboard": "Tableau de bord",
      "planning": "Programme mensuel",
      "employees": "Collaborateur",
      "configuration": "Configuration",
      "notification": {
        "title": "Notifications",
        "noMessages": "Pas de notifications",
        "message": {
          "unplannedMonth": "<span class=’strong’>Exercice mensuel</span> \"{0}\" pas encore planifié",
          "incompleteSubmissions": "<span class=’strong’>{0} collaborateurs</span> n’ont pas encore réalisé l’exercice mensuel \"{1}\""
        },
        "timeAgo": {
          "days": "Il y a {0} jours",
          "hours": "Il y a {0} heures",
          "minutes": "Il y a {0} minutes"
        }
      },
      "user": {
        "logout": "Déconnexion",
        "suvaClientPortal": "mySuva"
      }
    },
    "dashboard": {
      "view": {
        "noData": {
          "title": "Pas encore de résultats",
          "noEvaluationData": "Les données sont analysées au moins un mois après la saisie.",
          "addEmployee": "Ajouter un collaborateur",
          "addMonthlyTask": "Planifier un exercice mensuel"
        },
        "currentTask": {
          "title": "Taux de réalisation de l’exercice mensuel",
          "completedInfo": "des collaborateurs ont<br><strong>réalisé l’exercice mensuel</strong>",
          "notCompletedInfo": "des collaborateurs n’ont pas<br><strong>réalisé l’exercice mensuel</strong>",
          "noPackage": "Aucun lot d’exercices planifié ",
          "noPackagePast": "Aucun lot d’exercices planifié ",
          "planNow": "Planifier le mois maintenant"
        },
        "evaluation": {
          "title": "Évaluation de la sécurité et comparaison sectorielle"
        },
        "tasks": {
          "title": "Aperçu des exercices mensuels"
        },
        "lineChart": {
          "myCompany": "Mon entreprise",
          "averageIndustry": "Moyenne de la branche"
        }
      }
    },
    "planning": {
      "view": {
        "title": "Programme mensuel",
        "task": {
          "active": "En cours",
          "unplanned": "Non planifié",
          "planned": "Planifié",
          "completed": "Consulté",
          "missed": "Non planifié",
          "submittedUsers": "{0} sur {1}",
          "assignInfo": "Attribuez un lot d’exercices à ce mois",
          "plannedInfo": "Les questions seront disponible le 1er {0}.",
          "addPackage": "Ajouter un lot de questions",
          "removePackage": "Supprimer un lot de questions",
          "submissionInfo": "{0}% des collaborateurs ont réalisé l’exercice mensuel.",
          "missedInfo": "Mois en cours: le lot d’exercices ne peut plus être planifié.",
          "never": "Aucun lot n’a été consulté ce mois-ci.",
          "result": "Évaluation obtenue",
          "detailsLink": "Détails"
        },
        "package": {
          "title": "Pool de questions",
          "package": "Lot de questions",
          "neverUsed": "Pas encore consulté",
          "activeDate": "Consulté",
          "result": "Résultat",
          "resultLink": "Résultat",
          "searchFieldPlaceholder": "Rechercher des exercices...",
          "untestedCheckbox": "Afficher uniquement les lots de questions non testés"
        },
        "result": {
          "title": {
            "first-max": "Beau boulot!",
            "first-good": "Bon travail!",
            "first-zero": "Domage!",
            "second-better": "Super essai!",
            "second-same": "Bonne tentative",
            "second-worse": "Pas d’amélioration",
            "zero-zero": "Domage!",
            "demo": "Fin de l'exercice"
          },
          "description": {
            "first-max": "Vous avez terminé l’exercice mensuel avec le maximum de {0} sur {1} casques de sécurité.",
            "first-good": "Vous avez terminé l’exercice mensuel avec {0} sur {1} casques de sécurité. Si vous le souhaitez, vous pouvez réaliser l’exercice mensuel une seconde fois afin de vous améliorer.",
            "first-zero": "Cette fois-ci, vous n’avez malheureusement pas gagné de casques de sécurité. Veuillez essayer une seconde fois. Vous pouvez mieux faire!",
            "second-better": "Vous vous êtes amélioré et avez terminé l’exercice mensuel avec {0} sur {1} casques de sécurité.",
            "second-same": "Lors du second essai, vous avez marqué le même nombre de points et terminé l’exercice mensuel avec {0} sur {1} casques de sécurité.",
            "second-worse": "Bonne tentative. Comme le résultat de votre premier essai était meilleur, vous conservez les {0} sur {1} casques de sécurité que vous avez gagnés lors de cet essai.",
            "zero-zero": "Ce mois-ci, vous n’avez malheureusement gagné aucun casque de sécurité. Cependant, vous pouvez gagner des casques de sécurité en lançant des défis à vos collaborateurs.",
            "demo": "Tu as terminé l’exercice mensuel avec {0} de {1} casques de sécurité."
          },
          "noResults": "Aucun résultat",
          "score": "{0} sur {1}",
          "headers": {
            "employee": "Collaborateur",
            "participated": "A participé",
            "score": "Casques gagnés"
          },
          "viewPackage": "Afficher le lot de questions",
          "back": "Retour à l’aperçu"
        }
      }
    },
    "employees": {
      "view": {
        "title": "Collaborateur",
        "list": {
          "empty": "Aucun collaborateur. Invitez vos collaborateurs pour leur attribuer des exercices mensuels.",
          "invite": "Inviter un collaborateur",
          "lastOnline": "en ligne",
          "headers": {
            "name": "Nom",
            "score": "Casques",
            "status": "Statut"
          },
          "status": {
            "invited": "Invitation envoyée",
            "expired": "Invitation expirée",
            "failed": "Échec de l’invitation",
            "deactivated": "Inactif"
          },
          "actions": {
            "edit": "Traiter",
            "deactivate": "Désactiver",
            "activate": "Activer",
            "reinvite": "Inviter à nouveau",
            "delete": "Supprimer"
          },
          "infos": {
            "updated": {
              "title": "Modifications du profil reprises ",
              "message": "Les modifications du profil de {0} ont été reprises."
            },
            "deactivated": {
              "title": "Le compte a été désactivé",
              "message": "Le compte de {0} a été désactivé."
            },
            "activated": {
              "title": "Le compte a été activé",
              "message": "Le compte de {0} a été activé."
            },
            "reinvited": {
              "title": "L’invitation a été envoyée",
              "message": "{0} a reçu à nouveau une invitation par SMS pour l’application."
            },
            "deleted": {
              "title": "L’utilisateur a été supprimé",
              "message": "{0} a été supprimé."
            },
            "inviteError": {
              "title": "A échoué",
              "message": "L’invitation n’a pas pu être envoyée"
            },
            "updateError": {
              "title": "Le collaborateur n’a pas pu être actualisé.",
              "userAlreadyExists": "Il existe déjà un utilisateur actif avec ce numéro.",
              "unknown": "Veuillez réessayer plus tard."
            },
            "deleteError": {
              "title": "Le collaborateur n’a pas pu être supprimé.",
              "unknown": "Veuillez réessayer plus tard."
            }
          }
        },
        "invite": {
          "addMore": "Ajouter d’autres collaborateurs",
          "removeAll": "Supprimer tout",
          "importFromCsv" : "Importer à partir de Csv",
          "csvExample" : "Télécharger un exemple",
          "limitReached" : "Un maximum de {count} collaborateurs peut être saisi à la fois",
          "send": "Inviter un collaborateur | Inviter {count} collaborateurs",
          "firstname": "Prénom",
          "lastname": "Nom de famille",
          "phone": "Tél. portable",
          "back": "Retour à l’aperçu",
          "errors": {
            "exists": "Le numéro de téléphone portable est déjà attribué.",
            "couldNotCreate": {
              "duplicates": {
                "title": "Numéros de téléphone portable doubles",
                "message": "Veuillez vérifier les informations saisies et réessayer."
              },
              "unknown": {
                "title": "Erreur inconnue",
                "message": "Une erreur inconnue est survenue. Veuillez réessayer plus tard."
              }
            }
          },
          "warningInfo": {
            "title": "Vérifier le collaborateur",
            "message": "Toutes les invitations n’ont pas pu être envoyées."
          }
        },
        "edit": {
          "title": "Traiter le collaborateur",
          "firstname": "Prénom",
          "lastname": "Nom de famille",
          "phone": "Tél. portable",
          "update": "Reprendre les modifications"
        }
      }
    },
    "configuration": {
      "view": {
        "title": "Configuration",
        "logo": "Logo de l’entreprise",
        "changeLogo": "Voulez-vous modifier le logo de l’entreprise?",
        "name": "Nom de l’entreprise",
        "industry": "Branche",
        "zip": "NPA",
        "city": "Localité",
        "uploadLogo": "Télécharger le logo de l’entreprise",
        "updateDataClientPortal": "Vous pouvez modifier vos données clients dans le portail clients de la Suva",
        "dragAndDropInfo": "Glisser-déposer",
        "actions": {
          "changeLogo": "Modifier le logo",
          "uploadLogo": "Télécharger le logo de l’entreprise",
          "save": "Enregistrer les modifications",
          "toClientPortal": "Accéder au portail clients"
        }
      }
    },
    "setup": {
      "title": "Bienvenue dans l’application 12x12!",
      "lead": "Nous optimisons les contenus d'apprentissage spécifiquement pour le secteur de ton entreprise, afin que tes collaborateurs puissent résoudre les questions adaptées à leur domaine d'activité.",
      "industry": {
        "label": "Branche",
        "choose": "Sélectionner une branche",
        "placeholder": "Veuillez sélectionner"
      },
      "confirm": {
        "title": "Branche sélectionnée",
        "description": "Vous avez sélectionné la branche «{industry}». Veuillez vérifier à nouveau si la sélection est correcte et confirmez-la.<br><br>Vous ne pouvez pas réinitialiser la sélection.",
        "change": "Modifier la sélection"
      },
      "help": {
        "title": "Définir le secteur",
        "description": "Avant de commencer, indique le secteur dans lequel ton entreprise est active."
      }
    },
    "help": {
      "overview": {
        "title": "Centre d’aide 12x12",
        "description": "Vous trouverez ici des informations et de brèves vidéos concernant l’application pour préposés à la sécurité.",
        "startProductTour": "Lancer la présentation du produit",
        "introTitle": "Premières étapes",
        "introDescription": "Ici, vous pouvez lancer la présentation du produit afin de découvrir les premières étapes de votre application pour préposés à la sécurité.",
        "howItWorks": "Mode d’emploi"
      },
      "detail": {
        "back": "Précédent"
      }
    },
    "forbidden": {
      "noAccessToCompanies": "Vous n’avez pas accès aux entreprises dans l’application 12x12.",
      "noAccessToCompany": "Vous n’avez pas accès à cette entreprise.",
      "yourCompanies": "Vos entreprises"
    },
    "unauthorized": {
      "title": "Accès refusé",
      "text": "Vous n’avez pas accès à l’application 12x12.",
      "backLink": "Accéder mySuva"
    }
  }
}
