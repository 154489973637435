import CryptoJS from 'crypto-js';

export default () => {
  function encrypt(payload: string) {
    return CryptoJS.AES.encrypt(JSON.stringify(payload), '').toString();
  }
  function decrypt(payload: string) {
    const bytes = CryptoJS.AES.decrypt(payload, '');
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return {
    encrypt,
    decrypt,
  };
};
