
import { defineComponent } from 'vue';

import AppButton from '@/common/components/AppButton.vue';

export default defineComponent({
  name: 'fixed-game-button',
  components: { AppButton },
  props: {
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  setup(_props, { emit }) {
    return {
      onClick() {
        emit('click');
      },
    };
  },
});
