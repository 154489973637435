import { computed, ref, Ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

export const PHONE_NUMBER_FORMAT_EXAMPLE = '07x xxx xx xx';

export function isPhoneNumberValid(number: string | null) {
  if (!number) {
    return false;
  }

  return !!number.match(/^\+?(\d|\s)+$/g);
}

export default function usePhoneNumber(number: Ref<string>) {
  const { t } = useI18n();

  const isPhoneValid = computed(() => isPhoneNumberValid(number.value));
  const invalidPhoneErrorMessage = ref();

  watch(number, () => {
    const valid = (number.value?.length ?? 0) === 0 || isPhoneValid.value;
    invalidPhoneErrorMessage.value = valid ? null : t('form.phone.invalidFormat');
  });

  return {
    isPhoneValid,
    invalidPhoneErrorMessage,
  };
}
