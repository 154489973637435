import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cc14a88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-select", { '-locked': _ctx.disabled }]),
    ref: "target"
  }, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("button", {
      class: _normalizeClass(["target", { '-active': _ctx.open }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.disabled ? (_ctx.open = !_ctx.open) : ''))
    }, _toDisplayString(_ctx.currentOption ? _ctx.currentOption.name : _ctx.placeholder), 3),
    _createElementVNode("ul", {
      class: _normalizeClass(["list", { '-open': _ctx.open }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["option", { '-active': option.id === _ctx.currentValue }]),
          key: `option-${option.id}`
        }, [
          _createElementVNode("button", {
            class: "item",
            onClick: ($event: any) => (_ctx.select(option))
          }, _toDisplayString(option.name), 9, _hoisted_2)
        ], 2))
      }), 128))
    ], 2)
  ], 2))
}