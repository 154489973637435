<template>
  <div class="onboard-step">
    <h2 class="title">{{ title }}</h2>
    <div class="imagewrapper">
      <img class="image" :src="require(`@/assets/images/learn-app/onboarding/${img}.png`)" alt="" />
    </div>
    <p class="description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'OnboardStep',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.onboard-step {
  @include layout-container;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  > .title {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: center;
    align-items: flex-end;
    font-size: 24px;
    font-weight: 800;
    color: $palette-mango-700;
    line-height: 1.3;
    letter-spacing: 0.6px;
  }

  > .imagewrapper {
    position: relative;
    flex-shrink: 1;

    > .image {
      margin: 5vh 0 2.5vh;
      object-fit: contain;
      max-width: 25vh;
    }
  }

  > .description {
    text-align: center;
    flex-grow: 1;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    color: $palette-mango-700;
    line-height: 1.5;
    letter-spacing: 0.2px;
    margin-bottom: 14vh;
  }
}
</style>
