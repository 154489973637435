import axios from 'axios';
import { useSessionStorage } from '@vueuse/core';
import { computed, onBeforeMount, ref } from 'vue';
import datetime from '@/common/date/DatetimeService';
import { IndustryDto } from '@/sibe-app/types/Company';
import { useRouter } from 'vue-router';
import useAppData from '@/common/useAppData';
import { useI18n } from 'vue-i18n';
import { Locale } from '@/common/i18n/locale';

interface DemoModeInitialDataDto {
  industry: string;
  monthlyTasks: {
    date: string;
    isCurrentMonthlyTask: boolean;
    scheduled: string;
    icon: string;
    canUserSubmit: boolean;
    machineName: string;
    score: number;
    maxPoints: number;
    name: string;
    id: number;
    state: string;
    doneDate: string;

    // additional fields for demo
    packageId: string;
  }[];

  coWorkers: {
    id: number;
    name: string;
    avatar: string;
    score: number;
  }[];

  profile: {
    id: number;
    name: string;
    avatar: string;
    score: number;
  };
}

const client = axios.create({
  baseURL: '/',
  withCredentials: true,
});

const demoData = useSessionStorage<{ data: DemoModeInitialDataDto | null }>('demo.data', {
  data: null,
});

const currentMonthlyTask = computed(() => (demoData.value.data?.monthlyTasks ?? []).find((t) => t.isCurrentMonthlyTask));

// TODO: should be defined in airtable on the industries?
const suvaIndustryLandingPageUrls: Record<string, Record<Locale, string>> = {
  forestry: {
    de: 'https://www.suva.ch/de-ch/praevention/nach-branchen/forstunfaelle-vermeiden-bei-waldarbeiten',
    fr: 'https://www.suva.ch/fr-ch/prevention/par-branche/eviter-les-accidents-lors-de-travaux-forestiers?lang=fr-CH',
    it: 'https://www.suva.ch/it-ch/prevenzione/per-settori/evitare-infortuni-durante-le-attivita-forestali?lang=it-CH',
  },
  roadtransport: {
    de: 'https://www.suva.ch/de-ch/praevention/nach-branchen/sicherheitssysteme-transporte-strasse-eisenbahnen-seilbahnen-skilifte-luftfahrt/unfaelle-beim-strassentransport-vermeiden',
    fr: 'https://www.suva.ch/fr-ch/prevention/par-branche/systemes-de-securite-transports-route-chemins-de-fer-remontees-mecaniques-teleskis-transport-aerien/eviter-les-accidents-lors-des-transports-routiers?lang=fr-CH',
    it: 'https://www.suva.ch/it-ch/prevenzione/per-settori/sistemi-di-sicurezza-trasporti-strada-ferrovia-funivie-sciovie-spazio-aereo/evitare-infortuni-nel-trasposto-su-strada?lang=it-CH',
  },
};

async function init(industry: string) {
  const { data } = await client.get<DemoModeInitialDataDto>(`api/demo/industry/${industry}/init`);
  if (data.monthlyTasks) {
    demoData.value.data = data;
  }
}

export function demoDataInitialized() {
  return demoData.value.data;
}

const industries = ref<IndustryDto[]>();
const selectedIndustryOption = ref<string>();

export function useDemoModeStart() {
  const industryOptions = computed(() => industries.value?.map((industry) => ({ id: industry.id, name: industry.name })));

  const router = useRouter();

  async function startDemo(industry: string) {
    await init(industry);
    return router.replace({ name: 'start' });
  }

  onBeforeMount(() => {
    if ((industries.value ?? []).length === 0) {
      selectedIndustryOption.value = (router.currentRoute.value.query.industry as string) ?? undefined;

      client.get<IndustryDto[]>('api/company/industries').then((res) => {
        industries.value = res.data;

        if (!(industries.value ?? []).find((i) => i.id === selectedIndustryOption.value)) {
          selectedIndustryOption.value = undefined;
        }
      });
    }
  });

  return {
    startDemo,
    industryOptions,
    selectedIndustryOption,
  };
}

export function useDemoModeNavigation() {
  const router = useRouter();
  const { locale } = useI18n();
  const { baseUrl } = useAppData();

  function showInfos() {
    return router.push({ name: 'onboarding' });
  }

  async function playAgain() {
    await init(demoData.value.data?.industry ?? 'forestry');
    window.location.reload();
  }

  const shareLink = computed(() => `${baseUrl(locale.value as Locale)}/demo`);
  const activateLink = computed(
    () => suvaIndustryLandingPageUrls[demoData.value.data?.industry ?? '']?.[locale.value as Locale] ?? 'https://suva.ch',
  );

  return {
    showInfos,
    playAgain,
    shareLink,
    activateLink,
  };
}

export function demoDataForRequest(endpoint: string, payload: any): (() => Promise<any>) | null {
  // Company Data
  if (endpoint.endsWith('user/staff/company')) {
    return async () => ({ logo: '/assets/company/logo/logo-gridonic.png', name: 'Demo AG' });
  }

  // Monthly Tasks
  if (endpoint.endsWith('monthly-task/summary/for-staff')) {
    return async () => demoData.value.data?.monthlyTasks ?? [];
  }

  // Questions (Package) for monthly task for solving monthly task
  if (endpoint.match(/monthly-task\/(\d+)\/questions$/)) {
    return async () => {
      if (!currentMonthlyTask.value) {
        return null;
      }

      return (await client.get(`/api/demo/question-package/${currentMonthlyTask.value.packageId}/questions`)).data;
    };
  }

  // Submit question package
  if (endpoint.match(/monthly-task\/(.*)\/submit/)) {
    return async () => {
      const monthlyTaskId = Number(endpoint.match(/monthly-task\/(.*)\/submit/)?.[1]);

      // Nicht currentMonthlyTask verwenden, es muss ja zurückgeschrieben werden!
      const monthlyTask = (demoData.value.data?.monthlyTasks ?? []).find((t) => t.id === monthlyTaskId);

      if (!monthlyTask) {
        throw new Error('monthly task missing');
      }

      // monthlyTask.state = monthlyTask.state === 'current' ? 'solved-once' : 'completed';
      monthlyTask.state = 'completed'; // In demo Mode, directly complete (no second try needed)
      monthlyTask.score = payload.score;
      monthlyTask.canUserSubmit = monthlyTask.state !== 'completed';
      monthlyTask.doneDate = datetime.now().toIso();

      return {
        canUserSubmit: monthlyTask.canUserSubmit,
        userScore: monthlyTask.score,
        maxScore: monthlyTask.maxPoints,
        resultMessage: 'demo',
      };
    };
  }

  // Coworking employees
  if (endpoint.endsWith('user/co-workers')) {
    return async () => demoData.value.data?.coWorkers ?? [];
  }

  // Coworking employees
  if (endpoint.endsWith('user/profile')) {
    return async () => demoData.value.data?.profile ?? null;
  }

  return null;
}
