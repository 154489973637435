
import { defineComponent, PropType } from 'vue';

import AppButtonCard from '@/common/components/AppButtonCard.vue';

type PointerAlignment = 'top-left' | 'top-right' | 'left';

export default defineComponent({
  name: 'product-tour-card',
  components: { AppButtonCard },
  emits: ['next', 'cancel'],
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    pointerAlignment: {
      type: String as PropType<PointerAlignment>,
      default: 'top-left',
    },
  },
});
