import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cdf0398"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-notification" }
const _hoisted_2 = {
  key: 0,
  class: "circle"
}
const _hoisted_3 = { class: "count -font-size-10 -font-weight-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_icon_element, {
      icon: "clock",
      width: 24,
      height: 24
    }),
    (_ctx.count && _ctx.count > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.count), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}