
import { defineComponent } from 'vue';
import IconElement from '@/common/elements/IconElement.vue';

export default defineComponent({
  name: 'icon-notification',
  components: { IconElement },
  props: {
    count: {
      type: [Number, String],
      required: false,
    },
  },
});
