
import { defineComponent } from 'vue';
import AppButton from '@/common/components/AppButton.vue';
import BaseCard from '@/learn-app/components/common/BaseCard.vue';

export default defineComponent({
  name: 'modal-confirm-challenge',
  components: {
    AppButton,
    BaseCard,
  },
  props: {
    imageUrl: {
      type: String,
      required: true,
      default: 'https://source.unsplash.com/random',
    },
    name: {
      type: String,
      required: true,
      default: 'Max Muster',
    },
  },
  emits: ['abort', 'next'],
});
