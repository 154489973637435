import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45185cee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "challenger-result"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "title -font-size-21 -font-weight-900" }
const _hoisted_4 = { class: "text -font-size-18 -font-weight-700" }
const _hoisted_5 = { class: "score" }
const _hoisted_6 = { class: "added" }
const _hoisted_7 = { class: "text -font-size-18 -font-weight-700" }
const _hoisted_8 = { class: "actions" }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_activity_indicator = _resolveComponent("activity-indicator")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            class: "close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('abort'))),
            src: require(`@/assets/icons/close.svg`),
            alt: ""
          }, null, 8, _hoisted_2),
          _createVNode(_component_base_card, {
            class: "content challenger-result-card",
            imageUrl: _ctx.avatar
          }, {
            line1: _withCtx(() => [
              _createElementVNode("h5", _hoisted_3, [
                (_ctx.score > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('staff.challenge.view.result.wellDone')), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('staff.challenge.view.result.done')), 1)
                    ], 64))
              ]),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('staff.challenge.view.result.descriptionScore')), 1),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_icon_element, {
                  isImage: "",
                  class: "image",
                  icon: "helmet-bubbly",
                  width: "71",
                  height: "82"
                }),
                _createElementVNode("span", _hoisted_6, "+" + _toDisplayString(_ctx.score), 1)
              ]),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('staff.challenge.view.result.descriptionInfo', [_ctx.opponentName])), 1)
            ]),
            line2: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_app_button, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => {
                _ctx.$emit('abort');
                _ctx.$router.push({ name: 'challenges' });
              }),
                  class: "-primary",
                  label: _ctx.$t('staff.challenge.view.result.ctaBack')
                }, null, 8, ["label"])
              ])
            ]),
            _: 1
          }, 8, ["imageUrl"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_activity_indicator)
        ]))
  ]))
}