import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf96f45a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "challenge-final-result" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { ref: "titleRef" }
const _hoisted_4 = {
  key: 0,
  class: "title -font-size-21 -font-weight-900"
}
const _hoisted_5 = {
  key: 1,
  class: "title -font-size-21 -font-weight-900"
}
const _hoisted_6 = {
  key: 2,
  class: "title -font-size-21 -font-weight-900"
}
const _hoisted_7 = { class: "counterparty-container" }
const _hoisted_8 = { class: "contractor-container" }
const _hoisted_9 = { class: "avatar" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "name -font-size-14 -font-weight-900" }
const _hoisted_12 = { class: "score" }
const _hoisted_13 = { class: "number -font-size-18 -font-weight-700" }
const _hoisted_14 = { class: "contractor-container" }
const _hoisted_15 = { class: "avatar" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "name -font-size-14 -font-weight-900" }
const _hoisted_18 = { class: "score" }
const _hoisted_19 = { class: "number -font-size-18 -font-weight-700" }
const _hoisted_20 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('abort'))),
        src: require(`@/assets/icons/close.svg`),
        alt: ""
      }, null, 8, _hoisted_2),
      _createVNode(_component_base_card, {
        class: "content challenge-final-result-card",
        imgWidth: 91,
        imgHeight: 80,
        imageUrl: 
          _ctx.winner === _ctx.role || _ctx.winner === 'draw'
            ? require(`@/assets/icons/trophy-win.svg`)
            : require(`@/assets/icons/trophy-loose.svg`)
        
      }, {
        line1: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, null, 512),
          (_ctx.winner === _ctx.role)
            ? (_openBlock(), _createElementBlock("h5", _hoisted_4, _toDisplayString(_ctx.$t('staff.challenge.view.result.titleWon')), 1))
            : (_ctx.winner === 'draw')
              ? (_openBlock(), _createElementBlock("h5", _hoisted_5, _toDisplayString(_ctx.$t('staff.challenge.view.result.titleDraw')), 1))
              : (_openBlock(), _createElementBlock("h5", _hoisted_6, _toDisplayString(_ctx.$t('staff.challenge.view.result.titleLost')), 1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  class: "image",
                  src: _ctx.challengerAvatar,
                  alt: ""
                }, null, 8, _hoisted_10)
              ]),
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.challengerName), 1),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_icon_element, {
                  class: "icon",
                  icon: "helmet",
                  width: "21.64",
                  height: "14",
                  isImage: ""
                }),
                _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.challengerScore), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("img", {
                  class: "image",
                  src: _ctx.challengeeAvatar,
                  alt: ""
                }, null, 8, _hoisted_16)
              ]),
              _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.challengeeName), 1),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_icon_element, {
                  class: "icon",
                  icon: "helmet",
                  width: "21.64",
                  height: "14",
                  isImage: ""
                }),
                _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.challengeeScore), 1)
              ])
            ])
          ])
        ]),
        line2: _withCtx(() => [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_app_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => {
                _ctx.$emit('abort');
                _ctx.$router.push({ name: 'challenges' });
              }),
              class: "-primary",
              label: _ctx.$t('staff.challenge.view.result.ctaBack')
            }, null, 8, ["label"])
          ])
        ]),
        _: 1
      }, 8, ["imageUrl"])
    ])
  ]))
}