import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45de2e69"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "game-question-quiz" }
const _hoisted_2 = { class: "question-container" }
const _hoisted_3 = { class: "question" }
const _hoisted_4 = { class: "list" }
const _hoisted_5 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_game_header = _resolveComponent("game-header")!
  const _component_radio_button = _resolveComponent("radio-button")!
  const _component_fixed_game_button = _resolveComponent("fixed-game-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_game_header, {
      onExit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('exit'))),
      score: _ctx.score,
      answeredQuestions: _ctx.answeredQuestions,
      totalQuestions: _ctx.totalQuestions,
      animationProgress: _ctx.animationProgress
    }, null, 8, ["score", "answeredQuestions", "totalQuestions", "animationProgress"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.question), 1),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (d, i) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "option-item",
            key: i
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_radio_button, {
                class: "radiobutton",
                modelValue: _ctx.selectedOption,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedOption) = $event)),
                value: i,
                label: d
              }, null, 8, ["modelValue", "value", "label"])
            ])
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_fixed_game_button, {
      disabled: !_ctx.hasChanges,
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.verifyAnswer(_ctx.selectedOption))),
      class: _normalizeClass({ '-primary': _ctx.hasChanges, '-disabled': !_ctx.hasChanges }),
      label: _ctx.$t('staff.game.next')
    }, null, 8, ["disabled", "class", "label"])
  ]))
}