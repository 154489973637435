
import { defineComponent } from 'vue';

import IconElement from '@/common/elements/IconElement.vue';

export default defineComponent({
  name: 'game-button',
  components: {
    IconElement,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    },
    isUnsure: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  setup(_props, { emit }) {
    return {
      onClick() {
        emit('click');
      },
    };
  },
});
