
import { computed, defineComponent, inject, reactive, toRefs } from 'vue';

import AppButton from '@/common/components/AppButton.vue';
import CheckboxElement from '@/common/elements/form/CheckboxElement.vue';
import InputForm from '@/common/components/form/InputForm.vue';
import { additionalAppDataProviderKey } from '@/common/modules/appData';

interface LoginPayload {
  username: string;
  password: string;
  rememberMe: boolean;
}

export default defineComponent({
  name: 'Login',
  components: {
    AppButton,
    CheckboxElement,
    InputForm,
  },
  setup() {
    const additionalData = inject<any>(additionalAppDataProviderKey, {});

    const payload = reactive<LoginPayload>({
      username: additionalData.login?.username ?? '',
      password: additionalData.login?.password ?? '',
      rememberMe: additionalData.login?.rememberme ?? true,
    });

    return {
      error: computed(() => additionalData.login?.error ?? null),
      ...toRefs(payload),
    };
  },
});
