
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ActivityIndicator',
  props: {
    withSpacing: {
      type: Boolean,
      default: false,
    },
  },
});
