
import { defineComponent, reactive, ref } from 'vue';
import AppButton from '@/common/components/AppButton.vue';
import GameHeader from '@/learn-app/components/game/GameHeader.vue';
import useVerifyAnswer from '@/learn-app/common/useVerifyAnswer';

interface ImageCords {
  x: number;
  y: number;
}

export default defineComponent({
  name: 'game-question-identify-risks',
  components: {
    AppButton,
    GameHeader,
  },
  emits: ['exit'],
  props: {
    score: {
      type: Number,
      required: true,
      default: 2,
    },
    answeredQuestions: {
      type: Number,
      required: true,
      default: 3,
    },
    totalQuestions: {
      type: Number,
      required: true,
      default: 10,
    },
    animationProgress: {
      type: Boolean,
      required: true,
      default: false,
    },
    imagePath: {
      type: String,
      required: true,
      default: require('@/assets/images/learn-app/SpotDifference/spot1.png'),
    },
  },
  setup() {
    const hasChanges = ref(false);
    const threshold = 10;
    const selectedSpots = reactive([]) as ImageCords[];
    const imageRef = ref<HTMLImageElement>();
    const isOverlapping = (x1: number, y1: number, x2: number, y2: number) => {
      const a = x1 - x2;
      const b = y1 - y2;

      const c = Math.sqrt(a * a + b * b);

      return c < threshold;
    };

    const resetSelection = () => {
      selectedSpots.splice(0);
      hasChanges.value = false;
    };

    const onImageClicked = (e: any) => {
      const numberOfCirclesThatCanBePlaced = 1;

      const imgHeight = e.target.getBoundingClientRect().height ?? 0;
      const imgWidth = e.target.getBoundingClientRect().width ?? 0;

      hasChanges.value = true;
      const x = (100 / imgWidth) * e.offsetX;
      const y = (100 / imgHeight) * e.offsetY;

      let overlappingIdx = null;

      selectedSpots.forEach((spot, idx) => {
        if (isOverlapping(spot.x, spot.y, x, y)) {
          overlappingIdx = idx;
        }
      });

      if (overlappingIdx !== null) {
        selectedSpots.splice(overlappingIdx, 1);
      } else if (selectedSpots.length === numberOfCirclesThatCanBePlaced) {
        selectedSpots.splice(0, 1);
        selectedSpots.push({
          x,
          y,
        });
      } else {
        selectedSpots.push({
          x,
          y,
        });
      }

      if (selectedSpots.length === 0) {
        hasChanges.value = false;
      }
    };

    return {
      ...useVerifyAnswer(),
      hasChanges,
      onImageClicked,
      selectedSpots,
      resetSelection,
      imageRef,
    };
  },
});
