
import party from 'party-js';

import { defineComponent, onMounted, ref } from 'vue';
import AppButton from '@/common/components/AppButton.vue';
import BaseCard from '@/learn-app/components/common/BaseCard.vue';
import IconElement from '@/common/elements/IconElement.vue';

export default defineComponent({
  name: 'challenge-final-result',
  emits: ['abort', 'next'],
  components: {
    AppButton,
    BaseCard,
    IconElement,
  },
  props: {
    challengerScore: {
      type: Number,
      required: true,
      default: 4,
    },
    challengerName: {
      type: String,
      required: true,
      default: 'Simon Strassmann',
    },
    challengerAvatar: {
      type: String,
      required: false,
      default: 'https://source.unsplash.com/random',
    },
    challengeeScore: {
      type: Number,
      required: true,
      default: 3,
    },
    challengeeName: {
      type: String,
      required: true,
      default: 'Harmen Porter',
    },
    challengeeAvatar: {
      type: String,
      required: false,
      default: 'https://source.unsplash.com/random',
    },
    winner: {
      type: String,
      required: true,
      default: '',
    },
    role: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const titleRef = ref<HTMLElement | null>();
    const isSuccess = props.winner === props.role || props.winner === 'draw';

    onMounted(() => {
      setTimeout(() => {
        if (isSuccess && titleRef.value) {
          party.confetti(titleRef.value as HTMLElement, {
            count: party.variation.range(200, 400),
          });
        }
      }, 1000);
    });

    return {
      titleRef,
    };
  },
});
