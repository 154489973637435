import { FakeDateTimeOptions } from '@/common/date/FakeDatetimeMiddleware';
import DateTime from './DatetimeMiddleware';
import FakeDateTimeService from './FakeDatetimeService';

export class DatetimeService {
  public now(): DateTime {
    return DateTime.createNow();
  }

  public date(year: number, month: number, day: number): DateTime {
    return DateTime.createLocal(year, month, day);
  }

  public datetime(year: number, month: number, day: number, hour: number, minute: number, second: number, millisecond = 0) {
    return DateTime.createLocal(year, month, day, hour, minute, second, millisecond);
  }

  public fromTimestamp(timestamp: number): DateTime {
    return DateTime.createFromTimestamp(timestamp);
  }

  public fromIso(text: string): DateTime {
    return DateTime.createFromIso(text);
  }

  public fromNative(date: Date) {
    return DateTime.createFromNative(date);
  }

  public serialize(dateTime: DateTime) {
    return dateTime.toIso();
  }

  public deserialize(text: string): DateTime {
    return this.fromIso(text);
  }
}

let fakeDateTimeService: FakeDateTimeService | null = null;

const datetime = new Proxy(new DatetimeService(), {
  get(target, prop, receiver) {
    return Reflect.get(fakeDateTimeService ?? target, prop, receiver);
  },
});

export function enableWithFakeDateTimeService(options: FakeDateTimeOptions) {
  fakeDateTimeService = new FakeDateTimeService(options);
}

export default datetime;

export { DateTime };
