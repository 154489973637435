
import { defineComponent } from 'vue';

import AppButtonCard from '@/common/components/AppButtonCard.vue';
import IconElement from '@/common/elements/IconElement.vue';

export default defineComponent({
  name: 'tip-card',
  components: { AppButtonCard, IconElement },
  emits: ['close'],
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
  },
});
