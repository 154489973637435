
import { computed, defineComponent, inject, reactive, toRefs } from 'vue';
import InputForm from '@/common/components/form/InputForm.vue';
import AppButton from '@/common/components/AppButton.vue';
import CheckboxElement from '@/common/elements/form/CheckboxElement.vue';
import PasswordRating from '@/learn-login/views/PasswordRating.vue';
import { additionalAppDataProviderKey } from '@/common/modules/appData';

interface ChoosePasswordPayload {
  phone: string;
  password: string;
  passwordConfirmation: string;
  rememberMe: boolean;
}

interface TokenPayload {
  phone: string;
  isTokenValid: boolean;
}

export default defineComponent({
  name: 'ChoosePasswordView',
  components: {
    InputForm,
    AppButton,
    CheckboxElement,
    PasswordRating,
  },
  setup() {
    const tokenPayload = inject<any>(additionalAppDataProviderKey, {}) as TokenPayload;

    const payload = reactive<ChoosePasswordPayload>({
      phone: tokenPayload.phone,
      password: '',
      passwordConfirmation: '',
      rememberMe: true,
    });

    const canSubmit = computed(
      () => payload.password === payload.passwordConfirmation && payload.password !== '' && payload.passwordConfirmation !== '',
    );

    const showPasswordsNotMatching = computed(
      () => payload.password !== '' && payload.passwordConfirmation !== '' && payload.password !== payload.passwordConfirmation,
    );

    return {
      isTokenValid: tokenPayload.isTokenValid,
      canSubmit,
      showPasswordsNotMatching,
      ...toRefs(payload),
    };
  },
});
