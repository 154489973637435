import useApi from '@/common/modules/api';

export default function useChallengeRequests() {
  let isUpdatingNotfications = false;

  const createChallenge = (challengeeId: number) => {
    const { post, data, loading } = useApi('api/challenge/create', { encrypted: true });
    post({
      challengeeId,
    });
    return {
      data,
      loading,
    };
  };

  const startChallenge = (challengeId: string) => {
    const { post, data, loading } = useApi('api/challenge/start', { encrypted: true });
    post({
      challengeId,
    });
    return {
      loading,
      data,
    };
  };

  const declineChallenge = (challengeId: string) => {
    const { post, data, error } = useApi('api/challenge/decline');
    post({
      challengeId,
    });
    return {
      error,
      data,
    };
  };

  const getChallengeSummary = () => {
    const { get, data, loading } = useApi('api/challenge/summary/for-staff', { encrypted: true });

    return {
      loading,
      data,
      async loadChallenges() {
        return get();
      },
    };
  };

  const getNewChallenge = () => {
    const { get, data, loading } = useApi('api/challenge/get-new-challenge');
    get();
    return {
      loading,
      data,
    };
  };

  const getNewCompletedChallenge = () => {
    const { get, data, loading } = useApi('api/challenge/get-newly-completed-challenge');
    get();
    return {
      loading,
      data,
    };
  };

  const notificationViewed = (challengeId: string) => {
    const { post, data, error } = useApi('/api/challenge/notification-viewed');

    run();

    return {
      error,
      data,
    };

    async function run() {
      isUpdatingNotfications = true;
      try {
        await post({ challengeId });
      } finally {
        isUpdatingNotfications = false;
      }
    }
  };

  const submitChallengeResult = (challengeId: string, score: number) => {
    const { post, data, loading } = useApi('/api/challenge/submit');
    post({
      challengeId,
      score,
    });
    return {
      data,
      loading,
    };
  };

  const getNewChallengeNotifications = () => {
    const { get, data, error } = useApi('/api/challenge/get-new-challenge-notifications');

    run();

    return {
      data,
      error,
    };

    async function run() {
      if (isUpdatingNotfications) {
        // still updating notifications? we need to wait with fetching new notifications until update is completed.
        setTimeout(() => run(), 10);
        return;
      }
      get();
    }
  };

  return {
    createChallenge,
    startChallenge,
    declineChallenge,
    getChallengeSummary,
    getNewChallenge,
    getNewCompletedChallenge,
    submitChallengeResult,
    notificationViewed,
    getNewChallengeNotifications,
  };
}
