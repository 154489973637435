
import { defineComponent, inject, ref } from 'vue';

import AppButton from '@/common/components/AppButton.vue';
import InputForm from '@/common/components/form/InputForm.vue';
import { additionalAppDataProviderKey } from '@/common/modules/appData';
import usePhoneNumber from '@/common/modules/phone';

export default defineComponent({
  name: 'RequestPasswordReset',
  components: {
    AppButton,
    InputForm,
  },
  setup() {
    const linkSent = inject<any>(additionalAppDataProviderKey, {})?.linkSent ?? false;

    const phone = ref('');
    const { isPhoneValid, invalidPhoneErrorMessage } = usePhoneNumber(phone);

    return {
      linkSent,
      phone,
      isPhoneValid,
      invalidPhoneErrorMessage,
      retry() {
        window.location.reload();
      },
    };
  },
});
