<template>
  <div class="onboard-stepper">
    <div
      class="circle"
      v-for="(circle, index) in numberOfSteps"
      :class="{ '-is-active': index === activeStep }"
      :key="`circle-${index}`"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'OnboardStepper',
  props: {
    numberOfSteps: {
      type: Number,
      default: 0,
      required: true,
    },
    activeStep: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.onboard-stepper {
  display: flex;
  justify-content: center;
  margin: 0 -12px;

  > .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $palette-white;
    margin: 0 6px;

    &.-is-active {
      width: 20px;
      height: 6px;
      border-radius: 6px;
      background-color: $palette-primary-500;
    }
  }
}
</style>
