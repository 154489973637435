
import { defineComponent, PropType } from 'vue';

type Direction = 'left' | 'right';
export type Shape = 'default' | 'inverse' | 'full';

export default defineComponent({
  name: 'ArrowButton',
  props: {
    direction: {
      type: String as PropType<Direction>,
      default: 'left',
    },
    shape: {
      type: String as PropType<Shape>,
      default: 'default',
    },
  },

  emits: ['click'],

  setup(_props, { emit }) {
    return {
      onClick() {
        emit('click');
      },
    };
  },
});
