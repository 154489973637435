import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["app-button-card", { '-secondary': _ctx.secondary }]),
    type: "button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["label copy -font-size-14 -font-weight-700", _ctx.secondary ? '-font-size-12' : '-font-size-14'])
    }, _toDisplayString(_ctx.label), 3)
  ], 2))
}