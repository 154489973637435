// import useApi from '@/common/modules/api';
import { useGameService } from '@/learn-app/common/stateMachines/useGameStateMachine';
import { GameContext } from '@/learn-app/types/GameContext';
import { QuestionResult } from '@/learn-app/types/QuestionResult';
import { QuestionTypes } from '@/learn-app/types/QuestionTypes';
import { useI18n } from 'vue-i18n';

export default function useVerifyAnswer() {
  const { t } = useI18n();
  const { state, send } = useGameService();

  function verifyAnswer(payload: any) {
    let givenAnswer = payload;
    const ctx: GameContext = state.value.context;
    const solution = ctx.currentQuestion?.solution;
    const type = ctx?.currentQuestion?.type;

    let result = QuestionResult.FAIL;
    let correctAnswer = '';
    let score = 0;

    if (type === QuestionTypes.Quiz) {
      result = payload === solution ? QuestionResult.PASS : QuestionResult.FAIL;
      if (ctx?.currentQuestion?.options) {
        correctAnswer = ctx?.currentQuestion?.options[solution];
      }
      score = payload === solution ? 1 : 0;
    }

    if (type === QuestionTypes.SafeOrNot) {
      result = payload === solution ? QuestionResult.PASS : QuestionResult.FAIL;
      correctAnswer = solution ? t('staff.game.secure') : t('staff.game.unsecure');
      score = payload === solution ? 1 : 0;
    }

    if (type === QuestionTypes.IdentifyRisks) {
      const [{ x, y }]: Record<string, number>[] = payload;

      interface block {
        x0: number;
        x1: number;
        y0: number;
        y1: number;
      }

      // const solutionCells = [15, 20, 21, 22, 23, 27, 29, 46, 47];
      const blocks: Record<string, block> = {};
      const columns = 6;
      const rows = 8;
      new Array(48).fill(null).forEach((_, i) => {
        const column = i % columns;
        const row = parseInt(`${i / columns}`, 10);
        const cellWidth = (1 / columns) * 100;
        const cellHeight = (1 / rows) * 100;
        blocks[i + 1] = {
          x0: column * cellWidth,
          x1: column * cellWidth + cellWidth,
          y0: row * cellHeight,
          y1: row * cellHeight + cellHeight,
        };
      });

      const match = solution.some((cell: number) => {
        const { x0, x1, y0, y1 } = blocks[cell];
        let matchX = false;
        let matchY = false;

        if (x >= x0 && x <= x1) {
          matchX = true;
        }
        if (y >= y0 && y <= y1) {
          matchY = true;
        }

        return matchX && matchY;
      });

      result = match ? QuestionResult.PASS : QuestionResult.FAIL;
      score = match ? 1 : 0;

      givenAnswer = {
        choosenSpots: payload,
        solutionSpots: solution,
      };
    }

    ctx.score += score;
    ctx.result = {
      result,
      correctAnswer,
      justification: ctx.currentQuestion?.explanation ? ctx.currentQuestion?.explanation : '',
      givenAnswer,
    };

    send('NEXT');
  }

  return {
    verifyAnswer,
  };
}
