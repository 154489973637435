
import { defineComponent, PropType } from 'vue';
import { Worker } from '@/learn-app/types/Worker';

import EmployeeCard from '@/learn-app/components/challenge/EmployeeCard.vue';

export default defineComponent({
  name: 'employee-list',
  components: {
    EmployeeCard,
  },
  emits: ['cardClick'],
  props: {
    cardItems: {
      type: Array as PropType<Worker[]>,
      required: true,
      default: () => [
        {
          id: 1,
          imageUrl: 'https://source.unsplash.com/random',
          name: 'Ahmad Nazeri',
          score: 245,
        },
        {
          id: 2,
          imageUrl: 'https://source.unsplash.com/random',
          name: 'Harmen Porter',
          score: 149,
        },
        {
          id: 3,
          imageUrl: 'https://source.unsplash.com/random',
          name: 'Martin Buchmüller',
          score: 184,
        },
        {
          id: 4,
          imageUrl: 'https://source.unsplash.com/random',
          name: 'Jafaris Bezmalovic',
          score: 134,
        },
        {
          id: 5,
          imageUrl: 'https://source.unsplash.com/random',
          name: 'Maurus Hugentobler',
          score: 93,
        },
      ],
    },
  },
});
