
import { defineComponent, ref, onMounted, Ref } from 'vue';

export default defineComponent({
  name: 'slot-machine',
  emits: ['abort', 'next'],
  props: {
    iconType1: {
      type: String,
      required: false,
      default: 'quiz',
    },
    iconType2: {
      type: String,
      required: false,
      default: 'safe-or-not',
    },
    iconTopic1: {
      type: String,
      required: false,
      default: '',
    },
    iconTopic2: {
      type: String,
      required: false,
      default: '',
    },
    iconTopicList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const questionTypes = {
      'identify-risks': '/assets/question-types/identify-risks.svg',
      quiz: 'assets/question-types/quiz.svg',
      'safe-or-not': 'assets/question-types/safe-or-not.svg',
    };

    const reel1 = ref<HTMLDivElement>();
    const reel2 = ref<HTMLDivElement>();
    const reel3 = ref<HTMLDivElement>();
    const reel4 = ref<HTMLDivElement>();

    const numberOfSymbols = 40;

    const slotHeight = 100 / numberOfSymbols;

    function getRandomQuestionTypeIndex(type: string) {
      const keys = Object.keys(questionTypes);
      const multiplicator = getRandomValue(4, 12);
      return keys.indexOf(type) + 3 * multiplicator;
    }

    function getRandomQuestionPackageIndex(question: string) {
      const multiplicator = getRandomValue(2, 4);
      return (props.iconTopicList.indexOf(question) || 0) + 7 * multiplicator;
    }

    const randomFirstQuestionType = getRandomQuestionTypeIndex(props.iconType1);
    const randomFirstQuestionPackage = getRandomQuestionPackageIndex(props.iconTopic1);

    const randomSecondQuestionType = getRandomQuestionTypeIndex(props.iconType2);
    const randomSecondQuestionPackage = getRandomQuestionPackageIndex(props.iconTopic2);

    function spin(reel: Ref<HTMLDivElement | undefined>, number: number) {
      if (!reel.value) return;
      // eslint-disable-next-line no-param-reassign
      reel.value.style.cssText = `transform: translateY(-${number * slotHeight - 2.5}%); transition: ${
        100 * number
      }ms cubic-bezier(0, 0.55, 0.45, 1)`;
    }

    function getQuestionTypeIcon(i: number) {
      const imageUrl = Object.values(questionTypes)[i % 3];
      return `background-image: url(${imageUrl});`;
    }

    function getQuestionPackageIcon(i: number) {
      const icons = props.iconTopicList;
      const imageUrl = icons[i % icons.length];
      return `background-image: url(${imageUrl});`;
    }

    function getRandomValue(min: number, max: number) {
      return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);
    }

    onMounted(() => {
      setTimeout(() => {
        spin(reel1, randomFirstQuestionType);
        spin(reel2, randomFirstQuestionPackage);
        spin(reel3, randomSecondQuestionType);
        spin(reel4, randomSecondQuestionPackage);
      }, 500);
    });

    return {
      reel1,
      reel2,
      reel3,
      reel4,
      getQuestionTypeIcon,
      getQuestionPackageIcon,
    };
  },
});
