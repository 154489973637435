
import { defineComponent } from 'vue';

import MonthlyTaskPlannerStatus from '@/sibe-app/components/planning/MonthlyTaskPlannerStatus.vue';
import MonthlyProgressBar from '@/sibe-app/components/planning/MonthlyProgressBar.vue';
import IconElement from '@/common/elements/IconElement.vue';
import AppButton from '@/common/components/AppButton.vue';

export default defineComponent({
  name: 'MonthlyTaskCard',
  components: {
    MonthlyTaskPlannerStatus,
    MonthlyProgressBar,
    IconElement,
    AppButton,
  },
  emits: ['showinfo'],
  props: {
    status: {
      type: String,
      default: 'unplanned',
    },
    date: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    statusText: {
      type: String,
      required: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    info: {
      type: String,
      required: false,
    },
    completedDetails: {
      type: String,
      required: false,
    },
    actionLabel: {
      type: String,
      default: 'test',
    },
    clickEvent: {
      type: String,
      required: false,
    },
    hideInfo: {
      type: Boolean,
      required: false,
    },
  },
});
