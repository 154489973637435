<template>
  <div class="chart-line-area" ref="root">
    <ul class="chart-legend">
      <li class="set">
        <img class="icon" :src="require(`@/assets/icons/bullet-green.svg`)" alt="" />
        <span class="name copy -font-size-12 -font-weight-700">{{ $t('manager.dashboard.view.lineChart.myCompany') }}</span>
      </li>
      <li class="set">
        <img class="icon" :src="require(`@/assets/icons/bullet-grey.svg`)" alt="" />
        <span class="name copy -font-size-12 -font-weight-700">{{ $t('manager.dashboard.view.lineChart.averageIndustry') }}</span>
      </li>
    </ul>
    <svg ref="chartRef" class="chart" :width="width" :height="height" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient-green" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="var(--palette-emerald-500)" stop-opacity="1" />
          <stop offset="100%" stop-color="var(--palette-emerald-600)" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip">
          <rect :height="plotHeight + 20" :width="plotWidth + 20" :transform="`translate(-10, -10)`"></rect>
        </clipPath>
      </defs>
      <rect width="100%" height="100%" stroke="black" stroke-width="0" fill="none" />
      <g class="chart">
        <g class="axis-x copy -font-size-12" :transform="`translate(${margin.left}, ${height - margin.bottom + 20})`" />
        <g class="axis-y copy -font-size-12" :transform="`translate(${margin.left - 10}, ${margin.top})`" />

        <g class="plot" :transform="`translate(${margin.left}, ${margin.top})`" clip-path="url(#clip)">
          <path
            v-for="(data, index) in groupedAreaData"
            :key="`area-area-${data.date?.getTime() ?? index} `"
            :data-index="index"
            class="area-area"
            fill="url(#gradient-green)"
            :d="areaGenerator(data)"
          ></path>

          <path
            v-for="(data, index) in groupedAreaData"
            :key="`area-line-${data.date?.getTime() ?? index} `"
            :data-index="index"
            class="area-line"
            fill="none"
            stroke="var(--palette-emerald-700)"
            stroke-width="1"
            :d="lineGenerator(data)"
          ></path>

          <path
            v-for="(data, index) in groupedLineData"
            :key="`industry-line-${data.date?.getTime() ?? index} `"
            :data-index="index"
            class="industry-line"
            fill="none"
            stroke="var(--palette-neutral-400)"
            stroke-width="1"
            :d="lineGenerator(data)"
          ></path>

          <g
            v-for="(item, index) in areaData"
            :key="`active-datapoint-${index}`"
            class="chart-dataset"
            :class="getActiveMonthIndex === index ? '-selected' : ''"
          >
            <foreignObject :x="xScale(item.date) + 10" :y="plotHeight / 2 - 35" width="160" height="160" class="infobox">
              <div xmlns="http://www.w3.org/1999/xhtml" class="active-datapoint-info">
                <h4 class="title copy -font-size-12 -font-weight-700">
                  {{ translateMonth(item.date) }}
                </h4>
                <div class="values">
                  <div class="own">
                    <h4 class="name copy -font-size-10 -font-weight-700">
                      {{ $t('manager.dashboard.view.lineChart.myCompany') }}
                    </h4>
                    <p class="value copy -font-size-14 -font-weight-700">
                      {{ percentageValueLabel(item.value) }}
                    </p>
                  </div>
                  <div class="industry">
                    <h4 class="name copy -font-size-10 -font-weight-700">Branche</h4>
                    <p class="value copy -font-size-14 -font-weight-700">
                      {{ percentageValueLabel(lineData[index].value) }}
                    </p>
                  </div>
                </div>
              </div>
            </foreignObject>

            <line
              class="line"
              :x1="xScale(item.date)"
              :x2="xScale(item.date)"
              :y1="getMaxValue([item.value ?? 0, lineData[index].value] ?? 0)"
              :y2="plotHeight"
              stroke="var(--palette-primary-500)"
              stroke-width="3"
            />
            <rect :x="xScale(item.date) - 10" y="0" width="20" :height="height" fill="none" />

            <circle
              class="circle"
              :cx="xScale(item.date)"
              :cy="yScale(item.value ?? 0)"
              fill="var(--palette-emerald-500)"
              stroke="var(--palette-emerald-600)"
              stroke-width="1"
              r="5"
            />
            <circle class="circle" :cx="xScale(item.date)" :cy="yScale(item.value ?? 0)" fill="var(--palette-white)" r="2" />

            <circle
              class="circle"
              :cx="xScale(lineData[index].date)"
              :cy="yScale(lineData[index].value ?? 0)"
              fill="var(--palette-neutral-400)"
              stroke="var(--palette-neutral-500)"
              stroke-width="1"
              r="5"
            />
            <circle
              class="circle"
              :cx="xScale(lineData[index].date)"
              :cy="yScale(lineData[index].value ?? 0)"
              fill="var(--palette-white)"
              r="2"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3';
import { nextTick } from 'vue';

function groupedData(data) {
  let currentGroup = null;
  const groups = [];

  for (let i = 0; i < data.length; i += 1) {
    const current = data[i];
    if (current.value !== null) {
      if (currentGroup === null) {
        currentGroup = [];
        groups.push(currentGroup);
      }
      currentGroup.push(current);
    } else {
      currentGroup = null;
    }
  }

  return groups.map((group) => {
    if (group.length === 1 && group[0].value !== null) {
      const lonelyItem = group[0];
      const visibleTimeRangeHalf = 1000 * 60 * 60 * 24;
      return [
        { ...lonelyItem, date: new Date(lonelyItem.date.getTime() - visibleTimeRangeHalf) },
        { ...lonelyItem, date: new Date(lonelyItem.date.getTime() + visibleTimeRangeHalf) },
      ];
    }

    return group;
  });
}

export default {
  name: 'ChartLineArea',
  props: {
    lineData: {
      type: Array,
      required: true,
    },
    areaData: {
      type: Array,
      required: true,
    },
    activeDate: {
      type: Date,
      default: new Date(),
    },
  },
  emits: ['month-click'],
  data() {
    return {
      width: 600,
      height: 300,
      plotWidth: 600,
      plotHeight: 300,
      margin: {
        top: 30,
        right: 20,
        bottom: 50,
        left: 50,
      },
      activeDataSet: null,
      processing: false,
      transitionPathTimeout: null,
      processingTimeout: null,
      startupTransitionDone: false,
      availableXSpace: null,
      availableYSpace: null,
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    nextTick(() => {
      this.startupTransition();
      this.transitionPathTimeout = setTimeout(() => {
        this.$refs.chartRef.classList.add('-transiton-path');
      }, 1000);
    });
  },
  beforeUnmount() {
    if (this.transitionPathTimeout) {
      clearTimeout(this.transitionPathTimeout);
    }

    if (this.processingTimeout) {
      clearTimeout(this.processingTimeout);
    }
  },
  watch: {
    activeDate() {
      this.updateAxis();
    },
  },
  methods: {
    onResize() {
      if (this.$refs.root) {
        this.width = 0;
        this.height = 0;
        nextTick(() => {
          this.availableXSpace = parseInt(window.getComputedStyle(this.$refs.root).width, 10);
          this.width = this.availableXSpace;
          this.availableYSpace = parseInt(window.getComputedStyle(this.$refs.root).height, 10);
          this.height = this.availableYSpace;
          this.plotWidth = this.width - this.margin.right - this.margin.left;
          this.plotHeight = this.height - this.margin.top - this.margin.bottom;
          this.updateAxis();
        });
      }
    },
    getMaxValue(dataSet) {
      const maxValue = d3.max(dataSet, (d) => d);
      return this.yScale(maxValue ?? 0);
    },
    // prettier-ignore
    translateMonth(value) {
      const { lang } = document.documentElement;
      let langCode = 'de-CH';
      if (lang) {
        langCode = lang;
      }
      return new Intl
        .DateTimeFormat(langCode, { month: 'long' })
        .format(value);
    },
    pathTween(data, generator) {
      return () => (t) => {
        const interpolatedData = data.map((item) => {
          const interpolation = d3.interpolate(0, item.value ?? 0);
          return {
            date: item.date,
            value: interpolation(t),
          };
        });
        return generator(interpolatedData);
      };
    },
    // prettier-ignore
    updateAxis() {
      this.processing = true;
      const svg = d3.select(this.$refs.chartRef);
      svg.select('.axis-x')
        .call(this.xAxis)
        .call((g) => g.selectAll('.tick text')
          .attr('y', 0)
          .attr('dy', 10)
          .attr('class', 'clickable-month label copy -font-size-14 -font-weight-700')
          .attr('fill', 'var(--palette-primary-500)')
          .text((d) => this.translateMonth(d))
          .on('click', (evt, d) => this.$emit('month-click', d)))
        .call((g) => g.selectAll('.tick')
          .insert('text')
          .attr('class', 'label copy -font-size-12')
          .attr('fill', 'currentColor')
          .text((d) => d.getFullYear())
          .attr('dy', 25))
        .call((g) => g.select('.domain').remove());

      svg
        .select('.axis-y')
        .call(this.yAxis)
        .call((g) => g
          .selectAll('.tick line')
          .attr('stroke-opacity', 0.5)
          .attr('stroke-width', 1)
          .attr('stroke-dasharray', '2 10')
          .attr('stroke-linecap', 'round')
          .attr('x1', '-10')
          .attr('x2', this.plotWidth + 40))
        .call((g) => g.selectAll('.tick text').attr('x', -40))
        .call((g) => g.select('.domain').remove());
    },
    startupTransition() {
      const svg = d3.select(this.$refs.chartRef);

      this.groupedLineData.forEach((data, index) => {
        svg
          .select(`.industry-line[data-index="${index}"]`)
          .transition()
          .duration(1000)
          .attrTween('d', this.pathTween(data, this.lineGenerator));
      });

      this.groupedAreaData.forEach((data, index) => {
        svg
          .select(`.area-line[data-index="${index}"]`)
          .transition()
          .duration(1000)
          .attrTween('d', this.pathTween(data, this.lineGenerator));

        svg
          .select(`.area-area[data-index="${index}"]`)
          .transition()
          .duration(1000)
          .attrTween('d', this.pathTween(data, this.areaGenerator));
      });
    },
    percentageValueLabel(value) {
      if (value === null) {
        return '-';
      }

      return `${value}%`;
    },
  },
  computed: {
    minXDate() {
      const minXDate = new Date(this.activeDate);
      return new Date(minXDate.setMonth(minXDate.getMonth() - 2));
    },
    maxXDate() {
      const minXDate = new Date(this.activeDate);
      return new Date(minXDate.setMonth(minXDate.getMonth() + 2));
    },
    getActiveMonthIndex() {
      if (this.processing) {
        return null;
      }
      return this.lineData.findIndex((item) => item.date.getTime() === this.activeDate.getTime());
    },
    xScale() {
      return d3.scaleTime().domain([this.minXDate, this.maxXDate]).range([0, this.plotWidth]);
    },
    yScale() {
      return d3.scaleLinear().domain([0, 101]).range([this.plotHeight, 0]);
    },
    lineGenerator() {
      return d3
        .line()
        .x((d) => this.xScale(d.date))
        .y((d) => this.yScale(d.value ?? 0))
        .curve(d3.curveMonotoneX);
    },
    areaGenerator() {
      return d3
        .area()
        .y0(this.plotHeight)
        .y1((d) => this.yScale(d.value ?? 0))
        .x((d) => this.xScale(d.date))
        .curve(d3.curveMonotoneX);
    },
    area() {
      return this.areaGenerator(this.areaData);
    },
    xAxis() {
      return d3.axisBottom(this.xScale).ticks(5).tickSize(0).tickFormat(d3.timeFormat('%B')).tickSizeOuter(0);
    },
    yAxis() {
      return d3.axisRight(this.yScale).ticks(5).tickSize(this.plotWidth).tickSizeOuter(0);
    },
    groupedLineData() {
      return groupedData(this.lineData);
    },
    groupedAreaData() {
      return groupedData(this.areaData);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-line-area {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  min-height: 300px;

  > .chart {
    &.-transiton-path {
      path {
        transition: all 1000ms ease-in-out;
      }
    }
  }
}

.chart-legend {
  display: flex;
  padding: 0;
  margin-left: 1rem;

  > .set {
    display: flex;
    list-style: none;

    &:not(:last-child) {
      margin-right: 3rem;
    }

    > .icon {
      margin-right: 0.5rem;
    }
  }
}

.chart-dataset {
  pointer-events: bounding-box;
  opacity: 0;

  &.-selected {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  > .infobox {
    overflow: visible;
  }
}

.axis-x,
.axis-y {
  font-family: $base-font-family;
  color: $palette-neutral-400;
}

.active-datapoint-info {
  @include box;

  padding: 0.5rem;

  > .title {
    text-align: center;
  }

  > .values {
    display: grid;
    gap: 2px;
    grid-template-columns: 1fr 1fr;
    background-color: var(--palette-neutral-200);

    > .own,
    > .industry {
      background-color: var(--palette-white);

      > .name,
      > .value {
        text-align: center;
      }
    }

    > .own {
      > .name {
        color: var(--palette-emerald-500);
      }
    }
  }
}

::v-deep(.clickable-month) {
  cursor: pointer;
}
</style>
