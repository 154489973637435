
import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'game-progress',
  props: {
    current: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    animationProgress: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const calculated = ref(props.animationProgress ? props.current - 1 : props.current);
    const progress = computed(() => (calculated.value / props.max) * 100);
    onMounted(() => {
      if (props.animationProgress) {
        setTimeout(() => (calculated.value = props.current), 250);
      }
    });
    return {
      progress,
    };
  },
});
