
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import datetime from '@/common/date/DatetimeService';
import { QuestionState } from '@/learn-app/types/QuestionState';
import IconElement from '@/common/elements/IconElement.vue';

export default defineComponent({
  name: 'questionpack-state',
  components: { IconElement },
  props: {
    state: {
      type: String as PropType<QuestionState>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    doneDate: {
      type: String,
      required: true,
      default: datetime.now().toFormat('LLL YYYY dd'),
    },
    scheduled: {
      type: String,
      required: true,
      default: datetime.now().toFormat('LLL YYYY dd'),
    },
    canUserSubmit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const questionpackState = () => {
      if (props.state === QuestionState.Current) {
        return {
          title: t('staff.dashboard.taskCard.currentMonthlyTask'),
          text: t('staff.dashboard.taskCard.taskNotEdited'),
        };
      }
      if (props.state === QuestionState.Completed) {
        return {
          title: t('staff.dashboard.taskCard.done'),
          text: `${t('staff.dashboard.taskCard.at')} ${props.doneDate}`,
        };
      }
      if (props.state === QuestionState.SolvedOnce) {
        return {
          title: t('staff.dashboard.taskCard.done'),
          text: `${t('staff.dashboard.taskCard.at')} ${props.doneDate}`,
        };
      }
      if (props.state === QuestionState.NotCompleted) {
        return {
          title: t('staff.dashboard.taskCard.notDone'),
          text: `${t('staff.dashboard.taskCard.taskNotEdited')}`,
        };
      }
      return {
        title: t('staff.dashboard.taskCard.notYetReleased'),
        text: `${t('staff.dashboard.taskCard.releasingDate')}, ${props.scheduled}.`,
      };
    };
    return {
      QuestionState,
      ...questionpackState(),
    };
  },
});
