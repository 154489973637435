import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cc6327d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "game-intro" }
const _hoisted_2 = { class: "main-content" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "-font-size-16 -font-weight-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_game_header = _resolveComponent("game-header")!
  const _component_fixed_game_button = _resolveComponent("fixed-game-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_game_header, {
      onExit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('exit'))),
      key: _ctx.answeredQuestions,
      score: _ctx.score,
      answeredQuestions: _ctx.answeredQuestions,
      totalQuestions: _ctx.totalQuestions,
      animationProgress: _ctx.animationProgress
    }, null, 8, ["score", "answeredQuestions", "totalQuestions", "animationProgress"])),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "image",
        src: _ctx.getIconSource,
        alt: ""
      }, null, 8, _hoisted_3),
      _createElementVNode("h4", null, _toDisplayString(_ctx.getName), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getInstructions), 1)
    ]),
    _createVNode(_component_fixed_game_button, {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('next'))),
      label: _ctx.$t('staff.game.goOnToFirstQuestion')
    }, null, 8, ["label"])
  ]))
}