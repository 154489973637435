import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06312bb1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["game-button", { '-is-unsure': _ctx.isUnsure }]),
    type: _ctx.type,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_icon_element, {
      class: "icon",
      icon: _ctx.isUnsure ? 'result-not-sure-bubble' : 'sure-bubble',
      width: 24,
      height: 24,
      isImage: ""
    }, null, 8, ["icon"]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["label copy -font-size-14 -font-weight-700", { '-is-unsure': _ctx.isUnsure }])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}