
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'game-score',
  props: {
    score: {
      type: Number,
      required: true,
    },
  },
});
