import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b5157644"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "game-question-safe-or-not" }
const _hoisted_2 = ["src", "srcset", "alt"]
const _hoisted_3 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_game_header = _resolveComponent("game-header")!
  const _component_game_button = _resolveComponent("game-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_game_header, {
      onExit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('exit'))),
      key: _ctx.answeredQuestions,
      score: _ctx.score,
      answeredQuestions: _ctx.answeredQuestions,
      totalQuestions: _ctx.totalQuestions,
      animationProgress: _ctx.animationProgress
    }, null, 8, ["score", "answeredQuestions", "totalQuestions", "animationProgress"])),
    _createElementVNode("img", {
      class: "image",
      src: `${_ctx.imagePath}.jpeg`,
      srcset: `${_ctx.imagePath}_750.webp 750w,
                        ${_ctx.imagePath}_1500.webp 1500w,
                        ${_ctx.imagePath}_2250.webp 2250w
              `,
      alt: `${_ctx.imagePath}`
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_game_button, {
        class: "next -primary",
        isUnsure: "",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.verifyAnswer(false))),
        label: _ctx.$t('staff.game.unsecure')
      }, null, 8, ["label"]),
      _createVNode(_component_game_button, {
        class: "next -primary",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.verifyAnswer(true))),
        label: _ctx.$t('staff.game.secure')
      }, null, 8, ["label"])
    ])
  ]))
}