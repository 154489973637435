import { computed, inject } from 'vue';
import { additionalAppDataProviderKey } from '@/common/modules/appData';
import { Locale } from '@/common/i18n/locale';

export default function useAppData() {
  const additionalData = inject<any>(additionalAppDataProviderKey, {});

  function suvaCustomerPortalUrl(locale: Locale) {
    const urls = additionalData?.suvaCustomerPortalUrl ?? {};

    return urls[locale] ?? '';
  }

  function baseUrl(locale: Locale) {
    const baseUrls = additionalData?.baseUrls ?? {};

    return baseUrls[locale] ?? '';
  }

  return {
    appInstance: computed<'local' | 'stage' | 'production'>(() => additionalData?.appInstance),
    baseUrl,
    suvaCustomerPortalUrl,
  };
}
