import { computed, inject } from 'vue';
import { additionalAppDataProviderKey } from '@/common/modules/appData';
import { defaultLocale, Locale } from '@/common/i18n/locale';
import useAppData from '@/common/useAppData';

export default function useI18n() {
  const additionalData = inject<any>(additionalAppDataProviderKey, {});

  const locale: Locale = additionalData?.locale ?? defaultLocale;
  const locales: Locale[] = additionalData?.locales ?? [];

  const { baseUrl } = useAppData();

  return {
    locales: computed(() => locales),
    locale: computed(() => locale),
    otherLocales: computed(() => locales.filter((l) => l !== locale)),
    baseUrl,
  };
}
