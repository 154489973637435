interface ModeOptions {
  mode: 'demo' | 'default';
  baseUrl: string;
}

function determineMode(): ModeOptions {
  if (window.location.pathname.match(/^\/demo($|\/)/)) {
    return {
      mode: 'demo',
      baseUrl: '/demo',
    };
  }

  return {
    mode: 'default',
    baseUrl: '/',
  };
}

export const mode: ModeOptions = determineMode();
export const DEMO_MODE_ENABLED = mode.mode === 'demo';

console.debug('app mode', mode);
