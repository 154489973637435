
import { computed, defineComponent } from 'vue';
import { mapQuestionTypeToText } from '@/common/utils/mapToText';
import AppButton from '@/common/components/AppButton.vue';
import BaseCard from '@/learn-app/components/common/BaseCard.vue';

export default defineComponent({
  name: 'challenge-summary-view',
  emits: ['abort', 'next'],
  components: {
    AppButton,
    BaseCard,
  },
  props: {
    imageUrl: {
      type: String,
      required: false,
      default: 'https://source.unsplash.com/random',
    },
    topic1: {
      type: String,
      required: false,
      default: 'psa',
    },
    topic2: {
      type: String,
      required: false,
      default: 'work_in_isolation',
    },
    type1: {
      type: String,
      required: false,
      default: 'quiz',
    },
    type2: {
      type: String,
      required: false,
      default: 'safe-or-not',
    },
    icon1: {
      type: String,
      required: false,
      default: '/assets/question-packages/notfound.svg',
    },
    icon2: {
      type: String,
      required: false,
      default: '/assets/question-packages/notfound.svg',
    },
    name1: {
      type: String,
      required: false,
      default: '',
    },
    name2: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const typeSource = computed(() => (type: string) => `/assets/question-types/${type}.svg`);
    const mapQuestionType = computed(() => (type: string) => mapQuestionTypeToText(type));

    return {
      typeSource,
      mapQuestionType,
    };
  },
});
