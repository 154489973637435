import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5053f889"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label copy -font-size-12 -font-weight-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["monthly-task-planner-status", _ctx.setStatusClass])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1)
  ], 2))
}