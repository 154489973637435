
import { computed, defineComponent, PropType } from 'vue';
import { QuestionTypes } from '@/learn-app/types/QuestionTypes';
import { useI18n } from 'vue-i18n';
import GameHeader from '@/learn-app/components/game/GameHeader.vue';
import FixedGameButton from '@/learn-app/components/game/FixedGameButton.vue';

export default defineComponent({
  name: 'game-intro',
  components: {
    FixedGameButton,
    GameHeader,
  },
  emits: ['exit', 'next'],
  props: {
    type: {
      type: String as PropType<QuestionTypes>,
      required: true,
      default: QuestionTypes.IdentifyRisks,
    },
    score: {
      type: Number,
      required: true,
      default: 2,
    },
    answeredQuestions: {
      type: Number,
      required: true,
      default: 3,
    },
    totalQuestions: {
      type: Number,
      required: true,
      default: 10,
    },
    animationProgress: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const getName = computed(() => t(`staff.game.${props.type}`));
    const getInstructions = computed(() => t(`staff.game.instructions.${props.type}`));
    const getIconSource = computed(() => `/assets/question-types/bubble/${props.type}.svg`);
    return {
      getName,
      getInstructions,
      getIconSource,
    };
  },
});
