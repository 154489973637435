import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-686c79e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "employee-list" }
const _hoisted_2 = { class: "list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_employee_card = _resolveComponent("employee-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardItems, (worker) => {
        return (_openBlock(), _createBlock(_component_employee_card, {
          key: `co-worker-card-${worker.id}`,
          onClick: ($event: any) => (_ctx.$emit('cardClick', worker)),
          imageUrl: worker.imageUrl,
          name: worker.name,
          score: worker.score
        }, null, 8, ["onClick", "imageUrl", "name", "score"]))
      }), 128))
    ])
  ]))
}