import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import routerConfig from '@/common/configs/router';

import Login from '@/learn-login/views/Login.vue';
import Register from '@/learn-login/views/Register.vue';
import RequestPasswordReset from '@/learn-login/views/RequestPasswordReset.vue';
import PasswordReset from '@/learn-login/views/PasswordReset.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/auth/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/auth/request-password-reset',
    name: 'requestPasswordReset',
    component: RequestPasswordReset,
  },
  {
    path: '/auth/password-reset',
    name: 'passwordReset',
    component: PasswordReset,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  ...routerConfig,
});

export default router;
