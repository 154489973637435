import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a68382a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["isImage"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.icon)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["icon-element", { '-disabled': _ctx.disabled }]),
        isImage: _ctx.isImage,
        style: _normalizeStyle(
      _ctx.isImage
        ? {
            background: `url(${require('@/assets/icons/' + _ctx.icon + '.svg')})`,
            backgroundSize: '100% 100%',
            backgroundColor: 'transparent',
            backgroundRepeat: 'no-repeat',
            '--height': _ctx.height + 'px',
            '--width': _ctx.width + 'px',
          }
        : {
            maskImage: `url(${require('@/assets/icons/' + _ctx.icon + '.svg')})`,
            '--height': _ctx.height + 'px',
            '--width': _ctx.width + 'px',
          }
    )
      }, null, 14, _hoisted_1))
    : _createCommentVNode("", true)
}