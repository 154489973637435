import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab1bf85c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fixed-game-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_button, {
      class: "button -primary",
      disabled: _ctx.disabled,
      label: _ctx.label,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
    }, null, 8, ["disabled", "label"])
  ]))
}