import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b840900"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "task" }
const _hoisted_2 = { class: "date icon-content copy -font-size-10 -font-weight-500" }
const _hoisted_3 = { class: "title h4" }
const _hoisted_4 = {
  key: 0,
  class: "score h1"
}
const _hoisted_5 = { class: "info info-container" }
const _hoisted_6 = { class: "date icon-content copy -font-size-10 -font-weight-500" }
const _hoisted_7 = { class: "details" }
const _hoisted_8 = {
  key: 0,
  class: "rating"
}
const _hoisted_9 = { class: "copy -font-size-10 -font-weight-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!
  const _component_monthly_task_planner_status = _resolveComponent("monthly-task-planner-status")!
  const _component_monthly_progress_bar = _resolveComponent("monthly-progress-bar")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["monthly-task-card", {
      '-unplanned': _ctx.status === 'unplanned',
      '-planned': _ctx.status === 'planned',
      '-active': _ctx.status === 'active',
      '-completed': _ctx.status === 'completed',
      '-missed': _ctx.status === 'missed',
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, [
        _createVNode(_component_icon_element, {
          class: "icon",
          icon: "calendar",
          width: 16,
          height: 16
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.date), 1)
      ]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["status", { '-is-completed': _ctx.status === 'completed' }])
      }, [
        (_ctx.status === 'completed')
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(Math.ceil(_ctx.progress * 100)) + "%", 1))
          : _createCommentVNode("", true),
        (_ctx.status !== 'completed')
          ? (_openBlock(), _createBlock(_component_monthly_task_planner_status, {
              key: 1,
              status: _ctx.status,
              label: _ctx.statusText
            }, null, 8, ["status", "label"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_monthly_progress_bar, {
          isTaskCompleted: _ctx.status === 'completed',
          class: "progress",
          progress: _ctx.progress
        }, null, 8, ["isTaskCompleted", "progress"]),
        _createElementVNode("p", _hoisted_6, [
          (_ctx.status !== 'completed' && _ctx.info)
            ? (_openBlock(), _createBlock(_component_icon_element, {
                key: 0,
                class: "icon",
                icon: "info",
                width: 13,
                height: 13
              }))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.info), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.status === 'completed')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_icon_element, {
                  class: "icon",
                  icon: "person",
                  width: 14.3,
                  height: 10.57
                }, null, 8, ["width", "height"]),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.completedDetails), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_app_button, {
            class: _normalizeClass([{ '-is-hidden': _ctx.status === 'unplanned' || _ctx.status === 'missed' }, "button -secondary -link"]),
            isSmall: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('showinfo'))),
            label: _ctx.actionLabel
          }, null, 8, ["class", "label"])
        ])
      ])
    ])
  ], 2))
}