export function reduceIdentifyRiskToSingleSelection(columns: number) {
  return function reducer(acc: number[], current: number, i: number, array: number[]) {
    // is cell in the list?
    const isNew = !acc.includes(current);

    // has cell a connection? this way we only allow one group
    const hasAConnection =
      acc.includes(current - 1) ||
      acc.includes(current + 1) ||
      acc.includes(current - columns) ||
      acc.includes(current + columns);

    // is first cell in the list?
    const isFirstCell = i === 0;

    // only add elements to the list, if they are valid
    if (isFirstCell || (isNew && hasAConnection)) {
      // add current element to the list
      acc.push(current);

      // check if current element has a connection to one of each side, and it's not yed added to the list.
      // if true, add it to the list

      // left:
      if (array.includes(current - 1) && !acc.includes(current - 1)) {
        acc.push(current - 1);
      }

      // right:
      if (array.includes(current + 1) && !acc.includes(current + 1)) {
        acc.push(current + 1);
      }

      // bottom:
      if (array.includes(current + columns) && !acc.includes(current + columns)) {
        acc.push(current + columns);
      }

      // top:
      if (array.includes(current - columns) && !acc.includes(current - columns)) {
        acc.push(current - columns);
      }
    }
    return acc;
  };
}

export interface IdentifyRiskSolutionCell {
  borderTop: boolean;
  borderBottom: boolean;
  borderLeft: boolean;
  borderRight: boolean;
}

export function computeIdentifyRiskCellsArray(solutionSpots: number[]) {
  const rows = 8;
  const columns = 6;
  const numberOfCells = rows * columns;

  const cells: Record<number, IdentifyRiskSolutionCell> = {};

  solutionSpots
    // norm value to array index: 1 -> 0, 2 -> 1 ...
    .map((cell: number) => cell - 1)
    // only allow values which are in the grid range: 0 <= x < numberOfCells
    .filter((cell) => cell >= 0 && cell < numberOfCells)
    // create array, where cells are connected and allow only first group
    .reduce(reduceIdentifyRiskToSingleSelection(columns), [])
    // define borders for each cell, depending on neighbors
    .forEach((cell: number, _, array: number[]) => {
      cells[cell] = {
        borderTop: !array.includes(cell - columns),
        borderBottom: !array.includes(cell + columns),
        borderLeft: !array.includes(cell - 1),
        borderRight: !array.includes(cell + 1),
      };
    });

  return cells;
}
