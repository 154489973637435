
import { defineComponent } from 'vue';

import IconElement from '@/common/elements/IconElement.vue';
import GameScore from '@/learn-app/components/game/GameScore.vue';
import GameProgress from '@/learn-app/components/game/GameProgress.vue';

export default defineComponent({
  name: 'game-header',
  components: {
    IconElement,
    GameScore,
    GameProgress,
  },
  emits: ['exit'],
  props: {
    score: {
      type: Number,
      required: true,
      default: 2,
    },
    answeredQuestions: {
      type: Number,
      required: true,
      default: 3,
    },
    totalQuestions: {
      type: Number,
      required: true,
      default: 10,
    },
    animationProgress: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
