
import { defineComponent } from 'vue';
import AppButton from '@/common/components/AppButton.vue';
import BaseCard from '@/learn-app/components/common/BaseCard.vue';
import useChallengeRequests from '@/learn-app/common/requests/useChallengeRequests';
import IconElement from '@/common/elements/IconElement.vue';
import ActivityIndicator from '@/common/components/indicator/ActivityIndicator.vue';

export default defineComponent({
  name: 'challenger-result',
  emits: ['abort', 'next'],
  components: {
    ActivityIndicator,
    AppButton,
    BaseCard,
    IconElement,
  },
  props: {
    challengeId: {
      type: String,
      required: true,
      default: '',
    },
    score: {
      type: Number,
      required: true,
      default: 4,
    },
    avatar: {
      type: String,
      required: true,
      default: 'https://source.unsplash.com/random',
    },
    opponentName: {
      type: String,
      required: true,
      default: 'Harmen',
    },
  },
  setup(props) {
    const { submitChallengeResult } = useChallengeRequests();
    return {
      ...submitChallengeResult(props.challengeId, props.score),
    };
  },
});
