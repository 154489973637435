import { DateTime as LuxonDateTime, DurationUnits } from 'luxon';

export interface FakeDateTimeOptions {
  sourceDate: string;
  targetDate: string;
  sourceIntervalMinutes: number;
  targetIntervalMinutes: number;
}

export default class FakeDateTime {
  public static sourceStartDateTime: LuxonDateTime;

  public static targetStartDateTime: LuxonDateTime;

  public static fakeDateChangeFactor: number;

  public isFakeDateTime = true;

  private dt: LuxonDateTime;

  private constructor(dt: LuxonDateTime) {
    this.dt = dt;
  }

  public get year() {
    return this.dt.year;
  }

  public get month() {
    return this.dt.month;
  }

  public get day() {
    return this.dt.day;
  }

  public get hour() {
    return this.dt.hour;
  }

  public get minute() {
    return this.dt.minute;
  }

  public get second() {
    return this.dt.second;
  }

  public get millisecond() {
    return this.dt.millisecond;
  }

  public get timestamp() {
    return this.dt.toMillis();
  }

  public toIso() {
    return this.dt.toISO();
  }

  public toNative() {
    return this.dt.toJSDate();
  }

  public toFormat(format: string) {
    return this.dt.toFormat(format);
  }

  public equals(other: FakeDateTime) {
    return this.dt.equals(other.dt);
  }

  public diff(other: FakeDateTime, units: DurationUnits) {
    return this.dt.diff(other.dt, units);
  }

  public formattedMonth() {
    return this.toFormat('MMMM yyyy');
  }

  public formattedDay() {
    return this.toFormat('dd. MMMM');
  }

  public formattedDateDefault() {
    return this.toFormat('d. MMMM yyyy');
  }

  public static createNow() {
    const source = FakeDateTime.sourceStartDateTime.toMillis();
    const target = FakeDateTime.targetStartDateTime.toMillis();

    const now = LuxonDateTime.local().toMillis() - (source - target);

    const diffSinceReference = now - target;

    const fakeDiffSinceReference = FakeDateTime.fakeDateChangeFactor * diffSinceReference;

    return new FakeDateTime(LuxonDateTime.fromMillis(target + fakeDiffSinceReference));
  }

  public static createLocal(
    year?: number,
    month?: number,
    day?: number,
    hour?: number,
    minute?: number,
    second?: number,
    millisecond?: number,
  ) {
    return new FakeDateTime(LuxonDateTime.local(year, month, day, hour, minute, second, millisecond));
  }

  public static createFromTimestamp(timestamp: number) {
    return new FakeDateTime(LuxonDateTime.fromMillis(timestamp));
  }

  public static createFromIso(text: string) {
    return new FakeDateTime(LuxonDateTime.fromISO(text));
  }

  public static createFromNative(date: Date) {
    return new FakeDateTime(LuxonDateTime.fromJSDate(date));
  }
}
