import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ad00aa1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "questionpack-state" }
const _hoisted_2 = { class: "title copy -font-size-12 -font-weight-700" }
const _hoisted_3 = { class: "description" }
const _hoisted_4 = { class: "text copy -font-size-10 -font-weight-500" }
const _hoisted_5 = {
  key: 1,
  class: "link -font-size-10 -font-weight-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["state", {
        '-active': _ctx.state === _ctx.QuestionState.Current,
        '-disabled': _ctx.state === _ctx.QuestionState.Scheduled,
        '-done': _ctx.state === _ctx.QuestionState.Completed,
        '-solved-once': _ctx.state === _ctx.QuestionState.SolvedOnce,
        '-not-done': _ctx.state === _ctx.QuestionState.NotCompleted,
      }])
    }, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.state !== _ctx.QuestionState.Completed && _ctx.state !== _ctx.QuestionState.SolvedOnce)
        ? (_openBlock(), _createBlock(_component_icon_element, {
            key: 0,
            class: "icon",
            icon: "info",
            width: "13",
            height: "13"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.text), 1),
      (_ctx.state === 'solved-once')
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Erneut versuchen"))
        : _createCommentVNode("", true)
    ])
  ]))
}