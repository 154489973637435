import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85b298c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "name -font-size-18-narrow -font-weight-700" }
const _hoisted_4 = { class: "score-container" }
const _hoisted_5 = { class: "number -font-size-18 -font-weight-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["employee-card", { '-selectable': _ctx.selectable }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.imageUrl,
        alt: "",
        class: "image"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.name), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_icon_element, {
        isImage: "",
        class: "icon",
        icon: "helmet",
        width: "17",
        height: "11"
      }),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.score), 1)
    ])
  ], 2))
}