import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04eaa27c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slot-machine" }
const _hoisted_2 = { class: "title -font-size-32 -font-weight-900" }
const _hoisted_3 = { class: "result" }
const _hoisted_4 = { class: "slots slot-reel" }
const _hoisted_5 = { class: "slot1" }
const _hoisted_6 = {
  class: "slot-line",
  ref: "reel1"
}
const _hoisted_7 = { class: "slot2" }
const _hoisted_8 = {
  class: "slot-line",
  ref: "reel2"
}
const _hoisted_9 = { class: "slot3" }
const _hoisted_10 = {
  class: "slot-line",
  ref: "reel3"
}
const _hoisted_11 = { class: "slot4" }
const _hoisted_12 = {
  class: "slot-line",
  ref: "reel4"
}
const _hoisted_13 = { class: "description -font-size-18 -font-weight-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('staff.challenge.view.slotMachine.title')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(40, (i) => {
                return _createElementVNode("span", {
                  class: "slot",
                  key: i,
                  style: _normalizeStyle(_ctx.getQuestionTypeIcon(i))
                }, null, 4)
              }), 64))
            ], 512)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(40, (i) => {
                return _createElementVNode("span", {
                  class: "slot",
                  key: i,
                  style: _normalizeStyle(_ctx.getQuestionPackageIcon(i))
                }, null, 4)
              }), 64))
            ], 512)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(40, (i) => {
                return _createElementVNode("span", {
                  class: "slot",
                  key: i,
                  style: _normalizeStyle(_ctx.getQuestionTypeIcon(i))
                }, null, 4)
              }), 64))
            ], 512)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(40, (i) => {
                return _createElementVNode("span", {
                  class: "slot",
                  key: i,
                  style: _normalizeStyle(_ctx.getQuestionPackageIcon(i))
                }, null, 4)
              }), 64))
            ], 512)
          ])
        ])
      ]),
      _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('staff.challenge.view.slotMachine.subtitle')), 1)
    ])
  ]))
}