import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15e6d688"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "invalid-token"
}
const _hoisted_2 = {
  key: 1,
  class: "password-reset-view"
}
const _hoisted_3 = { class: "h4 title" }
const _hoisted_4 = {
  class: "form choose-password-form",
  method: "post"
}
const _hoisted_5 = { class: "confirmation" }
const _hoisted_6 = { class: "checkbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_form = _resolveComponent("input-form")!
  const _component_password_rating = _resolveComponent("password-rating")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_checkbox_element = _resolveComponent("checkbox-element")!

  return (!_ctx.isTokenValid)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('staff.choosePassword.view.invalidToken')), 1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('staff.choosePassword.view.title')), 1),
        _createElementVNode("form", _hoisted_4, [
          _createVNode(_component_input_form, {
            type: "tel",
            class: "tel",
            label: _ctx.$t('staff.choosePassword.view.mobile'),
            name: "username",
            disabled: "",
            required: "",
            icon: "lock",
            modelValue: _ctx.phone,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
            autocomplete: "username"
          }, null, 8, ["label", "modelValue"]),
          _createVNode(_component_password_rating, {
            modelValue: _ctx.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
            class: "rating"
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_input_form, {
              type: "password",
              name: "password-confirmation",
              label: _ctx.$t('staff.choosePassword.view.passwordConfirmation'),
              modelValue: _ctx.passwordConfirmation,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passwordConfirmation) = $event)),
              isPW: "",
              required: "",
              autocomplete: "new-password"
            }, null, 8, ["label", "modelValue"]),
            _createElementVNode("span", {
              class: _normalizeClass(["error copy -font-size-10 -font-weight-500", { '-show': _ctx.showPasswordsNotMatching }])
            }, _toDisplayString(_ctx.$t('staff.choosePassword.view.passwordsMismatch')), 3)
          ]),
          _createVNode(_component_app_button, {
            class: "button -primary",
            disabled: !_ctx.canSubmit,
            label: _ctx.$t('staff.choosePassword.view.button'),
            type: "submit"
          }, null, 8, ["disabled", "label"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_checkbox_element, {
              modelValue: _ctx.rememberMe,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.rememberMe) = $event)),
              name: "rememberme",
              label: _ctx.$t('staff.choosePassword.view.rememberMe'),
              class: "checkbox"
            }, null, 8, ["modelValue", "label"])
          ])
        ])
      ]))
}