import { provide, reactive, toRefs, InjectionKey } from 'vue';

import injectStrict from '@/common/utils/injectStrict';

type AlertType = 'INFO' | 'ERROR' | 'NEUTRAL' | 'CHECK';
interface Alert {
  type: AlertType;
  text: string;
}

type SetAlertSignature = (payload: Alert) => void;
const setAlertProviderKey: InjectionKey<SetAlertSignature> = Symbol('provideSetAlertKey');

export const useAlerts = () => {
  interface AlertState {
    alerts: Array<Alert>;
  }

  const state = reactive<AlertState>({
    alerts: [],
  });

  const setAlert: SetAlertSignature = (payload: Alert): void => {
    state.alerts.push(payload);

    setTimeout(() => {
      state.alerts.shift();
    }, 2500);
  };

  provide(setAlertProviderKey, setAlert);

  return {
    setAlert,
    ...toRefs(state),
  };
};

export const useSetAlert = () => ({
  setAlert: injectStrict(setAlertProviderKey),
});
