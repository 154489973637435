import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09e3a714"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "inner"
}
const _hoisted_3 = { class: "h4 title" }
const _hoisted_4 = { class: "description copy -font-size-16 -font-weight-700" }
const _hoisted_5 = {
  class: "form login-form",
  method: "post"
}
const _hoisted_6 = {
  key: 1,
  class: "inner"
}
const _hoisted_7 = { class: "h4 title" }
const _hoisted_8 = { class: "description copy -font-size-16 -font-weight-700" }
const _hoisted_9 = { class: "action" }
const _hoisted_10 = { class: "copy -font-size-16 -font-weight-700 link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_form = _resolveComponent("input-form")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.linkSent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('staff.passwordReset.view.title')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('staff.passwordReset.view.description')), 1),
          _createElementVNode("form", _hoisted_5, [
            _createVNode(_component_input_form, {
              type: "tel",
              class: "tel",
              label: _ctx.$t('staff.passwordReset.view.mobile'),
              name: "phone",
              required: "",
              modelValue: _ctx.phone,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
              "error-message": _ctx.invalidPhoneErrorMessage
            }, null, 8, ["label", "modelValue", "error-message"]),
            _createVNode(_component_app_button, {
              class: "button -primary",
              disabled: !_ctx.isPhoneValid,
              label: _ctx.$t('staff.passwordReset.view.reset'),
              type: "submit"
            }, null, 8, ["disabled", "label"]),
            _createVNode(_component_router_link, {
              to: { name: 'login' },
              class: "back"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_button, {
                  class: "button -secondary -link",
                  label: _ctx.$t('common.back')
                }, null, 8, ["label"])
              ]),
              _: 1
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.$t('staff.checkSMS.view.title')), 1),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('staff.checkSMS.view.description')), 1),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('staff.checkSMS.view.question')), 1),
            _createVNode(_component_app_button, {
              class: "question -secondary -link",
              label: _ctx.$t('staff.checkSMS.view.link'),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.retry()))
            }, null, 8, ["label"])
          ]),
          _createVNode(_component_router_link, {
            to: { name: 'login' },
            class: "back"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_button, {
                class: "button -secondary -link",
                label: _ctx.$t('common.back')
              }, null, 8, ["label"])
            ]),
            _: 1
          })
        ]))
  ]))
}