import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-303cd758"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "game-verifying" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "h5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require('@/assets/icons/loading-indicator.svg'),
      alt: ""
    }, null, 8, _hoisted_2),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('staff.game.verifying')), 1)
  ]))
}