import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd8fb308"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-card" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "copy -font-size-16 -font-weight-700" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!
  const _component_app_button_card = _resolveComponent("app-button-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_icon_element, {
          class: "icon",
          height: "26",
          width: "26",
          icon: "sure-Bubble-info"
        }),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("p", {
        class: "copy -font-size-14 -font-weight-500",
        innerHTML: _ctx.description
      }, null, 8, _hoisted_5),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "actions", {}, () => [
          _createVNode(_component_app_button_card, {
            label: _ctx.$t('common.ok'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('ok')))
          }, null, 8, ["label"])
        ], true)
      ])
    ])
  ]))
}