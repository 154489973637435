import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44055d9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "game-score" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "number -font-weight-700 -font-size-18" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require('@/assets/icons/helmet.svg'),
      width: "20",
      height: "20",
      alt: ""
    }, null, 8, _hoisted_2),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.score), 1)
  ]))
}