import datetime, { DateTime } from '@/common/date/DatetimeService';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

export function useFakeDateTimeDisplay() {
  const fakeDateTimeEnabled = (datetime.now() as any).isFakeDateTime;
  const fakeNow = ref<DateTime>(datetime.now());

  if (fakeDateTimeEnabled) {
    const intervalHandle = ref<any>(null);

    onMounted(() => {
      intervalHandle.value = setInterval(() => {
        fakeNow.value = datetime.now();
      }, 5000);
    });

    onBeforeUnmount(() => {
      if (intervalHandle.value) {
        clearInterval(intervalHandle.value);
      }
    });
  }

  return {
    fakeDateTimeEnabled,
    fakeNow,
    formattedFakeNow: computed(() => fakeNow.value.toFormat('dd.MM.yyyy HH:mm')),
  };
}

export function useAppInfos() {
  const buildTime = datetime.fromIso(process.env.VUE_APP_BUILD_TIME ?? '');

  return {
    appVersion: process.env.VUE_APP_BUILD_VERSION ?? '?',
    buildTime,
    formattedBuildTime: buildTime.toFormat('dd.MM.yyyy HH:mm'),
  };
}
