import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46140a45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-form" }
const _hoisted_2 = ["id", "type", "value", "placeholder", "errorMessage", "autocomplete", "maxlength", "autofocus", "disabled", "name"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 2,
  class: "-error -font-size-10 -font-weight-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: _normalizeClass(["input copy", {
        'password-login': _ctx.currentType === 'password',
        '-icon': _ctx.icon,
        '-haserror': _ctx.errorMessage,
      }]),
      id: _ctx.name,
      type: _ctx.currentType,
      value: _ctx.modelValue,
      placeholder: _ctx.visibleLabel,
      errorMessage: _ctx.errorMessage,
      autocomplete: _ctx.autocomplete,
      maxlength: _ctx.maxlength,
      onInput: _cache[0] || (_cache[0] = (e) => _ctx.$emit('update:modelValue', e.target.value)),
      onChange: _cache[1] || (_cache[1] = (e) => _ctx.$emit('update:modelValue', e.target.value)),
      onFocusin: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus'))),
      autofocus: _ctx.autofocus,
      disabled: _ctx.disabled,
      name: _ctx.name
    }, null, 42, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.name,
      class: "label copy -font-size-12"
    }, _toDisplayString(_ctx.visibleLabel), 9, _hoisted_3),
    (_ctx.isPW)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "visibility form-icon",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.switchVisibility && _ctx.switchVisibility(...args)))
        }, [
          _createVNode(_component_icon_element, {
            class: "icon",
            icon: _ctx.currentType === 'password' ? 'pw-hide' : 'pw-show',
            disabled: _ctx.modelValue === '' || _ctx.disabled
          }, null, 8, ["icon", "disabled"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.disabled)
      ? (_openBlock(), _createBlock(_component_icon_element, {
          key: 1,
          class: "form-icon",
          icon: "lock",
          disabled: ""
        }))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}