import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a31105ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_element = _resolveComponent("icon-element")!

  return (_ctx.href)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.href,
        class: _normalizeClass(["app-button", { '-is-small': _ctx.isSmall, '-is-filter': _ctx.isFilter }])
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_icon_element, {
              key: 0,
              class: "icon",
              isImage: _ctx.isImage,
              icon: _ctx.icon,
              width: _ctx.iconWidth,
              height: _ctx.iconHeight
            }, null, 8, ["isImage", "icon", "width", "height"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(["label copy", { '-is-filter': _ctx.isFilter, '-is-small': _ctx.isSmall }])
        }, _toDisplayString(_ctx.label), 3)
      ], 10, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: _normalizeClass(["app-button", { '-is-small': _ctx.isSmall, '-is-filter': _ctx.isFilter }]),
        type: _ctx.type,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        disabled: _ctx.disabled
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_icon_element, {
              key: 0,
              class: "icon",
              isImage: _ctx.isImage,
              icon: _ctx.icon,
              width: _ctx.iconWidth,
              height: _ctx.iconHeight
            }, null, 8, ["isImage", "icon", "width", "height"]))
          : _createCommentVNode("", true),
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(["label copy", { '-is-filter': _ctx.isFilter, '-is-small': _ctx.isSmall }])
            }, _toDisplayString(_ctx.label), 3))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2))
}