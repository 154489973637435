import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2c50750"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "game-question-identify-risks" }
const _hoisted_2 = ["src", "srcset"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_game_header = _resolveComponent("game-header")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_game_header, {
      onExit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('exit'))),
      key: _ctx.answeredQuestions,
      score: _ctx.score,
      answeredQuestions: _ctx.answeredQuestions,
      totalQuestions: _ctx.totalQuestions,
      animationProgress: _ctx.animationProgress
    }, null, 8, ["score", "answeredQuestions", "totalQuestions", "animationProgress"])),
    _createElementVNode("div", {
      class: "image-container",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onImageClicked && _ctx.onImageClicked(...args)))
    }, [
      _createElementVNode("img", {
        ref: "imageRef",
        class: "image",
        src: `${_ctx.imagePath}.jpeg`,
        srcset: `${_ctx.imagePath}_750.webp 750w,
                          ${_ctx.imagePath}_1500.webp 1500w,
                          ${_ctx.imagePath}_2250.webp 2250w
                `,
        alt: ""
      }, null, 8, _hoisted_2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedSpots, (selected, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "selection",
          key: `selected-spot-${index}`,
          style: _normalizeStyle({ top: selected.y + '%', left: selected.x + '%' })
        }, null, 4))
      }), 128))
    ]),
    _createVNode(_component_app_button, {
      class: "reset -secondary -link",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.resetSelection())),
      label: _ctx.$t('staff.game.reset'),
      style: _normalizeStyle(!_ctx.hasChanges ? 'color: transparent' : '')
    }, null, 8, ["label", "style"]),
    _createVNode(_component_app_button, {
      class: _normalizeClass(["next -primary", { '-primary': _ctx.hasChanges, '-disabled': !_ctx.hasChanges }]),
      disabled: !_ctx.hasChanges,
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.verifyAnswer(_ctx.selectedSpots))),
      label: _ctx.$t('staff.game.next')
    }, null, 8, ["class", "disabled", "label"])
  ]))
}