
import { computed, defineComponent, PropType, ref } from 'vue';
import { onClickOutside, onKeyStroke, useFocusWithin } from '@vueuse/core';

export interface Option {
  name: string;
  id: string;
}

export default defineComponent({
  name: 'form-select',
  emits: ['update:selected'],
  props: {
    selected: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array as PropType<Option[]>,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const open = ref<boolean>(false);
    const currentValue = ref(props.selected);
    const target = ref<HTMLElement>();
    onClickOutside(target, () => {
      open.value = false;
    });

    const { focused } = useFocusWithin(target);

    onKeyStroke('ArrowDown', (e) => {
      e.preventDefault();
      if (focused.value && !open.value) {
        open.value = true;
      }
    });

    onKeyStroke('Escape', (e) => {
      e.preventDefault();
      if (focused.value && open.value) {
        open.value = false;
      }
    });

    const select = (option: Option) => {
      currentValue.value = option.id;
      open.value = false;
      emit('update:selected', option.id);
    };

    const currentOption = computed(() => props.options?.find((o) => o.id === currentValue.value));

    return {
      open,
      target,
      select,
      currentValue,
      currentOption,
    };
  },
});
