import DateTime, { FakeDateTimeOptions } from '@/common/date/FakeDatetimeMiddleware';
import { DateTime as LuxonDateTime } from 'luxon';

export default class FakeDateTimeService {
  constructor(options: FakeDateTimeOptions) {
    DateTime.sourceStartDateTime = LuxonDateTime.fromISO(options.sourceDate);
    DateTime.targetStartDateTime = LuxonDateTime.fromISO(options.targetDate);
    DateTime.fakeDateChangeFactor = options.sourceIntervalMinutes / options.targetIntervalMinutes;
  }

  public now(): DateTime {
    return DateTime.createNow();
  }

  public date(year: number, month: number, day: number): DateTime {
    return DateTime.createLocal(year, month, day);
  }

  public datetime(year: number, month: number, day: number, hour: number, minute: number, second: number, millisecond = 0) {
    return DateTime.createLocal(year, month, day, hour, minute, second, millisecond);
  }

  public fromTimestamp(timestamp: number): DateTime {
    return DateTime.createFromTimestamp(timestamp);
  }

  public fromIso(text: string): DateTime {
    return DateTime.createFromIso(text);
  }

  public fromNative(date: Date) {
    return DateTime.createFromNative(date);
  }

  public serialize(dateTime: DateTime) {
    return dateTime.toIso();
  }

  public deserialize(text: string): DateTime {
    return this.fromIso(text);
  }
}
