
import { defineComponent, PropType } from 'vue';
import { QuestionState } from '@/learn-app/types/QuestionState';
import { QuestionTopic } from '@/learn-app/types/QuestionTopic';
import datetime from '@/common/date/DatetimeService';

import IconElement from '@/common/elements/IconElement.vue';
import QuestionpackState from '@/learn-app/components/questionpack/QuestionpackState.vue';

export default defineComponent({
  name: 'questionpack-card',
  components: { IconElement, QuestionpackState },
  props: {
    name: {
      type: String,
      required: true,
      default: () => 'Alleinarbeit',
    },
    icon: {
      type: String,
      required: true,
      default: () => '/assets/question-packages/notfound.svg',
    },
    date: {
      type: String,
      required: true,
      default: () => datetime.now().toIso(),
    },
    topic: {
      type: String as PropType<QuestionTopic>,
      required: true,
      default: '2',
    },
    state: {
      type: String as PropType<QuestionState>,
      required: true,
      default: () => QuestionState.Current,
    },
    score: {
      type: Number,
      required: true,
      default: 2,
    },
    maxPoints: {
      type: Number,
      required: true,
      default: () => 5,
    },
    doneDate: {
      type: String,
      required: true,
      default: () => datetime.now().toIso(),
    },
    scheduled: {
      type: String,
      required: true,
      default: () => datetime.now().toIso(),
    },
    canUserSubmit: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const cardClickHandler = () => {
      if (props.canUserSubmit) {
        emit('selected');
      }
    };
    const formatToMonthYeah = (isoDate: string) => datetime.fromIso(isoDate).toFormat('LLLL yyyy');
    const formatDateDefault = (isoDate: string) => datetime.fromIso(isoDate).toFormat('d.LL.yyyy');
    // prettier-ignore
    const formatDayMonthYear = (isoDate: string) => datetime.fromIso(isoDate).toFormat('dd.LLLL.yyyy');
    return {
      formatDayMonthYear,
      formatDateDefault,
      formatToMonthYeah,
      cardClickHandler,
      QuestionState,
      datetime,
    };
  },
});
