import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04220a61"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "text h5" }
const _hoisted_3 = {
  key: 0,
  class: "h4 question -overflow-wrap-anywhere"
}
const _hoisted_4 = {
  key: 1,
  class: "image image-feedback"
}
const _hoisted_5 = ["src", "srcset"]
const _hoisted_6 = {
  key: 0,
  class: "grid"
}
const _hoisted_7 = {
  key: 2,
  class: "image image-feedback",
  ref: "imageRef"
}
const _hoisted_8 = ["src", "srcset"]
const _hoisted_9 = { class: "discussion result-discussion" }
const _hoisted_10 = {
  key: 0,
  class: "block"
}
const _hoisted_11 = { class: "title -font-size-14 -font-weight-500" }
const _hoisted_12 = { class: "copy -font-size-18 -font-weight-900" }
const _hoisted_13 = { class: "block" }
const _hoisted_14 = { class: "title -font-size-14 -font-weight-500" }
const _hoisted_15 = { class: "copy -font-size-18 -font-weight-700 -overflow-wrap-anywhere" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fixed_game_button = _resolveComponent("fixed-game-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["game-view-result", [`-${_ctx.result.toLowerCase()}`]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["indicator result-indicator", { '-image': _ctx.questionType !== _ctx.QuestionTypes.Quiz }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["result", [`-${_ctx.result.toLowerCase()}`]])
      }, [
        _createElementVNode("img", {
          src: require(`@/assets/icons/${_ctx.getResultIcon(_ctx.result)}.svg`),
          alt: "",
          width: "32",
          height: "32"
        }, null, 8, _hoisted_1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getResultText(_ctx.result)), 1)
      ], 2),
      (_ctx.questionType === _ctx.QuestionTypes.Quiz)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.question), 1))
        : (_ctx.questionType === _ctx.QuestionTypes.IdentifyRisks)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "image",
                ref: "imageRef",
                src: `${_ctx.imagePath}.jpeg`,
                srcset: `${_ctx.imagePath}_750.webp 750w,
                          ${_ctx.imagePath}_1500.webp 1500w,
                          ${_ctx.imagePath}_2250.webp 2250w
                `,
                alt: ""
              }, null, 8, _hoisted_5),
              (_ctx.result === _ctx.QuestionResult.FAIL)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(48, (_, i) => {
                      return _createElementVNode("div", {
                        class: _normalizeClass(["cell", {
              '-active': _ctx.cells[i],
              '-border-top': _ctx.cells[i] ? _ctx.cells[i].borderTop : '',
              '-border-bottom': _ctx.cells[i] ? _ctx.cells[i].borderBottom : '',
              '-border-left': _ctx.cells[i] ? _ctx.cells[i].borderLeft : '',
              '-border-right': _ctx.cells[i] ? _ctx.cells[i].borderRight : '',
            }]),
                        key: `grid-cell-${i}`
                      }, null, 2)
                    }), 64))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["feedback", {
            '-correct': _ctx.result === _ctx.QuestionResult.PASS,
            '-wrong': _ctx.result === _ctx.QuestionResult.FAIL,
          }]),
                style: _normalizeStyle({ top: _ctx.yScale(_ctx.givenAnswer.choosenSpots[0].y) + 'px', left: _ctx.xScale(_ctx.givenAnswer.choosenSpots[0].x) + 'px' })
              }, null, 6)
            ]))
          : (_ctx.questionType === _ctx.QuestionTypes.SafeOrNot)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("img", {
                  class: "image",
                  src: `${_ctx.imagePath}.jpeg`,
                  srcset: `${_ctx.imagePath}_750.webp 750w,
                    ${_ctx.imagePath}_1500.webp 1500w,
                    ${_ctx.imagePath}_2250.webp 2250w
          `,
                  alt: ""
                }, null, 8, _hoisted_8)
              ], 512))
            : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.questionType !== _ctx.QuestionTypes.IdentifyRisks)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('staff.game.correctAnswer')), 1),
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.correctAnswer), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('staff.game.explanation')), 1),
        _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.justification), 1)
      ])
    ]),
    _createVNode(_component_fixed_game_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('next'))),
      label: _ctx.$t('staff.game.next')
    }, null, 8, ["label"])
  ], 2))
}