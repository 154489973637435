import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "learn-wrapper" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "helmet" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "image",
          src: require(`@/assets/images/learn-app/helmet-login.svg`),
          alt: "Suva 12x12 Demo"
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("main", _hoisted_5, [
      _createVNode(_component_router_view)
    ])
  ]))
}