
import { defineComponent, ref, watch } from 'vue';
import useVerifyAnswer from '@/learn-app/common/useVerifyAnswer';

import FixedGameButton from '@/learn-app/components/game/FixedGameButton.vue';
import RadioButton from '@/common/elements/form/RadioButton.vue';
import GameHeader from '@/learn-app/components/game/GameHeader.vue';

export default defineComponent({
  name: 'game-game-question-quiz',
  components: {
    RadioButton,
    FixedGameButton,
    GameHeader,
  },
  emits: ['exit'],
  props: {
    question: {
      type: String,
      required: true,
      default: 'Wann dürfen Holzerntearbeiten ausgeführt werden?',
    },
    options: {
      type: Array,
      required: true,
      default: () => ['Umlenkrolle aus Stahl einsetzen', 'Nicht im Seilinnenwinkel stehen', 'Eigengewicht der Rolle'],
    },
    score: {
      type: Number,
      required: true,
      default: 2,
    },
    answeredQuestions: {
      type: Number,
      required: true,
      default: 3,
    },
    totalQuestions: {
      type: Number,
      required: true,
      default: 10,
    },
    animationProgress: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    const selectedOption = ref(null);
    const hasChanges = ref(false);

    watch(selectedOption, () => {
      hasChanges.value = true;
    });

    return {
      ...useVerifyAnswer(),
      hasChanges,
      selectedOption,
    };
  },
});
